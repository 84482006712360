import React, { useEffect } from 'react'
import Message from '.'
import PropTypes from 'prop-types'
import ImageFiles from './imageFiles'
import Query from './query'

const MessageList = (props) => {
  const messagesEndRef = React.useRef(null)
  const { queryNessage, setQueryMessage, showQuery, setShowQuery } = props

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    scrollToBottom()
  }, [props.messages])
  return (
    <div className='sc-message-list' data-testid='msgList'>
      {showQuery ? (
        <Query
          queryNessage={queryNessage}
          setQueryMessage={setQueryMessage}
          showQuery={showQuery}
          setShowQuery={setShowQuery}
          scrollToBottom={scrollToBottom}
          onSubmit={props.onSubmit}
        />
      ) : Object.keys(props.image).length !== 0 ? (
        <ImageFiles image={props?.image?.file} isLoading={props.isLoading} />
      ) : (
        <>
          {props.messages?.error ? (
            <p>{props.messages?.error}</p>
          ) : (
            props.messages?.messages?.map((message, i) => {
              return <Message message={message} key={i} companyImg={props.imageUrl} userId={props.userId} />
            })
          )}
        </>
      )}
      <div ref={messagesEndRef} />
    </div>
  )
}
MessageList.propTypes = {
  imageUrl: PropTypes.string,
  poId: PropTypes.string,
  userId: PropTypes.string,
  messages: PropTypes.array,
  image: PropTypes.object,
  isLoading: PropTypes.string,
  ueryNessage: PropTypes.bool,
  queryNessage: PropTypes.string,
  setQueryMessage: PropTypes.func,
  showQuery: PropTypes.bool,
  setShowQuery: PropTypes.func,
  onSubmit: PropTypes.func,
  chatType: PropTypes.string,
}
export default MessageList
