import React from 'react'
import PropTypes from 'prop-types' // Import PropTypes for props validation
import { IconButton, Tooltip } from '@mui/material'
import { AiOutlineEye } from 'react-icons/ai'
import HorizontalStatus from 'components/common/status/HorizontalStatus'

const OrderTable = ({ orderTemp, navigateToOrderDetails }) => {
    return (
        <div style={{ marginTop: "20px" }}>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                    <tr>
                        {/* <th className="order1-dataList">Sr No.</th> */}
                        <th className="order1-dataList">Order ID</th>
                        <th className='order-dataList'>Shop Name</th>
                        <th className='order-dataList'>Customer</th>
                        <th className='order-dataList'>State</th>
                        <th className='order-dataList'>Source</th>
                        <th className='order-dataList'>Creation Date</th>
                        <th className='order-dataList'>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {orderTemp.length ? orderTemp?.map((shop, index) => (
                        <tr key={index} style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
                            {/* <td className='order-table'>{currentPage == 0 ? index + 1 :(currentPage - 1) * limit + (index + 1)}.</td> */}
                            <td className='order-table'>{shop.order_id}</td>
                            <td className='order-table'>{shop.shop_name}</td>
                            <td className='order-table'>{shop.shipping_address.name}</td>
                            <td className='order-state' style={{
                                color: shop.state === "shipping" ? "#8F00FF" :
                                    shop.state === "shipped" ? "green" :
                                        shop.state === "canceled" ? "red" :
                                            shop.state === "waiting_acceptance" ? "#e1ad01" : "#2e7d32",
                                border: shop.state === "shipping" ? "2px solid #8F00FF" :
                                    shop.state === "shipped" ? "2px solid green" :
                                        shop.state === "canceled" ? "2px solid red" :
                                            shop.state === "waiting_acceptance" ? "2px solid #e1ad01" : "#2e7d32",
                            }}>{shop.state}</td>
                            <td className='order-table'>{shop.source}</td>
                            <td className='order-table'>{new Date(shop.order_date).toLocaleDateString()}</td>
                            <td>
                                <Tooltip title='View Order'>
                                    <div className='col-md-auto'>
                                        <IconButton
                                            aria-label='view'
                                            color='success'
                                            onClick={() => navigateToOrderDetails(shop.order_id)}
                                        >
                                            <AiOutlineEye />
                                        </IconButton>
                                    </div>
                                </Tooltip>
                            </td>
                        </tr>
                    )) :
                        <tr style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
                            <td colSpan="7" style={{ padding: "50px", marginTop: "50px", textAlign: "center" }}>
                                <div style={{ paddingTop: 20, marginTop: 50, marginBottom: 50 }}>
                                    <HorizontalStatus
                                        imagesrc={require('assets/svgs/shared/empty.svg').default}
                                        mainmsg={'No Order found'}
                                        smallmsg={'No Order found'}         
                                    />
                                </div>

                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    )
}

// PropTypes validation
OrderTable.propTypes = {
    orderTemp: PropTypes.array.isRequired,
    navigateToOrderDetails: PropTypes.func.isRequired,
}

export default OrderTable
