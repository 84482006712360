import { Grid, Box } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import User from "./User"
import { UserContext } from "context/AuthContext/UserContext"

const ViewVendorDetails = () => {
    const location = useLocation()
    const [users, setUsers] = useState([])

    useEffect(() => {
        const usersData = location.state?.users || []
        setUsers(usersData)
    }, [location.state])

    const contextObj = React.useContext(UserContext)
    const userId = contextObj.userData?.user?.id || null
    
    return (
        <>
            <div className="p-5">
                <Grid container>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                        <h3>Vendor Details</h3>
                        <p>Details of vendor of your company.</p>
                    </Grid>
                </Grid>
                <br /><br />

                <Grid container>
                    <Grid item lg={3} md={3} sm={3} xs={12}>
                        <h5>Vendor Name</h5>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={12}>
                        <p>{location.state.title}</p>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={12}>
                        <h5>Vendor Email</h5>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={12}>
                        <p>{location.state.email}</p>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item lg={3} md={3} sm={3} xs={12}>
                        <h5>Vendor Company Name</h5>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={12}>
                        <p>{location.state.companyName}</p>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={12}>
                        <h5>Vendor Mobile Number</h5>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={12}>
                        <p></p>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item lg={3} md={3} sm={3} xs={12}>
                        <h5>Vendor Address</h5>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={12}>
                        <p></p>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={12}>
                        <h5>Vendor Alias</h5>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={12}>
                        <p></p>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item lg={3} md={3} sm={3} xs={12}>
                        <h5>Vendor Code</h5>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={12}>
                        <p></p>
                    </Grid>
                </Grid>
                <br />
                <Box border={1} p={2} mb={3}>
                    <h3>Vendor Company Users</h3>
                <Grid container spacing={3}>
                {users.length > 0 &&
                    users.map((user, index) => (
                        <Grid key={index} item lg={4} md={6} sm={12} xs={12}>
                          <User
                            id={user.id}
                            title={`${user.fName} ${user.lName} ${user.id === userId ? '(You)' : ''}`}
                            email={user.email}
                            code={user?.userSubscription?.roleCode}
                            isActive={user?.userSubscription?.isActive}
                            length={users.length}
                          />
                        </Grid>
                    ))}
                </Grid>
                </Box>

              
            </div>
        </>
    )
}

export default ViewVendorDetails