import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import useOrdersHooks from './useOrdersHooks'
import { useParams } from 'react-router-dom'
import OrderHeader from './OrderHeader'
// import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import Loader from 'components/common/Loader'
import ChatBox from 'components/chat/chatBox'
import purchaseOrderService from 'shared/services/purchase-order-service'
import PropTypes from 'prop-types'
import { useMutation } from 'react-query'
import { AppBreadcrumb } from 'sections/layout/dashboard'

const OrderListDetails = (props) => {
  const { contextObj, detail, refetchOrderDetails } = useOrdersHooks(props)

  const { socket } = props
  const { orderId } = useParams()
  const [isOpen, setIsOpen] = useState(false)
  const [newMessage, setNewMessage] = useState(0)
  const [messages, setMessages] = useState([])
  const updateMessage = () => {
    const userId = contextObj?.userData?.user?.id
    if (userId && messages && messages.users && messages.users.includes(userId)) {
      socket.emit('UpdateMessage', {
        poId: orderId,
        ids: [messages?._id]
      })
    }
  }

  useEffect(() => {
    const userId = contextObj?.userData?.user?.id
    if (socket && userId) {
      socket.on(orderId, (data) => {
        if (!isOpen) {
          setNewMessage(newMessage + 1)
        }
        if (userId && data && data.users && !data.users.includes(userId)) {
          data.lastMessage.isRead = false
        }
        setMessages(data)
      })
    }
  }, [socket, messages, contextObj.userData.user.id, isOpen])

  const sendMessage = (message, type) => {
    if (message !== '') {
      const companyIdToSave = contextObj.userData.user.companyId
      if (!message) {
        return showToast('Please add messages First', false)
      }
      socket.emit('Message', {
        userId: contextObj.userData.user.id,
        companyId: companyIdToSave,
        orderId: orderId,
        userName: contextObj.userData.user.fName,
        email: contextObj.userData.user.email,
        message: message.trim(),
        isRead: false,
        type: type,
        socketID: socket.id,
        poNumber: orderId,
        chatType: 'Retail Shop Order'
      })
    }
  }

  const getChats = useMutation(
    async (params) => {
      const { orderId, page, limit } = params
      const response = await purchaseOrderService.getAllQueryChats(orderId, page, limit)
      return response
    },
    {
      onSuccess: (response) => {
        setMessages(response.data[0])
      },
      onError: () => {
        setMessages({ error: 'Unable to Load Convertations' })
      }
    }
  )

  useEffect(() => {
    getChats.mutate({ orderId: orderId, page: 0, limit: 50 })
    refetchOrderDetails(orderId)
  }, [orderId])
  let breadCrumbPath = [
    { name: 'Orders List', url: `/orders`, active: 'not-allowed' },
    { name: 'Order-Details', url: `/order/${orderId}`, active: 'not-allowed' }
  ]

  return (
    <>
      <div>
        <Grid  >
          <Grid className='ms-5 mt-3' >
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <AppBreadcrumb pathNameDeclare={breadCrumbPath} />
              <h3>Order Details</h3>
            </Grid>
          </Grid>
          <Grid>
            {detail ? (
              <>
                <OrderHeader purchaseOrderDetail={detail} />
                <ChatBox
                isOpen={isOpen}
                poId={orderId}
                poNumber={orderId}
                userId={contextObj.userData.user.id}
                sendMessage={sendMessage}
                messages={messages}
                setIsOpen={setIsOpen}
                setNewMessage={setNewMessage}
                newMessage={newMessage}
                updateMessage={updateMessage}
                contextObj={contextObj}
                chatType='order'
              />
              </>
            ) : (
              <Box sx={{ display: 'flex', justifyContent: 'Center', alignItems: 'center' }}>
                <Loader />
              </Box>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  )
}
OrderListDetails.propTypes = {
  socket: PropTypes.object
}
export default OrderListDetails
