import React from 'react'
// import { CButton } from '@coreui/react'
import PropTypes from 'prop-types'
import { ToastMessage } from 'components/uiElements'
// import { Navigate } from 'react-router-dom'
// import Loader from 'components/common/Loader'

import { isNotNullUndefinedOrEmpty } from 'shared/util/helper'
import Status from 'components/common/status/VerticalStatus'
import { useParams } from 'react-router-dom'
// const load =()=>{

// }
const ResultPo = (props) => {
  const { projectId } = useParams()

  return (
    <>
      {!props.error && <ToastMessage color={'success'} message={projectId ? 'Project Updated Successfully' : 'Project Created Successfully'} />}

      <Status
        message={isNotNullUndefinedOrEmpty(props.error) ? 'Something Went Wrong!!!' : projectId ? 'Project Updated Successfully' : 'Project Created Successfully'}
        error={props.error}
      />
    </>
  )
}

ResultPo.propTypes = {
  error: PropTypes.string,
  pCode: PropTypes.string,
  message: PropTypes.string,
  setActiveStep: PropTypes.func,
  // setOpenModal: PropTypes.func,
  isAdmin: PropTypes.bool,


}

export default ResultPo
