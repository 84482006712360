import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Table, TableHead, TableBody, TableRow, TableCell, CircularProgress, Skeleton, TableContainer } from '@mui/material'
import PropTypes from 'prop-types'
import { FaArrowDownLong, FaArrowUpLong } from "react-icons/fa6"

const SkeletonRow = ({ cols }) => (
    <TableRow>
        {Array.from({ length: cols }, (_, index) => (
            <TableCell key={index}>
                <Skeleton variant='text' />
            </TableCell>
        ))}
    </TableRow>
)
SkeletonRow.propTypes = {
    cols: PropTypes.number.isRequired
}


const CommonTableComponent = (props) => {
    const { shops, selectedYear, lastMonth, currentMonth, lastYear, currentYear, loading, rowsPerPage, page,
        handleChangePage, fetchDataPaticularShop, shopCountryData, shopCountryDataLoading, setClickedRows, clickedRows, } = props
    const [totals, setTotals] = useState(null)
    const [sortConfig, setSortConfig] = useState({ type: 'qty_ordered_2023', order: 'asc' })
    const [allDataLoaded, setAllDataLoaded] = useState(false)
    const tableContainerRef = useRef(null)
    const sortData = (shopsData, type, order) => {
        return [...shopsData].sort((a, b) => {
            const aValue = a[type] ?? 0
            const bValue = b[type] ?? 0
            return order === 'asc' ? aValue - bValue : bValue - aValue
        })
    }
    const sortedData = useMemo(() => sortData([...shops?.period_data || []], sortConfig.type, sortConfig.order), [shops, sortConfig])
    const paginatedData = useMemo(() => {
        const start = page * rowsPerPage
        const end = start + rowsPerPage
        return sortedData.slice(0, end)
    }, [sortedData, page, rowsPerPage])
    const loadMoreData = () => {
        if (sortedData.length > paginatedData.length) {
            handleChangePage('', page + 1)
        }
        else {
            setAllDataLoaded(true)
        }
    }
    const getIconStyle = (type, order) => ({
        color: sortConfig.type === type && sortConfig.order === order ? 'blue' : 'grey',
        cursor: 'pointer',
        marginLeft: '1px',
    })
    const handleSort = async (type) => {
        const container = tableContainerRef.current
        if (container) {
            await container.scrollTo({ top: 0, behavior: 'smooth' })
        }
        const newOrder = sortConfig.type === type && sortConfig.order === 'asc' ? 'desc' : 'asc'
        setSortConfig({ type, order: newOrder })
    }
    const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = tableContainerRef.current
        if (scrollTop + clientHeight >= scrollHeight - 10 && !allDataLoaded) {
            loadMoreData()
        }
    }
    useEffect(() => {
        const container = tableContainerRef.current
        if (container) {
            container.addEventListener('scroll', handleScroll, true)
            return () => {
                container.removeEventListener('scroll', handleScroll, true)
            }
        }
    }, [allDataLoaded, page, sortedData])
    useEffect(() => {
        setAllDataLoaded(false)
        handleChangePage('', 0)
    }, [shops])

    const calculateTotals = async (data) => {
        if (data?.length) {
            let totals = { untaxed_2024: 0, untaxed_2023: 0, qty_ordered_2023: 0, qty_ordered_2024: 0 }
            for (let item of data) {
                totals.untaxed_2024 += item?.untaxed_2024 ?? 0
                totals.untaxed_2023 += item?.untaxed_2023 ?? 0
                totals.qty_ordered_2023 += item?.qty_ordered_2023 ?? 0
                totals.qty_ordered_2024 += item?.qty_ordered_2024 ?? 0
            }
            return totals
        }
        else {
            return null
        }
    }
    const fetchTotals = async () => {
        if (shops?.period_data?.length) {
            try {
                const result = await calculateTotals(shops?.period_data)
                setTotals(result)
            } catch (error) {
                console.error('Error calculating totals:', error)
            }
        }
    }
    useEffect(() => {
        fetchTotals()
    }, [shops?.period_data])
    return (
        <>
            <TableContainer className='scroll-box' ref={tableContainerRef} >
                <Table stickyHeader >
                    <TableHead>
                        <TableRow >
                            <TableCell ><strong>Shop</strong></TableCell>
                            <TableCell colSpan={2} align='center'><strong>Sales</strong></TableCell>
                            <TableCell colSpan={2} align='center'><strong>Units Sold</strong></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell><strong>{selectedYear === 'currentYear' ? lastMonth : lastYear}</strong></TableCell>
                            <TableCell><strong>{selectedYear === 'currentYear' ? currentMonth : currentYear}</strong></TableCell>
                            <TableCell>
                                <strong onClick={() => handleSort('qty_ordered_2023')}>
                                    {selectedYear === 'currentYear' ? lastMonth : lastYear}
                                    <FaArrowUpLong style={getIconStyle('qty_ordered_2023', 'asc')} />
                                    <FaArrowDownLong style={getIconStyle('qty_ordered_2023', 'desc')} />
                                </strong>
                            </TableCell>
                            <TableCell>
                                <strong onClick={() => handleSort('qty_ordered_2024')}>
                                    {selectedYear === 'currentYear' ? currentMonth : currentYear}
                                    <FaArrowUpLong style={getIconStyle('qty_ordered_2024', 'asc')} />
                                    <FaArrowDownLong style={getIconStyle('qty_ordered_2024', 'desc')} />
                                </strong>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            Array.from({ length: rowsPerPage }, (_, index) => <SkeletonRow key={index} cols={5} />)
                        ) : Array.isArray(paginatedData) && paginatedData.length ? (paginatedData.map((item, index) => (
                            <React.Fragment key={index}>
                                <TableRow
                                    className="table-row"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        if (clickedRows === index) {
                                            return setClickedRows(null)
                                        }
                                        fetchDataPaticularShop(item?.shop)
                                        setClickedRows(index)
                                    }}
                                >
                                    <TableCell>{item?.shop ? item?.shop : '--'}</TableCell>
                                    <TableCell>{item.untaxed_2023 ? `€ ${item.untaxed_2023.toFixed(2)}` : '--'}</TableCell>
                                    <TableCell>{item?.untaxed_2024 ? `€ ${item?.untaxed_2024.toFixed(2)}` : '--'}</TableCell>
                                    <TableCell>{item?.qty_ordered_2023 ? item?.qty_ordered_2023 : '--'}</TableCell>
                                    <TableCell>{item.qty_ordered_2024 ? item.qty_ordered_2024 : '--'}</TableCell>
                                </TableRow>
                                {clickedRows === index && shopCountryDataLoading ? (
                                    <TableRow>
                                        <TableCell colSpan={6}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    height: '100px'
                                                }}
                                            >
                                                <CircularProgress />
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ) : ""}
                                {clickedRows === index && !shopCountryDataLoading ? (
                                    <TableRow>
                                        <TableCell colSpan={6}>
                                            <div className='card' style={{ overflowX: 'auto' }}>
                                                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                                    <thead>
                                                        <tr style={{ borderBottom: '1px solid #ccc' }}>
                                                            <th style={{ padding: '8px', textAlign: 'left' }}>Country</th>
                                                            <th style={{ padding: '8px', textAlign: 'left' }}>{selectedYear === 'currentYear' ? lastMonth : lastYear}</th>
                                                            <th style={{ padding: '8px', textAlign: 'left' }}>{selectedYear === 'currentYear' ? currentMonth : currentYear}</th>
                                                            <th style={{ padding: '8px', textAlign: 'left' }}>{selectedYear === 'currentYear' ? lastMonth : lastYear}</th>
                                                            <th style={{ padding: '8px', textAlign: 'left' }}>{selectedYear === 'currentYear' ? currentMonth : currentYear}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {Array.isArray(shopCountryData?.period_data) && shopCountryData.period_data.length ? shopCountryData.period_data.map((res, idx) => (
                                                            <tr key={idx} style={{ borderBottom: '1px solid #ccc' }}>
                                                                <td style={{ padding: '8px', textAlign: 'left' }}>{res?.shipment_country ? res?.shipment_country : "--"}</td>
                                                                <td style={{ padding: '8px', textAlign: 'left' }}>{res?.untaxed_2023 ? `€ ${res?.untaxed_2023.toFixed(2)}` : "--"}</td>
                                                                <td style={{ padding: '8px', textAlign: 'left' }}>{res?.untaxed_2024 ? `€ ${res?.untaxed_2024.toFixed(2)}` : '--'}</td>
                                                                <td style={{ padding: '8px', textAlign: 'left' }}>{res?.qty_ordered_2023 ? res?.qty_ordered_2023 : '--'}</td>
                                                                <td style={{ padding: '8px', textAlign: 'left' }}>{res?.qty_ordered_2024 ? res?.qty_ordered_2024 : '--'}</td>
                                                            </tr>
                                                        )) : (
                                                            <TableRow>
                                                                <TableCell colSpan={6} style={{ padding: 0 }}>
                                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 30, marginBottom: 30 }} >
                                                                        <p className='text-center'>No Records Found... </p>
                                                                    </div>
                                                                </TableCell>
                                                            </TableRow>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ) : ""}
                            </React.Fragment>
                        )))
                            : (
                                <TableRow>
                                    <TableCell colSpan={6} style={{ padding: 0 }}>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 30, marginBottom: 30 }} >
                                            <p className='text-center'>No Records Found... </p>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            )}
                    </TableBody>
                </Table>
            </TableContainer>
            {shops?.period_data?.length ? <Table className='mt-3' sx={{ width: '100%', minWidth: 'auto', marginLeft: '20px' }}>
                <TableHead className='ms-3'>
                    <TableRow >
                        <TableCell className='fw-bold fs-16'>Total </TableCell>
                        <TableCell></TableCell>
                        <TableCell><strong>{`€ ${totals?.untaxed_2023.toFixed(2)}` ?? '--'}</strong></TableCell>
                        <TableCell><strong>{`€ ${totals?.untaxed_2024.toFixed(2)}` ?? '--'}</strong></TableCell>
                        <TableCell><strong>{totals?.qty_ordered_2023 ?? '--'}</strong></TableCell>
                        <TableCell><strong>{totals?.qty_ordered_2024 ?? '--'}</strong></TableCell>
                    </TableRow>
                </TableHead>
            </Table> : ""}
        </>
    )
}

CommonTableComponent.propTypes = {
    shops: PropTypes.shape({
        period_data: PropTypes.arrayOf(PropTypes.object),
        total_count: PropTypes.number
    }).isRequired,
    selectedYear: PropTypes.string.isRequired,
    lastMonth: PropTypes.string.isRequired,
    currentMonth: PropTypes.string.isRequired,
    lastYear: PropTypes.string.isRequired,
    currentYear: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    handleChangePage: PropTypes.func.isRequired,
    fetchDataPaticularShop: PropTypes.func.isRequired,
    setClickedRows: PropTypes.func.isRequired,
    clickedRows: PropTypes.bool.isRequired,
    shopCountryData: PropTypes.shape({
        period_data: PropTypes.arrayOf(PropTypes.object)
    }).isRequired,
    shopCountryDataLoading: PropTypes.bool.isRequired,
}


export default CommonTableComponent
