import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { styled } from '@mui/system'

const StyledTable = styled(Table)({
    borderCollapse: 'collapse',
    width: '100%',
    '& th, & td': {
        border: '1px solid #ddd',
        padding: '8px',
    },
    '& th': {
        paddingTop: '12px',
        paddingBottom: '12px',
        textAlign: 'center',
        backgroundColor: '#f2f2f2',
        color: 'black',
    },
    '& td': {
        textAlign: 'center',
        whiteSpace: 'nowrap',
    },
})

const SkuDataComponent = (props) => {
    const { shopsSku, formatDecimal } = props
    return (
        <Grid container direction='row' justifyContent='space-between'>
            <Grid item md={12}>
                <StyledTable>
                    <TableHead>
                        <TableRow>
                            <TableCell>Image</TableCell>
                            <TableCell>Sku</TableCell>
                            <TableCell>Untaxed 2024</TableCell>
                            <TableCell>Qty Ordered 2024</TableCell>
                            <TableCell>Untaxed 2023</TableCell>
                            <TableCell>Qty Ordered 2023</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {shopsSku?.period_data?.map((shopData, index) => (
                            <React.Fragment key={index}>
                                <TableRow>
                                    <TableCell>
                                        <img src={
                                            shopData?.flat_image ? shopData?.flat_image : `https://innoage.blob.core.windows.net/images/648b053c91392cac4623e220/668.9231059410128/MicrosoftTeams-image%20%288%29.png`
                                        } alt="Product" width="60px" height="60px" />
                                    </TableCell>
                                    <TableCell>{shopData.sku}</TableCell>
                                    <TableCell>{formatDecimal(shopData.untaxed_2024) || '-'}</TableCell>
                                    <TableCell>{shopData.qty_ordered_2024 || '-'}</TableCell>
                                    <TableCell>{formatDecimal(shopData.untaxed_2023) || '-'}</TableCell>
                                    <TableCell>{shopData.qty_ordered_2023 || '-'}</TableCell>
                                </TableRow>
                            </React.Fragment>
                        ))}
                    </TableBody>
                </StyledTable>
            </Grid>
        </Grid>
    )
}

SkuDataComponent.propTypes = {
    shopsSku: PropTypes.shape({
        period_data: PropTypes.arrayOf(
            PropTypes.shape({
                flat_image: PropTypes.string,
                sku: PropTypes.string,
                untaxed_2024: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                qty_ordered_2024: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                untaxed_2023: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                qty_ordered_2023: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            })
        ),
    }).isRequired,
    formatDecimal: PropTypes.func.isRequired,
}

export default SkuDataComponent
