import React from 'react'
import PropTypes from 'prop-types'

const CustomTooltip = (props) => {
    const { content, position } = props
    if (!content) return null
    return (
        <div className="custom-tooltip" style={{ top: position.top, left: position.left }}>
            <p className="label">{`${content.label}`}</p>
            <hr></hr>
            <p className="intro">{`Current Year: ${content.currentYear}`}</p>
            <hr></hr>
            <p className="intro">{`Last Year: ${content.lastYear}`}</p>
        </div>
    )
}
CustomTooltip.propTypes = {
    position: PropTypes.string,
    content: PropTypes.string,
}
export default CustomTooltip