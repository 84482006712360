import React from 'react'
import PropTypes from 'prop-types'

import { Chip } from '@mui/material'
import { getCatalogueStatus } from 'data/Enums'

const getColor = (status) => {
  switch (status) {
    case 2:
      return 'success'
    case 4:
    case 1:
      return 'warning'
    case 3:
      return 'error'
    default:
      return 'success'
  }
}

const CatalogueStatus = (props) => {
  return (
    <Chip
      label={getCatalogueStatus(props.status)}
      color={getColor(props.status)}
      variant='outlined'
    />
  )
}

CatalogueStatus.propTypes = {
  status: PropTypes.number
}

export default CatalogueStatus
