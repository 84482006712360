import React from 'react'
import PropTypes from 'prop-types'
import './Types.css'
import { AiFillFilePdf } from 'react-icons/ai'

const PdfMessage = (props) => {
  const pdf = (url) => {
    let name = url.split('/')
    return name[name.length - 1]
  }
  const openPdf = () => {
    window.open(props.data.message, '_blank')
  }
  return (
    <div className='sc-message--text'>
      <span className='small  text-muted float-start'>
        {props.userId === props.data.userId ? 'You' : props.data.userName}
      </span>
      <p className='pt-4 pdf--message' onClick={() => openPdf()}>
        {pdf(props.data.message)} <AiFillFilePdf size='1.5em' />
      </p>
    </div>
  )
}

PdfMessage.propTypes = {
  data: PropTypes.object,
  userId: PropTypes.string
}

export default PdfMessage
