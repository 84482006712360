import { Grid, Hidden } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'
import './analytics.css'
import moment from 'moment'

const ShopSkuFilter = (props) => {
    const { selectedYear, selectedShipmentCountry, yearSelectStartDate, yearSelectEndDate, selectedOption, searchShop, selectedShipmentCountrySku, skuYearSelectStartDate, skuYearSelectEndDate, skuSelectedOption, searchSku, selectedShop, selectedPercent } = props

    return (
        <>

            <Grid container direction='row' justifyContent='space-between' spacing={2}>
                {selectedYear && (
                    <Grid item md={4} className='gridItem'>
                        <Grid item md={12} pt={1}>
                            <span className='heading'>Year</span>
                        </Grid>
                        <Grid item md={12} className='paddingB'>
                            <span className='text'>{selectedYear}</span>
                        </Grid>
                    </Grid>
                )}
                {selectedShipmentCountry && (
                    <Grid item md={4} className='gridItem'>
                        <Grid item md={12} pt={1}>
                            <span className='heading'>Shipment Country</span>
                        </Grid>
                        <Grid item md={12}>
                            <span className='text'>{selectedShipmentCountry}</span>
                        </Grid>
                    </Grid>
                )}
                <Hidden mdUp={!yearSelectStartDate && !yearSelectEndDate && !selectedOption && !searchShop}>
                    {yearSelectStartDate && (
                        <Grid item md={4} className='gridItem'>
                            <Grid item md={12} pt={1}>
                                <span className='heading'>Start Date</span>
                            </Grid>
                            <Grid item md={12}>
                                <span className='text'>{moment(yearSelectStartDate).format("MMMM Do YYYY")}</span>
                            </Grid>
                        </Grid>
                    )}
                    {yearSelectEndDate && (
                        <Grid item md={4} className='gridItem'>
                            <Grid item md={12} pt={1}>
                                <span className='heading'>End Date</span>
                            </Grid>
                            <Grid item md={12}>
                                <span className='text'>{moment(yearSelectEndDate).format("MMMM Do YYYY")}</span>
                            </Grid>
                        </Grid>
                    )}
                    {selectedOption && (
                        <Grid item md={4} className='gridItem'>
                            <Grid item md={12} pt={1}>
                                <span className='heading'>Option</span>
                            </Grid>
                            <Grid item md={12}>
                                <span className='text'>{selectedOption}</span>
                            </Grid>
                        </Grid>
                    )}
                    {searchShop && (
                        <Grid item md={4} className='gridItem'>
                            <Grid item md={12} pt={1}>
                                <span className='heading'>Shop</span>
                            </Grid>
                            <Grid item md={12}>
                                <span className='text'>{searchShop}</span>
                            </Grid>
                        </Grid>
                    )}
                </Hidden>
            </Grid>

            <Grid container direction='row' justifyContent='space-between' spacing={2}>
                {selectedYear && (
                    <Grid item md={4} className='gridItem'>
                        <Grid item md={12} pt={1}>
                            <span className='heading'>Year</span>
                        </Grid>
                        <Grid item md={12} className='paddingB'>
                            <span className='text'>{selectedYear}</span>
                        </Grid>
                    </Grid>
                )}
                {selectedShipmentCountrySku && (
                    <Grid item md={4} className='gridItem'>
                        <Grid item md={12} pt={1}>
                            <span className='heading'>Shipment Country</span>
                        </Grid>
                        <Grid item md={12}>
                            <span className='text'>{selectedShipmentCountrySku}</span>
                        </Grid>
                    </Grid>
                )}
                <Hidden mdUp={!skuYearSelectStartDate && !skuYearSelectEndDate && !skuSelectedOption && !searchSku}>
                    {skuYearSelectStartDate && (
                        <Grid item md={4} className='gridItem'>
                            <Grid item md={12} pt={1}>
                                <span className='heading'>Start Date</span>
                            </Grid>
                            <Grid item md={12}>
                                <span className='text'>{moment(skuYearSelectStartDate).format("MMMM Do YYYY")}</span>
                            </Grid>
                        </Grid>
                    )}
                    {skuYearSelectEndDate && (
                        <Grid item md={4} className='gridItem'>
                            <Grid item md={12} pt={1}>
                                <span className='heading'>End Date</span>
                            </Grid>
                            <Grid item md={12}>
                                <span className='text'>{moment(skuYearSelectEndDate).format("MMMM Do YYYY")}</span>
                            </Grid>
                        </Grid>
                    )}
                    {skuSelectedOption && (
                        <Grid item md={4} className='gridItem'>
                            <Grid item md={12} pt={1}>
                                <span className='heading'>Option</span>
                            </Grid>
                            <Grid item md={12}>
                                <span className='text'>{skuSelectedOption}</span>
                            </Grid>
                        </Grid>
                    )}
                    {searchSku && (
                        <Grid item md={4} className='gridItem'>
                            <Grid item md={12} pt={1}>
                                <span className='heading'>Sku</span>
                            </Grid>
                            <Grid item md={12}>
                                <span className='text'>{searchSku}</span>
                            </Grid>
                        </Grid>
                    )}
                    {selectedShop && (
                        <Grid item md={4} className='gridItem'>
                            <Grid item md={12} pt={1}>
                                <span className='heading'>Shop</span>
                            </Grid>
                            <Grid item md={12}>
                                <span className='text'>{selectedShop}</span>
                            </Grid>
                        </Grid>
                    )}
                    {selectedPercent && (
                        <Grid item md={4} className='gridItem'>
                            <Grid item md={12} pt={1}>
                                <span className='heading'>Percent</span>
                            </Grid>
                            <Grid item md={12}>
                                <span className='text'>{selectedPercent}</span>
                            </Grid>
                        </Grid>
                    )}
                </Hidden>
            </Grid>
        </>
    )
}

ShopSkuFilter.propTypes = {
    selectedYear: PropTypes.string,
    selectedShipmentCountry: PropTypes.string,
    yearSelectStartDate: PropTypes.instanceOf(Date),
    yearSelectEndDate: PropTypes.instanceOf(Date),
    selectedOption: PropTypes.string,
    searchShop: PropTypes.string,
    selectedShipmentCountrySku: PropTypes.string,
    skuYearSelectStartDate: PropTypes.instanceOf(Date),
    skuYearSelectEndDate: PropTypes.instanceOf(Date),
    skuSelectedOption: PropTypes.string,
    searchSku: PropTypes.string,
    selectedShop: PropTypes.string,
    selectedPercent: PropTypes.string
}

export default ShopSkuFilter
