
import { useState, useEffect, useContext } from 'react'
import purchaseOrderService from 'shared/services/purchase-order-service'
import { useMutation } from 'react-query'
import { UserContext } from 'context/AuthContext/UserContext'

export const useSalesHooks = () => {
    const { showToast } = useContext(UserContext)
    const [error, setError] = useState('')
    const [shop, setShop] = useState([])
    const [shipment, setShipment] = useState([])
    const [warehouse, setWarehouse] = useState([])
    const [sales, setSales] = useState([])
    const [designsales, setDesignSales] = useState([])
    const [fromDate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('')
    const [presetValue, setPresetValue] = useState(10)
    const [rowsPerPage, setRowsPerPage] = useState(25)
    const [productPage, setProductPage] = useState(0)
    const [total, setTotal] = useState(0)
    const [designtotal, setDesignTotal] = useState(0)
    const [designPage, setDesignPage] = useState(0)
    const [totalRevenue, setTotalRevenue] = useState(0)
    const [totalUnitSold, setTotalUnitSold] = useState(0)
    const [showSalesRevenue, setShowSalesRevenue] = useState(true)
    const [showProduct, setShowProduct] = useState(true)
    const [selectedShop, setSelectedShop] = useState('')
    const [selectedWarehouse, setSelectedWarehouse] = useState('')
    const [selectedShipmentCountry, setSelectedShipmentCountry] = useState('')
    const [selectedShopType, setSelectedShopType] = useState('')
    const [searchSku, setSearchSku] = useState('')
    const [groupDataValue, setGroupDataValue] = useState()
    const [showFilterMessage, setShowFilterMessage] = useState(false)
    const [totalSales, setTotalSales] = useState()
    const [dateRange, setDateRange] = useState({ startDate: null, endDate: null })
    const [chartData, setChartData] = useState()
    const [selectedComparison, setSelectedComparison] = useState('')

    useEffect(() => {
        if (presetValue !== 10) {
            setSelectedComparison('py')
        }
    }, [presetValue])

    const handleProductPageChange = (event, newPage) => {
        setProductPage(newPage)
    }

    const handleDesignPageChange = (event, newPage) => {
        setDesignPage(newPage)
    }

    const handleRowsPerPageChange = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10)
        setRowsPerPage(newRowsPerPage)
        setProductPage(0)
        setDesignPage(0)
    }

    const { mutate: getShop } = useMutation(
        async () => {
            const response = await purchaseOrderService.getShopData()
            return response.data
        },
        {
            onSuccess: (data) => {
                const arr = data.map(item => item.shop)
                setShop(arr)
            },
            onError: (error) => {
                setError(error.response?.data?.message || 'Some error occurred')
            },
        }
    )

    const { mutate: getShipment } = useMutation(
        async () => {
            const response = await purchaseOrderService.getShipmentData()
            return response.data
        },
        {
            onSuccess: (data) => {
                const arr = data.map(item => item.shipment_country)
                setShipment(arr)
            },
            onError: (error) => {
                setError(error.response?.data?.message || 'Some error occurred')
            },
        }
    )

    const { mutate: getWarehouse } = useMutation(
        async () => {
            const response = await purchaseOrderService.getWarehouseData()
            return response.data
        },
        {
            onSuccess: (data) => {
                const arr = data.map(item => item.warehouse)
                setWarehouse(arr)
            },
            onError: (error) => {
                setError(error.response?.data?.message || 'Some error occurred')
            },
        }
    )

    const activeDateRange = fromDate && toDate ? `${fromDate} - ${toDate}` : ''

    const getCurrentQuarterDates = () => {
        const currentDate = new Date()
        const currentQuarter = Math.floor((currentDate.getMonth() + 3) / 3)
        let startDate, endDate
        switch (currentQuarter) {
            case 1:
                startDate = new Date(currentDate.getFullYear(), 0, 1)
                endDate = new Date(currentDate.getFullYear(), 2, 31)
                break
            case 2:
                startDate = new Date(currentDate.getFullYear(), 3, 1)
                endDate = new Date(currentDate.getFullYear(), 5, 30)
                break
            case 3:
                startDate = new Date(currentDate.getFullYear(), 6, 1)
                endDate = new Date(currentDate.getFullYear(), 8, 30)
                break
            case 4:
                startDate = new Date(currentDate.getFullYear(), 9, 1)
                endDate = new Date(currentDate.getFullYear(), 11, 31)
                break
            default:
                break
        }
        return { startDate, endDate }
    }

    const [revenueData, setRevenueData] = useState([])
    const initilizeRevenue = async (data, ppData) => {
        try {
            const dataRes = data.map((item, index) => {
                const baseData = {
                    argument: item?._id,
                    value: item.totalUntaxedTotal,
                }
                if (selectedComparison && ppData) {
                    baseData.additionalValue = ppData[index]?.totalUntaxedTotal
                }
                return baseData
            })
            setRevenueData(dataRes)
        } catch (error) {
            console.log(error)
        }
    }

    const [unitSoldData, setUnitSoldData] = useState([])
    const initilizeUnitSold = async (data, ppData) => {
        try {
            const dataRes = data.map((item, index) => {
                const baseData = {
                    argument: item?._id,
                    value: item?.totalQuantity,
                }
                if (selectedComparison && ppData) {
                    baseData.additionalValue = ppData[index]?.totalQuantity
                }
                return baseData
            })
            setUnitSoldData(dataRes)
        } catch (error) {
            console.log(error)
        }
    }


    const { mutate: getShopShipment } = useMutation(
        async (variables) => {
            try {
                const response = await purchaseOrderService.getShopShipmentData(
                    variables.startDate,
                    variables.endDate,
                    variables.comparison,
                    variables.shop,
                    variables.country,
                    variables.warehouse,
                    variables.shop_type,
                    variables.sku,
                )
                return response.data
            } catch (error) {
                throw new Error(error.response.data.message || 'Some error occurred')
            }
        },
        {
            onSuccess: async (data) => {
                setChartData(data)
            },
            onError: (error) => {
                setError(error.message || 'Some Error Occurred, Please Try Again')
            },
        }
    )

    const { mutate: getTotalSales } = useMutation(
        async (variables) => {
            try {
                const { startDate, endDate, presetValue, groupDataValue, fromDate, toDate, comparison, shop, country, warehouse, shop_type, sku } = variables
                const response = await purchaseOrderService.getTotalSalesData(startDate, endDate, presetValue, groupDataValue, fromDate, toDate, comparison, shop, country, warehouse, shop_type, sku)
                return response.data
            } catch (error) {
                throw new Error(error.response.data.message || 'Some error occurred')
            }
        },
        {
            onSuccess: async (data) => {
                setTotalSales(data)
            },
            onError: (error) => {
                setError(error.message || 'Some Error Occurred, Please Try Again')
            },
        }
    )

    const { mutate: getSales, isLoading } = useMutation(
        async (variables) => {
            try {
                const response = await purchaseOrderService.getSalesData(
                    variables.startDate,
                    variables.endDate,
                    variables.page,
                    variables.rowsPerPage,
                    variables.comparison,
                    variables.sortOrder,
                    variables.sortType,
                    variables.shop,
                    variables.country,
                    variables.warehouse,
                    variables.shop_type,
                    variables.sku,
                )
                return response.data
            } catch (error) {
                throw new Error(error.response.data.message || 'Some error occurred')
            }
        },
        {
            onSuccess: async (data) => {
                setSales(Array.isArray(data.data) ? data.data : [])
                setDesignSales(Array.isArray(data.designData) ? data.designData : [])
                setTotal(data.total)
                setDesignTotal(data.totalDesign)
            },
            onError: (error) => {
                setError(error.message || 'Some Error Occurred, Please Try Again')
            },
        }
    )


    useEffect(() => {
        const fetchData = async () => {
            await getShop()
            await getShipment()
            await getWarehouse()
        }
        fetchData()
    }, [])

    useEffect(() => {
        initilizeUnitSold(totalSales?.result, totalSales?.pp)
        initilizeRevenue(totalSales?.result, totalSales?.pp)
    }, [totalSales, selectedComparison])

    const handleApplyFilter = async () => {
        try {
            let startDate
            let endDate

            const noFiltersSelected =
                (presetValue === 10) &&
                !fromDate &&
                !toDate &&
                !selectedShop &&
                !selectedShipmentCountry &&
                !selectedWarehouse

            if (noFiltersSelected) {
                return setShowFilterMessage(true)
            }
            else {
                setShowFilterMessage(false)
            }
            if (fromDate && toDate) {
                startDate = new Date(fromDate)
                endDate = new Date(toDate)
            }
            else {
                const currentDate = new Date()
                switch (presetValue) {
                    case 20: // Last 7 days
                        startDate = new Date()
                        startDate.setDate(startDate.getDate() - 6)
                        endDate = currentDate
                        break
                    case 30: // Last 30 days
                        startDate = new Date()
                        startDate.setDate(startDate.getDate() - 29)
                        endDate = currentDate
                        break
                    case 40: // December 2023 (replace with your logic)
                        startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)
                        endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0)
                        break
                    case 50: // Quarter 4 2023 (replace with your logic)
                        const quarterDates = getCurrentQuarterDates()
                        startDate = quarterDates.startDate
                        endDate = quarterDates.endDate
                        break
                    case 60: // Year-to-date(YTD)
                        startDate = new Date(`${currentDate.getFullYear()}-01-01`)
                        endDate = currentDate
                        break
                    case 70: // Year 2023
                        const previousYear = currentDate.getFullYear() - 1
                        startDate = new Date(previousYear, 0, 1) // January 1st of the previous year
                        endDate = new Date(previousYear, 11, 31) // December 31st of the previous year
                        break
                    case 80: // Yesterday date
                        const yesterday = new Date()
                        yesterday.setDate(currentDate.getDate() - 1) // Subtract one day
                        startDate = yesterday
                        endDate = yesterday
                        break
                    case 90: // Last week number days
                        const lastWeekDate = new Date()
                        const dayOfWeek = lastWeekDate.getDay()
                        const daysToSubtract = dayOfWeek + 7
                        startDate = new Date(lastWeekDate.setDate(lastWeekDate.getDate() - daysToSubtract))
                        startDate.setHours(0, 0, 0, 0)
                        endDate = new Date(startDate)
                        endDate.setDate(startDate.getDate() + 6)
                        endDate.setHours(23, 59, 59, 999)
                        break
                    case 100: // Current week number days
                        const currentWeekDate = new Date()
                        const currentDayOfWeek = currentWeekDate.getDay()
                        const daysToSubtractCurrentWeek = currentDayOfWeek
                        startDate = new Date(currentWeekDate.setDate(currentWeekDate.getDate() - daysToSubtractCurrentWeek))
                        startDate.setHours(0, 0, 0, 0)
                        endDate = new Date(startDate)
                        endDate.setDate(startDate.getDate() + 6)
                        endDate.setHours(23, 59, 59, 999)
                        break
                    case 110: // Current month
                        startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
                        endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
                        endDate.setHours(23, 59, 59, 999)
                        break
                    case 120: // Last Quarter
                        const currentMonth = currentDate.getMonth()
                        let startMonth, endMonth

                        if (currentMonth >= 0 && currentMonth <= 2) {
                            startMonth = 9
                            endMonth = 11
                        } else if (currentMonth >= 3 && currentMonth <= 5) {
                            startMonth = 0
                            endMonth = 2
                        } else if (currentMonth >= 6 && currentMonth <= 8) {
                            startMonth = 3
                            endMonth = 5
                        } else {
                            startMonth = 6
                            endMonth = 8
                        }
                        startDate = new Date(currentDate.getFullYear(), startMonth, 1)
                        endDate = new Date(currentDate.getFullYear(), endMonth + 1, 0)
                        endDate.setHours(23, 59, 59, 999)
                        break
                    default:
                        showToast("Please Select Dates Range Presets", false)
                        break
                }
            }
            if ((startDate && endDate) !== undefined) {
                setProductPage(1)
                setDesignPage(1)

                await getSales({ startDate: startDate, endDate: endDate, page: 1, rowsPerPage: rowsPerPage, comparison: selectedComparison, sortOrder: 'asc', sortType: 'totalUntaxed', shop: selectedShop, country: selectedShipmentCountry, warehouse: selectedWarehouse, shop_type: selectedShopType, sku: searchSku})
                setDateRange({ startDate, endDate })
                getTotalSales({
                    startDate: startDate,
                    endDate: endDate,
                    presetValue: presetValue,
                    groupDataValue: groupDataValue,
                    fromDate: fromDate,
                    toDate: toDate,
                    comparison: selectedComparison,
                    shop: selectedShop, 
                    country: selectedShipmentCountry, 
                    warehouse: selectedWarehouse, 
                    shop_type: selectedShopType,
                    sku: searchSku,
                })
                await getShopShipment({ startDate: startDate, endDate: endDate, comparison: selectedComparison, shop: selectedShop, country: selectedShipmentCountry, warehouse: selectedWarehouse, shop_type: selectedShopType, sku: searchSku })
            }
        } catch (error) {
            console.error(error)
        }
    }


    const getCurrentMonthMinusOne = () => {
        const currentDate = new Date()
        currentDate.setMonth(currentDate.getMonth() - 1)
        const formattedDate = currentDate.toLocaleString('default', { month: 'long' })
        return `Last month (${formattedDate} ${currentDate.getFullYear()})`
    }

    const getCurrentMonth = () => {
        const currentDate = new Date()
        currentDate.setMonth(currentDate.getMonth())
        const formattedDate = currentDate.toLocaleString('default', { month: 'long' })
        return `Current month (${formattedDate} ${currentDate.getFullYear()})`
    }

    const getCurrentQuarter = () => {
        const currentDate = new Date()
        const currentMonth = currentDate.getMonth() + 1 // January is 0
        const currentQuarter = Math.ceil(currentMonth / 3)
        return `Current Quarter (Quarter ${currentQuarter} ${currentDate.getFullYear()})`
    }

    const getCurrentWeekNumber = () => {
        const currentDate = new Date()
        const startDate = new Date(currentDate.getFullYear(), 0, 1)
        const pastDaysOfYear = (currentDate - startDate) / 86400000
        return `Current Week ${Math.ceil((pastDaysOfYear + startDate.getDay() + 1) / 7)}`
    }

    const getLastWeekNumber = () => {
        const currentDate = new Date()
        currentDate.setDate(currentDate.getDate() - 7)
        const startDate = new Date(currentDate.getFullYear(), 0, 1)
        const pastDaysOfYear = (currentDate - startDate) / 86400000
        return `Last Week ${Math.ceil((pastDaysOfYear + startDate.getDay() + 1) / 7)}`
    }

    const getLastQuarterNumber = () => {
        const currentDate = new Date()
        const currentQuarter = Math.floor((currentDate.getMonth() + 3) / 3)
        let lastQuarter = currentQuarter - 1

        if (lastQuarter === 0) {
            lastQuarter = 4
        }
        return `Last Quarter (Quarter ${lastQuarter} ${currentDate.getFullYear()})`
    }

    const getCurrentYearMinusOne = () => {
        const currentYear = new Date().getFullYear()
        return `Last year (${currentYear - 1})`
    }

    const resetFilters = () => {
        setFromDate('')
        setToDate('')
        setPresetValue(10)
        setRowsPerPage(25)
        setProductPage(0)
        setDesignPage(0)
        setTotalRevenue(0)
        setTotalUnitSold(0)
        setShowSalesRevenue(true)
        setShowProduct(true)
        setSelectedShop('')
        setSelectedWarehouse('')
        setSelectedShipmentCountry('')
        setSales([])
        setGroupDataValue('GroupBy')
        setSelectedComparison('')
        setSelectedShopType('')
        setSearchSku('')
    }


    return {
        shop,
        shipment,
        warehouse,
        groupDataValue,
        showProduct,
        setShowProduct,
        showSalesRevenue,
        setShowSalesRevenue,
        designPage,
        productPage,
        rowsPerPage,
        activeDateRange,
        dateRange,
        setGroupDataValue,
        setSelectedShop,
        setSelectedWarehouse,
        setSelectedShipmentCountry,
        handleProductPageChange,
        handleDesignPageChange,
        handleRowsPerPageChange,
        handleApplyFilter,
        getCurrentMonthMinusOne,
        getCurrentMonth,
        getCurrentQuarter,
        getCurrentYearMinusOne,
        getCurrentWeekNumber,
        getLastWeekNumber,
        getLastQuarterNumber,
        resetFilters,
        setPresetValue,
        presetValue,
        fromDate,
        setFromDate,
        toDate,
        setToDate,
        selectedShop,
        selectedShipmentCountry,
        selectedWarehouse,
        totalRevenue,
        totalUnitSold,
        isLoading,
        showFilterMessage,
        revenueData,
        unitSoldData,
        error,
        totalSales,
        sales,
        total,
        designsales,
        designtotal,
        getSales,
        chartData,
        setSelectedComparison,
        selectedComparison,
        setSelectedShopType,
        selectedShopType,
        setSearchSku,
        searchSku,
    }
}