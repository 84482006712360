import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

const SearchableDropdown = ({ options, label, id, selectedVal, onHandleChange }) => {
  const [query, setQuery] = useState('')
  const [isOpen, setIsOpen] = useState(false)

  const inputRef = useRef(null)

  useEffect(() => {
    document.addEventListener('click', toggle)
    return () => document.removeEventListener('click', toggle)
  }, [])
  const selectOption = (option) => {
    setQuery(() => '')
    onHandleChange(option)
    setIsOpen((isOpen) => !isOpen)
  }

  function toggle(e) {
    setIsOpen(e && e.target === inputRef.current)
  }

  const getDisplayValue = () => {
    if (query) return query
    if (selectedVal) return selectedVal.sku

    return ''
  }

  const filter = (options) => {
    return options.filter((option) => option[label]?.toLowerCase().indexOf(query.toLowerCase()) > -1)
  }
 
  return (
    <div className='dropdown'>
      <div className='control'>
        <div className='selected-value'>
          <input
            autoComplete='off'
            style={{ height: '48px' }}
            ref={inputRef}
            type='text'
            value={getDisplayValue()}
            name='searchTerm'
            onChange={(e) => {
              setQuery(e.target.value)
              onHandleChange(null)
            }}
            onClick={toggle}
          />
        </div>
        <div className={`arrow ${isOpen ? 'open' : ''}`}></div>
      </div>

      <div className={`options ${isOpen ? 'open' : ''}`}>
        {filter(options).map((option, index) => {
          return (
            <div
              onClick={() => selectOption(option)}
              className={`option ${option[label] === selectedVal ? 'selected' : ''}`}
              key={`${id}-${index}`}
            >
              <span className='me-2'>
                <img src={option.images[0]} height={50} width={50} alt='img' />
              </span>
              {option[label]}
              <span className='ms-2'>{option?.measurements[0]?.metricSize}</span>
            </div>
          )
        })}
      </div>
    </div>
  )
}
SearchableDropdown.propTypes = {
  options: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  selectedVal: PropTypes.string,
  images: PropTypes.string,
  measurements: PropTypes.string,

  onHandleChange: PropTypes.func
}

export default SearchableDropdown
