import React from 'react'
import PropTypes from 'prop-types'

import './Notification.css'
const Notification = (props) => {
  return <span className='system-notification-msg text-center'>{props.message}</span>
}

Notification.propTypes = {
  message: PropTypes.string
}

export default Notification
