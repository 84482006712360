import React from 'react'
import PropTypes from 'prop-types'
import { isNotNullUndefinedOrEmpty } from 'shared/util/helper'
import Loader from 'components/common/Loader'

import './VerticalStatus.css'

const VerticalStatus = (props) => {
  if (props.loading) {
    return (
      <div className='row d-flex justify-content-center'>
        <div className='height w-25 p-3'>
          <Loader />
        </div>
      </div>
    )
  } else {
    return (
      <div
        data-testid={props.datatestid}
        className={`${props.isNotCentered ? '' : 'container status-container '} ${props.extraClasses}`}
        style={{ height: props.height || '90%' }}
      >
        <div className='row'>
          <div className='col text-center'>
            <div className='row'>
              <div className='col text-center'>
                <img
                  src={
                    isNotNullUndefinedOrEmpty(props.image)
                      ? props.image
                      : isNotNullUndefinedOrEmpty(props.error)
                        ? require('assets/svgs/shared/500.svg').default
                        : require('assets/svgs/shared/tick.svg').default
                  }
                  className=''
                  alt=''
                  style={{ height: props.imgheight || 150,marginTop:'210px' }}
                />
              </div>
            </div>
            <h4 className='mt-4 main-message'>{props.message}</h4>
            <p
              className={` ${
                isNotNullUndefinedOrEmpty(props.error) ? 'is-error-message' : 'is-info-message'
              }`}
            >
              {props.info}
            </p>
            {!!props.redirection && (
              <div className='row mt-2'>
                <div className='col'>{props.redirection}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

VerticalStatus.propTypes = {
  loading: PropTypes.bool,
  message: PropTypes.string,
  info: PropTypes.string,
  redirection: PropTypes.object,
  error: PropTypes.string,
  height: PropTypes.string,
  imgheight: PropTypes.string,
  image: PropTypes.string,
  extraClasses: PropTypes.string,
  datatestid: PropTypes.string,
  isNotCentered: PropTypes.bool
}

export default VerticalStatus
