import React from 'react'
import { CFooter } from '@coreui/react'
import PropTypes from 'prop-types'

import './AppFooter.css'
import Images from 'data/Images'

const AppFooter = (props) => {
  return (
    <>
      {props.isImageFooter ? (
        <div className='footer-brand-white pb-2 pt-5'>
          <a href='https://innoage.in/products/innosales' target='_blank' rel='noopener noreferrer'>
            <img src={Images.DesignedWithFooter} alt='footer' height='27px' />
          </a>
        </div>
      ) : (
        <CFooter>
          <div className='footer-brand'>
            <span className='ms-1'>Pom</span> &copy; {new Date().getFullYear()}{' '}
            <a className='footer-url' href='https://innoage.in' target='_blank' rel='noopener noreferrer'>
              InnoAge&trade; Technologies Pvt. Ltd.
            </a>
          </div>
        </CFooter>
      )}
    </>
  )
}

AppFooter.propTypes = {
  isImageFooter: PropTypes.bool
}

export default React.memo(AppFooter)
