import { useEffect } from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import purchaseOrderService from 'shared/services/purchase-order-service'
import { useContext } from 'react'
import { UserContext } from 'context/AuthContext/UserContext'
import { useQuery, useMutation } from 'react-query'
import { getDate } from 'shared/util/helper'

export const useProjectsDetailsHooks = (props) => {
  const { getProjectOnProjectId } = purchaseOrderService
  const { socket } = props
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [filterDrawer, setFilterDrawer] = useState(false)
  const [projectDetail, setProjectDetail] = useState(false)
  const param = useParams()
  const [isProLoading, setIsProLoading] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [rows, setRows] = useState([])
  let [values, setValues] = useState({})
  const contextObj = useContext(UserContext)
  const { showToast } = useContext(UserContext)

  const columns = [
    { id: 'poNumber', label: 'Po Number', minWidth: 130 },
    { id: 'supplier', label: 'Supplier', minWidth: 130 },
    { id: 'shipTo', label: 'Ship\u00a0To', minWidth: 100 },
    { id: 'shipVia', label: 'Ship\u00a0Via', minWidth: 100 },
    { id: 'poDate', label: 'Po Date ', minWidth: 100 },
    { id: 'poDueDate', label: 'Po Due\u00a0Date', minWidth: 100 }
  ]

  function createData(poNumber, supplier, shipTo, shipVia, poDate, poDueDate) {
    return { poNumber, supplier, shipTo, shipVia, poDate, poDueDate }
  }
  const handleChangePage = (newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const toggleDrawer = (e) => {
    e.preventDefault()
    setFilterDrawer(!filterDrawer)
  }

  const { mutate: fetchProductsOnIds } = useMutation(
    async (ids) => {
      try {
        const response = await purchaseOrderService.getPurchaseOrdersOnIds(ids)
        return response
      }
      catch (error) {
        console.error(error)
      }
    },
    {
      onSuccess: (response) => {
        if (response?.data?.length) {
          const arr = response?.data?.map((project) =>
            createData(
              project.poNumber,
              project.supplierName,
              project.shipTo,
              project.shipVia,
              getDate(project.poDate),
              getDate(project.poDueDate)
            )
          )
          setRows(arr)
        }
      },
      onError: (error) => {
        console.error('Error fetching products:', error)
      },
    }
  )

  const { } = useQuery(
    ['purchaseOrder', param.projectId],
    async () => {
      const response = await getProjectOnProjectId(param.projectId)
      return response.data
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: async (data) => {
        let productData = []
        setProjectDetail(data)
        setValues(data)
        await getPoChats({ poId: data?._id, page: 0, limit: 50 })
        await data.purchaseOrder.map((project) => {
          productData.push(project.poId)
        })
        await fetchProductsOnIds(productData)
      },
      onError: (error) => {
        setError(error?.data)
      },
    }
  )

  const updateProjectMutation = useMutation(
    (values) => {
      purchaseOrderService.updateProjectOnProjectId(values)
      return values
    },
    {
      onSuccess: (value) => {
        setSuccess(true)
        showToast(`Project Stage Updated to ${value.projectStage}`, true)
      },
      onError: (error) => {
        setSuccess(false)
        setError(error)
      }
    }
  )
  const onStageChange = () => {
    try {
      updateProjectMutation.mutate(values)
    }
    catch (error) {
      console.error(error)
    }
  }

  const sendMessage = (message, type) => {
    if (message !== '') {
      socket.emit('Message', {
        userId: contextObj.userData.user.id,
        poId: projectDetail._id,
        userName: contextObj.userData.user.fName,
        message: message,
        poNumber: projectDetail.projectId,
        isRead: false,
        type: type,
        socketID: socket.id,
        chatType: 'Project'
      })
    }
  }

  const { mutate: getPoChats } = useMutation(async (params) => {
    const { poId, page, limit } = params
    await purchaseOrderService.getAllPoChats(poId, page, limit)

  }, {
    onSuccess: (response) => {
      if (response?.data?.length) {
        setMessages(response.data[0])
      }
    },
    onError: (err) => {
      setMessages({ error: 'Unable to Load Convertations' })
      setError('Seems like our server is down')
      console.log(err)
    }
  })

  const [messages, setMessages] = useState([])
  const [newMessage, setNewMessage] = useState(0)
  useEffect(() => {
    if (!!contextObj?.userData?.user?.id) {
      socket.on(projectDetail._id, (data) => {
        if (!isOpen) {
          setNewMessage(newMessage + 1)
        } else {
          if (data.userId !== contextObj.userData.user.id) {
            socket.emit('UpdateMessage', {
              poId: projectDetail._id,
              ids: [data._id]
            })
          }
          setNewMessage(0)
        }
        setMessages(data)
      })
    }
  }, [socket, messages])

  return {
    columns,
    rows,
    page,
    rowsPerPage,
    projectDetail,
    setProjectDetail,
    values,
    error,
    success,
    setValues,
    handleChangePage,
    handleChangeRowsPerPage,
    toggleDrawer,
    createData,
    onStageChange,
    contextObj,
    newMessage, 
    setNewMessage,
    isOpen,
    setIsOpen,
    sendMessage,
    messages,
    isProLoading,
    setIsProLoading
  }
}
