import React, { useContext } from 'react'
import { CCol, CRow, CFormLabel } from '@coreui/react'
import { Grid, Skeleton } from '@mui/material'
import { useProjectsDetailsHooks } from './useProjectsDetailsHook'
import BasicTable from 'components/uiElements/table/BasicTable'
import { Button as ProductButton } from '@mui/material'
import { HiViewGridAdd } from 'react-icons/hi'
import { AiOutlineAppstoreAdd, AiOutlineEdit } from 'react-icons/ai'
import { Link, useParams } from 'react-router-dom'
import { getDate } from 'shared/util/helper'
import ChatBox from 'components/chat/chatBox'
import { AppBreadcrumb } from 'sections/layout/dashboard'
import { UserContext } from 'context/AuthContext/UserContext'

const ProjectsDetails = (props) => {
  const { userData } = useContext(UserContext)
  const options = [
    { label: 'Waiting', value: 'Waiting' },
    { label: 'Loading', value: 'Loading' },
    { label: 'In Transit', value: 'In Transit' },
    { label: 'Arrived', value: 'Arrived' },
    { label: 'Delivered', value: 'Delivered' }
  ]

  const {
    columns,
    rows,
    page,
    rowsPerPage,
    isPoLoading,
    projectDetail,
    values,
    setValues,
    isLoading,
    handleChangePage,
    handleChangeRowsPerPage,
    onStageChange,
    contextObj,
    isOpen,
    setIsOpen,
    sendMessage,
    messages,
    setNewMessage,
    newMessage,
    isProLoading,
  } = useProjectsDetailsHooks(props)
  const param = useParams()
  let breadCrumbPath = [
    { name: 'Projects', url: `/projects`, active: 'not-allowed' },
    { name: 'Project-Details', url: `/projectsdetails/${param.projectId}`, active: 'not-allowed' },

  ]


  return (
    <>
      <>
        <div className='p-5'>
          <Grid container>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <AppBreadcrumb pathNameDeclare={breadCrumbPath} />
              <h3>Projects Detail</h3>
              <p>Detail of your Projects</p>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Grid textAlign='end' alignItems='end'>
                {userData?.user?.pomUserType === 1 && userData?.user?.role >= 4 ? <ProductButton className='me-2' variant='outlined' component={Link} to={`/project/edit/${param?.projectId}`}>
                  Edit Project &nbsp;&nbsp;
                  <AiOutlineEdit
                    size='1.5rem'
                  />
                </ProductButton> : ("")}
                <ProductButton variant='outlined' component={Link} to={`/projects/delivery/${param?.projectId}`}>
                  Delivery&nbsp;&nbsp;
                  <AiOutlineAppstoreAdd size='1.5rem' />
                </ProductButton>
              </Grid>
            </Grid>
          </Grid>
          <>
            {isPoLoading ? (
              <Skeleton animation='wave' variant='rectangle' width='100%' height='350px' />
            ) : (
              <>
                <CRow className='ms-2 mt-5'>
                  <CCol>
                    <CRow>
                      <CCol sm={6} lg={6}>
                        <CRow>
                          <CCol>
                            <h5>Project ID</h5>
                          </CCol>
                          <CCol>
                            <p>{projectDetail.projectId}</p>
                          </CCol>
                        </CRow>

                        <CRow>
                          <CCol>
                            <h5>Start Date</h5>
                          </CCol>
                          <CCol>
                            <p>{getDate(projectDetail.startDate)}</p>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol>
                            <h5>Vessel Name</h5>
                          </CCol>
                          <CCol>
                            <p>{projectDetail.vesselName}</p>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol>
                            <h5>Load Date</h5>
                          </CCol>
                          <CCol>
                            <p>{getDate(projectDetail.loadDate)}</p>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol>
                            <h5>Freight Line</h5>
                          </CCol>
                          <CCol>
                            <p>{projectDetail.freightLine}</p>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol>
                            <h5>Cutoff Date</h5>
                          </CCol>
                          <CCol>
                            <p>{getDate(projectDetail.cutOffDate)}</p>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol>
                            <h5> Booking Number</h5>
                          </CCol>
                          <CCol>
                            <p>{projectDetail.bookingNumber}</p>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol>
                            <h5> Container</h5>
                          </CCol>
                          <CCol>
                            <p>{projectDetail.container}</p>
                          </CCol>
                        </CRow>
                      </CCol>

                      <CCol sm={6} lg={6}>
                        <CRow>
                          <CCol>
                            <h5>Departure Date</h5>
                          </CCol>
                          <CCol>
                            <p>{getDate(projectDetail.departureDate)}</p>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol>
                            <h5>Departure Port</h5>
                          </CCol>
                          <CCol>
                            <p>{projectDetail.departurePort}</p>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol>
                            <h5>Arrival Date</h5>
                          </CCol>
                          <CCol>
                            <p>{getDate(projectDetail.arrivalDate)}</p>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol>
                            <h5>Arrival Port</h5>
                          </CCol>
                          <CCol>
                            <p>{projectDetail.arrivalPort}</p>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol>
                            <h5>Container Return to Port Date</h5>
                          </CCol>
                          <CCol>
                            <p>{getDate(projectDetail.containerReturntoPortDate)}</p>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol>
                            <h5>Arrival Warehouse</h5>
                          </CCol>
                          <CCol>
                            <p>{projectDetail.arrivalWarehouse}</p>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol>
                            <h5>Delivery Date</h5>
                          </CCol>
                          <CCol>
                            <p>{getDate(projectDetail.deliveryDate)}</p>
                          </CCol>
                        </CRow>
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>

                <CRow className='ms-2 mt-5'>
                  <CCol sm={10} lg={10} style={{ display: 'flex' }}>
                    <CCol>
                      <CFormLabel htmlFor='type' className='col-sm-12 col-form-label' data-testid='Supplier'>
                        <h5>Project Stages</h5>
                      </CFormLabel>
                    </CCol>
                    <CCol>
                      <select
                        value={values.projectStage}
                        onChange={(e) => {
                          setValues({ ...values, projectStage: e.target.value })
                        }}
                      >
                        {options.map((option, index) => {
                          return <option key={index} value={option.value}>
                            {option.value}
                          </option>
                        })}
                      </select>
                    </CCol>
                    <CCol className='ms-5'>
                      <ProductButton variant='outlined' onClick={onStageChange}>
                        Update &nbsp;&nbsp;&nbsp;
                        <HiViewGridAdd size='1.5rem' />
                      </ProductButton>
                    </CCol>
                  </CCol>
                </CRow>

                <BasicTable
                  columns={columns}
                  rows={rows}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  projectDetail={projectDetail}
                  isLoading={isLoading}
                  Nopagination={true}
                  isProLoading={isProLoading}
                />
                <ChatBox
                  isOpen={isOpen}
                  poId={projectDetail._id}
                  poNumber={projectDetail.projectId}
                  userId={contextObj.userData.user.id}
                  sendMessage={sendMessage}
                  messages={messages}
                  setIsOpen={setIsOpen}
                  setNewMessage={setNewMessage}
                  newMessage={newMessage}
                  contextObj={contextObj}
                />
              </>
            )}
          </>
        </div>
      </>
    </>
  )
}

export default ProjectsDetails
