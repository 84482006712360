import React from 'react'
import { Card, IconButton, Tooltip } from '@mui/material'
import PropTypes from 'prop-types'
import './User.css'
import { getAccessCodeDetails } from 'data/Enums'
import Images from 'data/Images'
import { GoUnverified, GoVerified } from 'react-icons/go'
import { FiEdit } from 'react-icons/fi'


const User = (props) => {
  const { disabled } = props

  return (
    <>
      <Card className={`user-card mt-3 ${disabled ? 'user-card-disabled' : ''}`}>
        <div className='row'>
          <div className='col'>
            <h4 className='user-title'>
              {props.title}{' '}
              {props.isVerified ? (
                <Tooltip title='Verified E-Mail'>
                  <sup style={{ color: 'green' }}>
                    <GoVerified size='1.3rem' />
                  </sup>
                </Tooltip>
              ) : (
                <Tooltip title='E-Mail is Not Verified'>
                  <sup style={{ color: '#B82828' }}>
                    <GoUnverified size='1.3rem' />
                  </sup>
                </Tooltip>
              )}
            </h4>
          </div>
          {props.setisAddUser ? (
            <Tooltip title={'Edit User'}>
              <div className='col-1'>
                <div className='d-flex align-items-center justify-content-center'>
                  <div>
                    <IconButton
                      className='hover-effect'
                      aria-label='Activate User'
                      component='span'
                      onClick={() => { props.setParticularUserId(props.id), props.setisAddUser(true) }}
                    >
                      <FiEdit size='0.8em' />
                    </IconButton>
                  </div>
                </div>
              </div>
            </Tooltip>
          ) : null}
        </div>
        <div className='row'>
          <div className='col'>
            <span className='user-email'>{props?.email}</span>
          </div>
        </div>
        <div className='role-container mt-1'>
          <div className='role-image'>
            <img src={Images.Security} alt='security' />
          </div>
          <div className='role-text'>
            <span className='user-role'>{getAccessCodeDetails(props?.code)?.label}</span>
            <span className='user-role-desc'>{getAccessCodeDetails(props?.code)?.detail}</span>
          </div>
        </div>
      </Card>
    </>
  )
}

User.propTypes = {
  title: PropTypes.string,
  email: PropTypes.string,
  code: PropTypes.number,
  isActive: PropTypes.bool,
  length: PropTypes.number,
  isVerified: PropTypes.bool,
  setisAddUser: PropTypes.func,
  id: PropTypes.string,
  setParticularUserId: PropTypes.func,
  disabled: PropTypes.bool,
}

export default User
