import React from 'react'
import { Grid, Box } from '@mui/material'
import PropTypes from 'prop-types'
import CommonBox from './CommonBox'

const DataBoxGrid = (props) => {
    const { data, rangeData, showRangeFilter, rangeFilterTitle } = props
    return (
        <>
            <Grid container spacing={2} alignItems="center">
                {data.map((item, index) => {
                    return (
                        <Grid item xs={4} key={index}>
                            <CommonBox
                                title={item.title}
                                amount={item.amount}
                                difference={item.difference}
                                disabled={item.disabled}
                                isSales={item.isSales}
                            />
                        </Grid>
                    )
                })}
            </Grid>
            {showRangeFilter && (
                <Box>
                    <h5 className='ms-3 mt-2'>{rangeFilterTitle}</h5>
                    <Grid container spacing={2} alignItems='center'>
                        {rangeData.map((item, index) => (
                            <Grid item xs={4} key={index}>
                                <CommonBox
                                    title={item.title}
                                    amount={item.amount}
                                    difference={item.difference}
                                    disabled={item.disabled}
                                    isSales={item.isSales}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            )}
        </>
    )
}


DataBoxGrid.propTypes = {
    data: PropTypes.array.isRequired,
    rangeData: PropTypes.array,
    showRangeFilter: PropTypes.bool,
    rangeFilterTitle: PropTypes.string
}

export default DataBoxGrid
