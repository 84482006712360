import apiClient from 'api/apiClient'
import { endpoints } from 'api/endpoints'

const purchaseOrderService = {
  addPurchaseOrder: async (input) => {
    try {
      const response = await apiClient.post(endpoints.ADD_PURCHASE_ORDER, input)
      return response
    } catch (error) {
      throw error
    }
  },

  getPurchaseOrder: async (companyId) => {
    try {
      const response = await apiClient.get(endpoints.GET_COMPANY_PURCHASE_ORDER(companyId))
      return response
    } catch (error) {
      throw error.response
    }
  },

  filterPurchaseOrder: async (
    userType,
    companyId,
    filterProductId,
    po,
    shipVia,
    shipTo,
    supplier,
    order_deadline,
    poDueDate
  ) => {
    try {
      const response = await apiClient.get(
        endpoints.FILTER_PURCHASE_ORDER(
          userType,
          companyId,
          filterProductId,
          po,
          shipVia,
          shipTo,
          supplier,
          order_deadline,
          poDueDate
        )
      )
      return response
    } catch (error) {
      throw error.response
    }
  },

  getPurchaseOrderIds: async (companyId, type, userId) => {
    try {
      const response = await apiClient.get(endpoints.GET_PURCHASE_ORDER_IDS(companyId, type, userId))
      return response
    } catch (error) {
      throw error.response
    }
  },

  getSinglePurchaseOrder: async (PoNumber) => {
    try {
      const response = await apiClient.get(endpoints.GET_SINGLE_PURCHASE_ORDER(PoNumber))
      return response
    } catch (error) {
      throw error.response
    }
  },

  getSupplierPurchaseOrder: async (supplierId) => {
    try {
      const response = await apiClient.get(endpoints.GET_SUPPLIER_PURCHASE_ORDER(supplierId))
      return response
    } catch (error) {
      throw error.response
    }
  },

  getProductsOnIds: async (ids) => {
    try {
      const response = await apiClient.get(endpoints.GET_PRODUCTS_ON_IDS(ids))
      return response
    } catch (error) {
      throw error.response
    }
  },

  UpdatePoSkuProposedQty: async (poId, productId, input) => {
    try {
      const response = await apiClient.patch(endpoints.UPDATE_PO_SKU_PROPOSED_QTY(poId, productId), input)
      return response
    } catch (error) {
      throw error.response
    }
  },

  UpdatePoSkuValidateQty: async (poId, input) => {
    try {
      const response = await apiClient.patch(endpoints.UPDATE_PO_SKU_VALIDATE_QTY(poId), input)
      return response
    } catch (error) {
      throw error.response
    }
  },

  sendTextMessage: async (input) => {
    try {
      const response = await apiClient.post(endpoints.SEND_TEXT_MESSAGE, input)
      return response
    } catch (error) {
      throw error.response
    }
  },

  getAllChats: async (role, companyId, userId) => {
    try {
      const response = await apiClient.get(endpoints.GET_ALL_CHATS(role, companyId, userId))
      return response
    } catch (error) {
      throw error.response
    }
  },

  getAllPoChats: async (poId, page, limit) => {
    try {
      const response = await apiClient.get(endpoints.GET_ALL_PO_CHATS(poId, page, limit))
      return response
    } catch (error) {
      throw error.response
    }
  },

  getAllQueryChats: async (poId, page, limit) => {
    try {
      const response = await apiClient.get(endpoints.GET_ALL_QUERY_CHATS(poId, page, limit))
      return response
    } catch (error) {
      throw error.response
    }
  },

  getAllUnreadChats: async (userId) => {
    try {
      const response = await apiClient.get(endpoints.GET_ALL_UNREAD_CHATS(userId))
      return response
    } catch (error) {
      throw error.response
    }
  },

  getPurchaseOrdersOnIds: async (ids) => {
    try {
      const response = await apiClient.get(endpoints.GET_PO_ON_IDS(ids))
      return response
    } catch (error) {
      throw error.response
    }
  },
  getDeliveryOnIds: async (ids) => {
    try {
      const response = await apiClient.get(endpoints.GET_DELIVERY_ON_IDS(ids))
      return response
    } catch (error) {
      throw error.response
    }
  },

  deletePurchaseOrder: async (poNumber) => {
    try {
      const response = await apiClient.delete(endpoints.DELETE_PURCHASE_ORDER(poNumber))
      return response
    } catch (error) {
      throw error.response
    }
  },

  updatePurchaseOrder: async (input) => {
    try {
      const response = await apiClient.put(endpoints.UPDATE_PURCHASE_ORDER(input.PoNumber), input)
      return response
    } catch (error) {
      throw error.response
    }
  },

  updateisDispatch: async (id, input) => {
    try {
      const response = await apiClient.patch(endpoints.UPDATE_IS_DISPATCH(id), input)
      return response
    } catch (error) {
      throw error.response
    }
  },

  createInitialDelivery: async (input) => {
    try {
      const response = await apiClient.post(endpoints.CREATE_INITIAL_DELIVERY, input)
      return response
    } catch (error) {
      throw error
    }
  },

  getDeliveriesOnPoNumber: async (poNumber) => {
    try {
      const response = await apiClient.get(endpoints.GET_DELIVERIES_ON_PO_NUMBER(poNumber))
      return response
    } catch (error) {
      throw error.response
    }
  },

  getSingleDeliveryOnDeliveryId: async (deliveryId) => {
    try {
      const response = await apiClient.get(endpoints.GET_SINGLE_DELIVERY_ON_DELIVERY_ID(deliveryId))
      return response
    } catch (error) {
      throw error.response
    }
  },

  updateDelivery: async (deliveryId, input) => {
    try {
      const response = await apiClient.patch(endpoints.UPDATE_DELIVERY(deliveryId), input)
      return response
    } catch (error) {
      throw error.response
    }
  },

  updateDeliveryStatus: async (deliveryId, input) => {
    try {
      const response = await apiClient.patch(endpoints.UPDATE_DELIVERY_STATUS(deliveryId), input)
      return response
    } catch (error) {
      throw error.response
    }
  },

  updatePurchaseOrderStatus: async (poNumber, input) => {
    try {
      const response = await apiClient.patch(endpoints.UPDATE_PURCHASE_ORDER_STATUS(poNumber), input)
      return response
    } catch (error) {
      throw error.response
    }
  },

  getPurchaseDeliveryOnPoNumber: async (ids) => {
    try {
      const response = await apiClient.get(endpoints.GET_PURCHASE_DELIVERY_ON_PO_NUMBER(ids))
      return response
    } catch (error) {
      throw error.response
    }
  },

  addProject: async (input) => {
    try {
      const response = await apiClient.post(endpoints.ADD_PROJECT, input)
      return response
    } catch (error) {
      throw error
    }
  },

  filterProject: async (
    companyId,
    projectId,
    bookingNumber,
    container,
    freightLine,
    projectStage,
    loadDate,
    arrivalDate
  ) => {
    try {
      const response = await apiClient.get(
        endpoints.FILTER_PROJECT(
          companyId,
          projectId,
          bookingNumber,
          container,
          freightLine,
          projectStage,
          loadDate,
          arrivalDate
        )
      )
      return response
    } catch (error) {
      throw error.response
    }
  },

  getAllProjectonCompanyId: async (companyId) => {
    try {
      const response = await apiClient.get(endpoints.GET_ALL_PROJECTS_ON_COMPANY_ID(companyId))
      return response
    } catch (error) {
      throw error.response
    }
  },

  getProjectOnProjectId: async (projectId) => {
    try {
      const response = await apiClient.get(endpoints.GET_PROJECT_BY_PROJECT_ID(projectId))
      return response
    } catch (error) {
      throw error.response
    }
  },

  deleteProjectOnProjectId: async (projectId) => {
    try {
      const response = await apiClient.delete(endpoints.DELETE_PROJECT_BY_PROJECT_ID(projectId))
      return response
    } catch (error) {
      throw error.response
    }
  },

  updateProjectOnProjectId: async (input) => {
    try {
      const response = await apiClient.patch(endpoints.UPDATE_PROJECT_BY_PROJECT_ID(input.projectId), input)
      return response
    } catch (error) {
      throw error.response
    }
  },

  createDelivery: async (input) => {
    try {
      const response = await apiClient.post(endpoints.CREATE_PROJECT_DELIVERY, input)
      return response
    } catch (error) {
      throw error.response
    }
  },

  createBackOrder: async (input) => {
    try {
      const response = await apiClient.post(endpoints.CREATE_BACKORDER, input)
      return response
    } catch (error) {
      throw error.response
    }
  },

  getDeliveryByProjectId: async (projectId) => {
    try {
      const response = await apiClient.get(endpoints.GET_DELIVERY_BY_PROJECT_ID(projectId))
      return response
    } catch (error) {
      throw error.response
    }
  },

  getSingleDeliveryId: async (deliveryId) => {
    try {
      const response = await apiClient.get(endpoints.GET_SINGLE_DELIVERY_ID(deliveryId))
      return response
    } catch (error) {
      throw error.response
    }
  },

  updateProjectDeliveryStatus: async (deliveryId, input) => {
    try {
      const response = await apiClient.patch(endpoints.UPDATE_PROJECT_DELIVERY_STATUS(deliveryId), input)
      return response
    } catch (error) {
      throw error.response
    }
  },

  UpdatePoInDelivery: async (deliveryId, input) => {
    try {
      const response = await apiClient.patch(endpoints.UPDATE_PO_IN_DELIVERY(deliveryId), input)
      return response
    } catch (error) {
      throw error.response
    }
  },

  getSalesData: async (startDate, endDate, page, limit, comparison, sortType, sortOrder, shop, country, warehouse, shop_type, sku) => {
    try {
      const response = await apiClient.get(
        // endpoints.GET_SALES_DATA(startDate, endDate, page, limit)\
        endpoints.GET_SALES_DATA(startDate, endDate, page, limit, comparison, sortType, sortOrder, shop, country, warehouse, shop_type, sku)
      )
      return response
    } catch (error) {
      throw error.response
    }
  },

  getShopShipmentData: async (startDate, endDate, comparison, shop, country, warehouse, shop_type, sku) => {
    try {
      const response = await apiClient.get(
        // endpoints.GET_SALES_DATA(startDate, endDate, page, limit)\
        endpoints.GET_PIE_CHART_DATA(startDate, endDate, comparison, shop, country, warehouse, shop_type, sku)
      )
      return response
    } catch (error) {
      throw error.response
    }
  },


  getTotalSalesData: async (startDate, endDate, presetValue, groupDataValue, fromDate, toDate, comparison, shop, country, warehouse, shop_type, sku) => {
    try {
      const response = await apiClient.get(
        // endpoints.GET_TOTAL_SALES_DATA(
        //   startDate,
        //   endDate,
        //   presetValue,
        //   groupDataValue,
        //   fromDate,
        //   toDate
        // )
        endpoints.GET_TOTAL_SALES_DATA(
          startDate,
          endDate,
          presetValue,
          groupDataValue,
          fromDate,
          toDate,
          comparison,
          shop, country, warehouse, shop_type, sku
        )
      )
      return response
    } catch (error) {
      throw error.response
    }
  },


  getGraphData: async (startDate, endDate, filterType, year, country = null, shop = null) => {
    try {
      const queryParams = new URLSearchParams({
        startDate,
        endDate,
        filterType,
        year
      })

      if (filterType === 'shop' && country) {
        queryParams.append('country', country)
      }

      if (filterType === 'country' && shop) {
        queryParams.append('shop', shop)
      }

      const response = await apiClient.get(
        endpoints.GET_GRAPH_DATA(queryParams)
        // `http://localhost:5010/api/salesdashboard${endpoints.GET_GRAPH_DATA(queryParams)}`
      )
      return response
    } catch (error) {
      throw error.response
    }
  },

  getSalesDataYears: async (dateObj) => {
    try {
      const response = await apiClient.get(
        endpoints.GET_SALES_DATA_YEARS(dateObj)
      )
      return response
    } catch (error) {
      throw error.response
    }
  },

  getSaleslisting: async (query) => {
    try {
      const response = await apiClient.get(
        endpoints.GET_SALES_LISTING(query)
        // `http://localhost:5010/api/salesdashboard${endpoints.GET_SALES_LISTING(query)}`
      )
      return response
    } catch (error) {
      throw error.response
    }
  },

  getSalesReportByCountry: async (query) => {
    try {
      const response = await apiClient.get(
        endpoints.GET_SALES_REPORT_BY_SHOP(query)
        // `http://localhost:5010/api/salesdashboard${endpoints.GET_SALES_REPORT_BY_SHOP(query)}`
      )
      return response
    } catch (error) {
      throw error.response
    }
  },


  getWarehouseData: async () => {
    try {
      const response = await apiClient.get(endpoints.GET_WAREHOUSE_DATA)
      return response
    } catch (error) {
      throw error.response
    }
  },

  getShipmentData: async () => {
    try {
      const response = await apiClient.get(endpoints.GET_SHIPMENT_DATA)
      return response
    } catch (error) {
      throw error.response
    }
  },

  getShopData: async () => {
    try {
      const response = await apiClient.get(endpoints.GET_SHOP_DATA)
      return response
    } catch (error) {
      throw error.response
    }
  },

  getAllSalesData: async () => {
    try {
      const response = await apiClient.get(endpoints.GET_ALL_SALES_DATA)
      return response
    } catch (error) {
      throw error.response
    }
  },

  addAdvertisement: async (input) => {
    try {
      const response = await apiClient.post(endpoints.ADD_ADVERTISEMENT_DATA, input)
      return response
    } catch (error) {
      throw error
    }
  },

  getAdvertisementReport: async (query) => {
    try {
      const response = await apiClient.get(
        // endpoints.GET_SALES_DATA_YEARS(startDate, endDate)
        endpoints.GET_ADVERTISEMENT_REPORT(query)
      )
      return response
    } catch (error) {
      throw error.response
    }
  },
  getTotalRevenue: async (query) => {
    try {
      const response = await apiClient.get(
        // endpoints.GET_SALES_DATA_YEARS(startDate, endDate)
        endpoints.GET_TOTAL_REVENUE(query)
      )
      return response
    } catch (error) {
      throw error.response
    }
  },
  getFilterListingAdvertisment: async (query) => {
    try {
      const response = await apiClient.get(
        // `http://localhost:5009/api/advertisement/${endpoints.GET_FILTER_LIST_ADVERTISEMENT(query)}`
        endpoints.GET_FILTER_LIST_ADVERTISEMENT(query)
      )
      return response
    } catch (error) {
      throw error.response
    }
  },
  
  addRefund: async (input) => {
    try {
      const response = await apiClient.post(endpoints.ADD_REFUND_DATA, input)
      return response
    } catch (error) {
      throw error
    }
  },
  getRefundList: async (query) => {
    try {
      const response = await apiClient.get(endpoints.GET_REFUND_DATA(query))
      return response
    } catch (error) {
      throw error
    }
  },
  getMarketplaceList: async () => {
    try {
      const response = await apiClient.get(endpoints.GET_MARKETPLACE_DATA)
      return response
    } catch (error) {
      throw error
    }
  },
  getOrderCountry: async (orderNumber) => {
    try {
      const response = await apiClient.get(endpoints.GET_COUNTRY_DATA(orderNumber))
      return response
    } catch (error) {
      throw error
    }
  }
}

export default purchaseOrderService
