import React from 'react'
import PropTypes from 'prop-types'

import './Types.css'
import { Box, CardMedia, Modal, Typography } from '@mui/material'
import { useState } from 'react'
import { getChatMessageDateTime } from 'shared/util/helper'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
}

const ImageMessage = (props) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div className='sc-message--image'>
      <CardMedia image={props?.data?.message} height='75%' component='img' onClick={() => setIsOpen(true)} />
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            {props.data.userId === props.userId ? 'You' : props.data.userName}
          </Typography>

          <CardMedia image={props?.data?.message} height='75%' component='img' onClick={() => setIsOpen(true)} />
          <Typography id='modal-modal-description' sx={{ mt: 2 }} style={{textAlign:"end"}}>
            {getChatMessageDateTime(props.data?.createdAt)}
          </Typography>
        </Box>
      </Modal>
    </div>
  )
}

ImageMessage.propTypes = {
  data: PropTypes.object,
  userId: PropTypes.string
}

export default ImageMessage
