import React from 'react'
import PropTypes from 'prop-types' // Import PropTypes for prop type validation
import { FormControl, Grid, MenuItem, Select, TextField, Tooltip, InputLabel } from '@mui/material'
import { Button as ProductButton } from '@mui/material' // Import Button as ProductButton
import { GrPowerReset } from 'react-icons/gr'

const OrderFilters = ({ filters, handleFilterChange }) => {
    return (

        <Grid container alignItems="center" item lg={12} md={12} sm={12} xs={12} sx={{ marginBottom: '20px', '& > *': { marginX: '5px' } }}>
            <Grid xs={1.5}>
                <Tooltip title='Search Order'>
                    <FormControl fullWidth size='small' variant='outlined'>
                        <TextField
                            label='Order ID'
                            size='medium'

                            variant='filled'
                            value={filters.order_id || ''}
                            onChange={handleFilterChange('order_id')}
                        />
                    </FormControl>
                </Tooltip>
            </Grid>
            <Grid xs={1.5}>
                <Tooltip title='Search Customer'>
                    <FormControl fullWidth variant='filled'>
                        <TextField
                            label='Customer'
                            size='medium'
                            variant='filled'
                            value={filters.customer || ''}
                            onChange={handleFilterChange('customer')}
                        />
                    </FormControl>
                </Tooltip>
            </Grid>
            <Grid xs={1.5}>
                <FormControl fullWidth variant='filled'>
                    <InputLabel id='demo-simple-select-filled-label'>State</InputLabel>
                    <Select
                        labelId='demo-simple-select-filled-label'
                        id='demo-simple-select-filled'
                        value={filters.state || ''}
                        onChange={handleFilterChange('state')}
                    >
                        <MenuItem value='shipping'>
                            <em>Shipping</em>
                        </MenuItem>
                        <MenuItem value='shipped'>
                            <em>Shipped</em>
                        </MenuItem>
                        <MenuItem value='canceled'>
                            <em>Canceled</em>
                        </MenuItem>
                        <MenuItem value='waiting_acceptance'>
                            <em>Waiting_acceptance</em>
                        </MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid xs={1.5}>
                <FormControl fullWidth variant='filled'>
                    <InputLabel id='demo-simple-select-filled-label'>Source</InputLabel>
                    <Select
                        labelId='demo-simple-select-filled-label'
                        id='demo-simple-select-filled'
                        value={filters.source || ''}
                        onChange={handleFilterChange('source')}
                    >
                        <MenuItem value='bulk'>
                            <em>Bulk</em>
                        </MenuItem>
                        <MenuItem value='manual'>
                            <em>Manual</em>
                        </MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid xs={1.5}>
            <FormControl fullWidth  variant='outlined'>
                <TextField
                    label='Creation Date'
                    type='date'
                    size='medium'
                    variant='filled'
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={filters.creation_date || ''}
                    onChange={handleFilterChange('creation_date')}
                />
            </FormControl>
            </Grid>
            <Grid xs={2}>
            <ProductButton
                // sx={{ m: 2, minWidth: 50 }}
                sx={{
                    minHeight: 50,
                    fontSize: '1.0rem',
                }}
                size='large'
                variant="outlined"
                onClick={handleFilterChange('null')}
            >
                Reset Filter <span ><GrPowerReset className='reset-icon ms-2' size='1.5rem' /></span>
            </ProductButton>

            </Grid>
        </Grid>
    )
}

OrderFilters.propTypes = {
    filters: PropTypes.object.isRequired, // Validate filters prop as an object
    handleFilterChange: PropTypes.func.isRequired, // Validate handleFilterChange prop as a function
}

export default OrderFilters
