import { TableCell, TableRow } from '@mui/material'
import React from 'react'

const Skeleton = () => {
    return (
        <TableRow>
            <TableCell>
                {Array.from({ length: 5 }).map((_, index) => (
                    <div key={index} style={{ marginBottom: '0' }}>
                        <Skeleton animation="wave" height={100} />
                    </div>
                ))}
            </TableCell>
        </TableRow>
    )
}

export default Skeleton