import { TextField } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import SendButton from './SendButton'
import { BiImageAdd } from 'react-icons/bi'
import uploadServices from 'shared/services/upload.service'

const UserInput = (props) => {
  const [inputActive, setInputActive] = useState(false)
  const [inputText, setInputText] = useState()

  const submitText = (event) => {
    event.preventDefault()

    props.onSubmit(inputText, 'text')
    setInputText('')
  }
  const handleUpload = async () => {
    const formData = new FormData()
    formData.append('files', props?.image?.file)
    formData.append('SubDirectory', props.userId)
    formData.append('Directory', props.poId)
    props.setIsLoading(true)

    await uploadServices.postImage(formData).then(
      (response) => {
        if (props?.image?.file.type === 'application/pdf') {
          props.onSubmit(response[0], 'pdf')
        } else {
          props.onSubmit(response[0], 'image')
        }
        props.setIsLoading(false)
        props.setImage({})
      },
      () => {
        props.setIsLoading(false)
      }
    )
  }

  const renderSendOrFileIcon = () => {
    return (
      <div className='sc-user-input--button'>
        {Object.keys(props.image).length !== 0 ? (
          <SendButton onClick={() => handleUpload()} />
        ) : (
          <SendButton onClick={(e) => submitText(e)} />
        )}
      </div>
    )
  }
  const selectImage = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0]
      props.setImage({ file: file })
    }
  }

  const renderFile = () => {
    return (
      <div className='sc-user-input--button'>
        <input
          accept='image/*,.pdf'
          id='icon-button-file'
          className='sc-user-input--send-icon-wrapper'
          type='file'
          style={{ display: 'none' }}
          onChange={(e) => selectImage(e)}
        />
        <label htmlFor='icon-button-file'>
          <BiImageAdd className='send-icon' size='1.5em' />
        </label>
      </div>
    )
  }

  return (
    <form className={`sc-user-input ${inputActive ? 'active' : ''}`}>
      <TextField
        tabIndex='0'
        onFocus={() => {
          setInputActive(false)
        }}
        onBlur={() => {
          setInputActive(false)
        }}
        onChange={(e) => setInputText(e.target.value)}
        value={inputText}
        contentEditable='true'
        placeholder='Write a Query'
        className='sc-user-input--text'
      />
      <div className='sc-user-input--image pt-3'>{renderFile()}</div>
      <div className='sc-user-input--buttons'>{renderSendOrFileIcon()}</div>
    </form>
  )
}

UserInput.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  userId: PropTypes.string,
  poId: PropTypes.string,
  onSubmit: PropTypes.func,
  image: PropTypes.string,
  setImage: PropTypes.func,
  isLoading: PropTypes.string,
  setIsLoading: PropTypes.func
}

export default UserInput
