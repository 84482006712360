import React, { useState, createRef, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import SimpleBar from 'simplebar-react'

import Footer from './Footer'
import Header from './Header'
import Company from './messageTypes/Company'
import Buyer from './messageTypes/Buyer'
import Notification from './messageTypes/Notification'
import { getChatMessageDateTime, getFromattedDateTime } from 'shared/util/helper'

import './Body.css'
import ImageMessage from 'components/chat/chatBox/messages/types/ImageMessage'
import PdfMessage from 'components/chat/chatBox/messages/types/PdfMessage'
import ImageFiles from 'components/chat/chatBox/messages/imageFiles'
import uploadServices from 'shared/services/upload.service'
import { UserContext } from 'context/AuthContext/UserContext'

const ChatBody = (props) => {
  const { socket, userId, poId, userName, value, chatName } = props
  const {showToast} = useContext(UserContext)
  const messagesEndRef = createRef(null)
  const [message, setMessage] = useState('')
  const [image, setImage] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    if (!!userId) {
      socket.on(poId, (data) => {
        props.setChat(data)
      })
    }
  }, [socket, props.chat])

  const handleChange = () => {
    if (message !== '') {
      const chatMessage = {
        type: 'text',
        author: 'company',
        data: { text: message },
        createdOn: new Date(),
        unread: true
      }
      sendMessage(chatMessage)
      setMessage('')
    }
  }

  useEffect(() => {
    scrollToBottom()
  }, [props.chat])

  useEffect(() => {
    if (props.chat.length > 0) {
      props.setChat(props.chat)
    }
  }, [])

  const handleKeypress = (e) => {
    if (e.key === 'Enter') {
      handleChange()
    }
  }

  const onStateChange = (e) => {
    e.preventDefault()
    setMessage(e.target.value)
  }


  const sendMessage = (msg, type) => {
    if(!msg){
      return showToast("Please add messages First" , false)
    }
    socket.emit('Message', {
      userId: userId,
      poId: poId,
      userName: userName,
      message: msg.trim(),
      isRead: false,
      type: type,
      chatType: value,
      poNumber: chatName,
      socketID: socket.id
    })
    setMessage('')
  }
  const handleUpload = async () => {
    const formData = new FormData()
    formData.append('files', image?.file)
    formData.append('SubDirectory', userId)
    formData.append('Directory', poId)
    setIsLoading(true)
    await uploadServices.postImage(formData).then(
      (response) => {
        if (image?.file.type === 'application/pdf') {
          sendMessage(response[0], 'pdf')
        } else {
          sendMessage(response[0], 'image')
        }
        setIsLoading(false)
        setImage({})
      },
      () => {
        setIsLoading(false)
      }
    )
  }

  const selectImage = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0]
      setImage({ file: file })
    }
  }
  const renderMessage = (item, index) => {
    if (item.userId === userId) {
      return (
        <>
          {item.type === 'text' ? (
            <Company
              index={index}
              message={item.message}
              createdOn={item.createdAt}
              logo={props.companyLogo}
            />
          ) : item.type === 'image' ? (
            <div className='d-flex flex-row justify-content-end' data-testid='company' key={index}>
              <div className='message-text'>
                <ImageMessage data={item} userId={userId} />
                <p className='small ms-3 mb-3 rounded-3 text-muted float-end'>
                  {getChatMessageDateTime(item.createdAt)}
                </p>
              </div>
            </div>
          ) : item.type === 'pdf' ? (
            <div className='d-flex flex-row justify-content-end' data-testid='company' key={index}>
              <div className='message-text'>
                <PdfMessage data={item} userId={userId} />
                <p className='small ms-3 mb-3 rounded-3 text-muted float-end'>
                  {getChatMessageDateTime(item.createdAt)}
                </p>
              </div>
            </div>
          ) : (
            ''
          )}
        </>
      )
    } else {
      return (
        <>
          {item.type === 'text' ? (
            <Buyer index={index} message={item.message} name={item.userName} createdOn={item.createdAt} />
          ) : item.type === 'image' ? (
            <div className='d-flex flex-row justify-content-start' data-testid='buyer' key={index}>
              <div className='message-text'>
                <ImageMessage data={item} userId={userId} />
                <p className='small ms-3 mb-3 rounded-3 text-muted float-end'>
                  {getChatMessageDateTime(item.createdAt)}
                </p>
              </div>
            </div>
          ) : item?.type === 'pdf' ? (
            <div className='d-flex flex-row justify-content-start' data-testid='buyer' key={index}>
              <div className='message-text'>
                <PdfMessage data={item} userId={userId} />
                <p className='small ms-3 mb-3 rounded-3 text-muted float-end'>
                  {getChatMessageDateTime(item.createdAt)}
                </p>
              </div>
            </div>
          ) : (
            ''
          )}
        </>
      )
    }
  }
  return (
    <div data-testid='chatContent' className='chat-content'>
      {props.isMobile && (
        <Header
          clearChat={props.clearChat}
          name={props.chat[props.chat.length - 1].userName}
          lastActive={props.chat[props.chat.length - 1].createdAt}
        />
      )}
      <SimpleBar style={{ height: props.isMobile ? 'calc(100vh - 21rem)' : 'calc(100vh - 16rem' }}>
        <div className='pt-3 pe-3' style={{ position: 'relative' }}>
          {Object.keys(image).length !== 0 ? (
            <ImageFiles image={image?.file} isLoading={isLoading} />
          ) : (
            <>
              {' '}
              <Notification
                message={
                  props?.chat?.createdAt
                    ? `Converstation Started on ${getFromattedDateTime(props.chat.createdAt)}`
                    : 'Converstation Not Started'
                }
              />
              {Array.isArray(props.chat?.messages) &&
                props.chat?.messages.map((itm, index) => {
                  return <div key={index}>{renderMessage(itm, index)}</div>
                })}
              <div ref={messagesEndRef} />
            </>
          )}
        </div>
      </SimpleBar>
      <Footer
        onStateChange={onStateChange}
        message={message}
        handleKeypress={handleKeypress}
        sendMessage={sendMessage}
        logo={props.companyLogo}
        selectImage={selectImage}
        image={image}
        setImage={setImage}
        isLoading={isLoading}
        handleUpload={handleUpload}
      />
    </div>
  )
}

ChatBody.propTypes = {
  chat: PropTypes.object,
  companyLogo: PropTypes.string,
  catalogueName: PropTypes.string,
  buyerLogo: PropTypes.string,
  setChat: PropTypes.func,
  isMobile: PropTypes.bool,
  clearChat: PropTypes.func,
  handleReply: PropTypes.func,
  socket: PropTypes.object,
  poId: PropTypes.string,
  userId: PropTypes.string,
  value: PropTypes.string,
  chatName: PropTypes.string,
  userName: PropTypes.string
}

export default ChatBody
