import React from 'react'
import PropTypes from 'prop-types'

import Queried from './Queried'

import './CatalogueDisplay.css'

const PoNumber = (props) => {
  return (
    <>
      <span>
        <button className='tracking_code'>
          <span className='cat-title'>{props.name}</span>
        </button>
        <Queried isQuery={props.isQuery} isQueryPending={props.isQueryPending} catalogueId={props.catalogueId} />
      </span>
    </>
  )
}

PoNumber.propTypes = {
  name: PropTypes.string,
  isQuery: PropTypes.bool,
  isQueryPending: PropTypes.bool,
  catalogueId: PropTypes.string
}

export default PoNumber
