import React from 'react'
import PropTypes from 'prop-types'
import { Grid, TableRow, TableCell, Select, MenuItem, FormControl, Skeleton, Button, CircularProgress, Tooltip, TextField } from '@mui/material'
import { useAdvertisementHooks } from './useAdvertisementHooks'
import ModalDialog from 'components/uiElements/modal/Dialog'
import { FaFileImport } from 'react-icons/fa'
import CustomisedInput from 'components/formElements/CustomisedInput'
import Loader from 'components/common/Loader'
import HorizontalStatus from 'components/common/status/HorizontalStatus'
import AdvertisementGraph from 'components/graph/advertisementGraph'
import AdvertisementTable from 'components/advertisement/advertisementTable'
import Constants from 'data/Constants'
import AdvertisementDataBox from 'components/advertisement/advertisementDataBox'

const SkeletonRow = ({ cols }) => (
  <TableRow>
    {Array.from({ length: cols }, (_, index) => (
      <TableCell key={index}>
        <Skeleton variant='text' />
      </TableCell>
    ))}
  </TableRow>
)

SkeletonRow.propTypes = {
  cols: PropTypes.number.isRequired
}

const Advertisement = () => {
  const { shipment, shop, filteredShipment, setSelectedShop, selectedShop, setSelectedShipmentCountry, selectedShipmentCountry, adGroups, selectedAdGroup, setSelectedAdGroup,
    setProductSku, advertisementReport, totalRevenue, graphDetails, reportLoadng, skuData, newParam, getPercentageDifference, skurowsPerPage,
    skupage, setSkuPage, importModalOpen, loading, handleSkuChangePage, handleSkuChangeRowsPerPage, handleImportModal, submitData,
    convertData, onChangeFilter, filterShipmentCountry, setFilterShipmentCountry, filterShop, setFilterShop, setPeriodType, periodType } = useAdvertisementHooks()

  const data = [
    { title: 'CTR', amount: `${getPercentageDifference(advertisementReport?.totalClicks, advertisementReport?.totalImpressions).toFixed(4)} %`, difference: 12, isValue: false, color: '#D25E5E' },
    { title: 'Conversions', amount: advertisementReport?.totalConversions.toFixed(0), color: '#34C085' },
    { title: 'CPC', amount: `€ ${advertisementReport?.totalCpc ? advertisementReport?.totalCpc?.toFixed(4) : 0}`, difference: 1, disabled: 'selectedYear' === 'currentYear', isSales: true, color: '#487CCB' },
    { title: 'Spend', amount: `€ ${advertisementReport?.totalSpend?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`, color: '#487CCB' },
    { title: 'Revenue', amount: `€ ${advertisementReport?.totalRevenue?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`, color: '#34C085' },
    { title: 'ACOS', amount: `${getPercentageDifference(advertisementReport?.totalSpend, advertisementReport?.totalRevenue).toFixed(4)} %`, isValue: false, color: '#D25E5E' },
    { title: 'ROAS', amount: `${getPercentageDifference(advertisementReport?.totalRevenue, advertisementReport?.totalSpend).toFixed(4)} %`, isValue: false, color: '#34C085' },
    { title: 'TACOS', amount: `${getPercentageDifference(advertisementReport?.totalSpend, totalRevenue?.totalRevenue).toFixed(4)} %`, isValue: false, color: '#D25E5E' },
    { title: 'Total Revenue', amount: `€ ${totalRevenue?.totalRevenue?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`, color: '#34C085' }
  ]
  return (
    <section className='page-spacing'>
      <div className='container-fluid'>
        <div className='common-card rounded-3'>
          <Grid className='p-2 ms-2' item xs={12} sm={6} md={4} lg={1} >
            <Grid >
              <h3 className='fw-bold advertisement-report' >Advertisement Report <span ></span></h3>
              <p className='fw-semibold' >{totalRevenue?.totalRevenue ? `€ ${totalRevenue?.totalRevenue?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : ""}  <button className='ms-2 rounded-3 growth'>Growth</button></p>
            </Grid>
            {/* FILTER INPUTS */}
            <Grid container spacing={2} sx={{ marginTop: '30px', marginBottom: '30px' }}>
              <Grid item xs={12} sm={6} md={4} lg={1}>
                <Tooltip title={'Sku or Design'}>
                  <FormControl fullWidth sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }} size='small' className='border-none'>
                    <TextField
                      placeholder='Sku or Design'
                      size='small'
                      variant='outlined'
                      InputLabelProps={{
                        shrink: false,
                      }}
                      input={<CustomisedInput />}
                      onChange={(e) => { setProductSku(e.target.value.trim()), setSkuPage(0) }}
                    />
                  </FormControl>
                </Tooltip>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={1}>
                <FormControl fullWidth sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }} size='small' className='border-none'>
                  <Select
                    value={selectedShop}
                    displayEmpty
                    InputLabelProps={{ shrink: false }}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return `Shops`
                      }
                      return selected
                    }}
                    onChange={(e) => {
                      setSelectedShop(e.target.value)
                    }}
                    IconComponent={() => null}
                  >
                    <MenuItem value=''>All Shops</MenuItem>
                    {shop.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={1}>
                <FormControl fullWidth sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }} size='small' className='border-none'>
                  <Select
                    value={selectedShipmentCountry}
                    displayEmpty
                    InputLabelProps={{ shrink: false }}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return `Shipment Country`
                      }
                      return selected
                    }}
                    IconComponent={() => null}
                    onChange={(e) => {
                      setSelectedShipmentCountry(e.target.value)
                    }}
                  >
                    <MenuItem value=''>All Country</MenuItem>
                    {Array.isArray(filteredShipment) && filteredShipment?.length ? filteredShipment?.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    )) : ""}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={1}>
                <FormControl fullWidth sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }} size='small' className='border-none'>
                  <Select
                    value={selectedAdGroup}
                    displayEmpty
                    InputLabelProps={{ shrink: false }}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return `Ad Groups`
                      }
                      return selected
                    }}
                    IconComponent={() => null}
                    onChange={(e) => { setSelectedAdGroup(e.target.value) }}
                  >
                    <MenuItem value=''>Ad Groups</MenuItem>
                    {adGroups?.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={1}>
                <FormControl fullWidth sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }} size='small' className='border-none'>
                  <TextField
                    type='date'
                    size='small'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    IconComponent={() => null}
                    value={newParam?.get('start_date') ? new Date(newParam.get('start_date')).toISOString().split('T')[0] : newParam.get('start_date')}
                    onChange={(e) => onChangeFilter('start_date', e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={1}>
                <FormControl fullWidth sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }} size='small' className='border-none'>
                  <TextField
                    type='date'
                    size='small'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    IconComponent={() => null}
                    value={newParam.get('end_date') ? new Date(newParam.get('end_date')).toISOString().split('T')[0] : newParam.get('end_date')}
                    disabled={!newParam.get('start_date')}
                    onChange={(e) => onChangeFilter('end_date', e.target.value)}
                    min={new Date(newParam.get('start_date')).toISOString().split('T')[0]}
                    max={new Date().toISOString().split('T')[0]}
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={1.0}>
                <FormControl fullWidth sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }} size='small' className='border-none'>
                  <Select
                    displayEmpty
                    InputLabelProps={{ shrink: false }}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return `Select type`
                      }
                      return selected
                    }}
                    IconComponent={() => null}
                    value={periodType}
                    onChange={(e) => setPeriodType(e.target.value)}
                  >
                    <MenuItem value='weekToDate'>Week to Date</MenuItem>
                    <MenuItem value='monthToDate'>Month to Date</MenuItem>
                    <MenuItem value='yearToDate' disabled={'selectedYear' === 'currentYear'}>
                      Year to Date
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={1.3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button className='rounded-3 fs-16' variant='outlined' onClick={() => { handleImportModal() }} style={{ backgroundColor: '#424242', color: 'white', textTransform: 'none', fontSize: 16 }}>
                  <FaFileImport className='me-2' size='1.0rem' />Import</Button>
              </Grid>
            </Grid>
          </Grid>
        </div>
        {reportLoadng ? (<div className='mt-5'><Loader /></div>) : (
          <>
            {graphDetails?.xLabels?.length > 0 ? (
              <>
                <section className='dashboard-graph mt-4 mb-2'>
                  <div >
                    <div className="row gy-3 h-100">
                      <div className="col-lg-6 px-xs-0">
                        <Grid item xs={12} lg={6} md={6} sm={6} >
                          <Grid container spacing={2} alignItems='center'>
                            {data.map((item, index) => (
                              <Grid item xs={12} sm={6} md={4} key={index}>
                                <AdvertisementDataBox {...item} />
                              </Grid>
                            ))}
                          </Grid>
                        </Grid>
                      </div>
                      <div className="col-lg-6 px-xs-0">
                        <div className="common-card rounded-4 h-100">
                          <div className="common-card-content border-0">
                            <Grid item xs={12} lg={6} md={6} sm={6} >
                              <AdvertisementGraph
                                xLabels={graphDetails.xLabels}
                                label={['Sales', 'Spend', 'ACOS']}
                                graphData={[graphDetails?.sales, graphDetails?.spendData, graphDetails?.acosData]}
                              />
                            </Grid>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section className='dashboard-graph mt-4 mb-2'>
                  <div>
                    <div className="row gy-3">
                      <div className="col-lg-6 px-xs-0">
                        <div className="common-card rounded-2 h-100">
                          <div className="common-card-content border-0 advertisement-graph-container">
                            <AdvertisementGraph
                              xLabels={graphDetails?.xLabels}
                              label={['Impressions', 'CTR']}
                              graphData={[graphDetails?.impressions, graphDetails?.ctr]}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 px-xs-0">
                        <div className="common-card rounded-3 h-100">
                          <div className="common-card-content border-0 advertisement-graph-container">
                            <AdvertisementGraph
                              xLabels={graphDetails?.xLabels}
                              label={['Conversions', 'CVR']}
                              graphData={[graphDetails?.conversions, graphDetails?.cvr]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <div className="row mt-4">
                  <div className="col-md-12 h-100">
                    <div className="common-card rounded-3">
                      <div className="common-card-title">
                        <div className='d-flex align-items-center justify-content-between'>
                          <h5 >Advertisement Listing</h5>
                        </div>
                      </div>
                      <div className="common-card-content border-0 mt-0 mb-0">
                        <AdvertisementTable
                          skuData={skuData}
                          skupage={skupage}
                          skurowsPerPage={skurowsPerPage}
                          handleSkuChangePage={handleSkuChangePage}
                          handleSkuChangeRowsPerPage={handleSkuChangeRowsPerPage}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (<div className='mt-5'>
              <HorizontalStatus
                imagesrc={require('assets/svgs/shared/empty.svg').default}
                mainmsg='No Advertisement Report Found'
                smallmsg='Try to change the filter to fetch the Advertisement report'
                isAbsolute={true}
              />
            </div>
            )}
          </>
        )
        }
        <ModalDialog open={importModalOpen} fullWidth maxWidth='md' isDivider='true' title={<><h4 className='fw-semibold'>Advertisement Report</h4></>} content={
          <>
            <Grid container spacing={2} alignItems='center' item lg={6} md={6} sm={6} xs={12} sx={{ marginTop: '10px', marginBottom: '20px' }} >
              <Grid item xs={12} sm={6} md={4} lg={1}>
                <FormControl fullWidth sx={{ backgroundColor: '#F9F9F9', minWidth: 150, borderColor: '#D3D3D3' }} size='small' className='border-none'>
                  <Select
                    value={filterShop}
                    displayEmpty
                    InputLabelProps={{ shrink: false }}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return `Select Shops`
                      }
                      return selected
                    }}
                    IconComponent={() => null}
                    onChange={(e) => { setFilterShop(e.target.value) }}
                  >
                    <MenuItem value=''>All Shop</MenuItem>
                    {Constants?.shopData?.map((item, index) => (
                      <MenuItem key={index} value={item}>{item}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={1}>
                <FormControl fullWidth sx={{ backgroundColor: '#F9F9F9', minWidth: 150, borderColor: '#D3D3D3' }} size='small' className='border-none'>
                  <Select
                    value={filterShipmentCountry}
                    onChange={(e) => { setFilterShipmentCountry(e.target.value) }}
                    displayEmpty
                    InputLabelProps={{ shrink: false }}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return `Select Shipment Country`
                      }
                      return selected
                    }}
                    IconComponent={() => null}
                  >
                    <MenuItem value=''>All Country</MenuItem>
                    {Array.isArray(shipment) && shipment?.length ? shipment.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    )) : ""}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <p>Choose a file to import:</p>
            <input type='file' accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={(e) => {
              const file = e.target.files[0]
              convertData(file)
            }}
            />
            <div style={{ marginTop: '20px' }}>
              <Button className='rounded-3 fs-16' variant='outlined' disabled={(!filterShipmentCountry || !filterShop)} onClick={submitData} style={{ minWidth: 100, backgroundColor: '#424242', color: 'white', textTransform: 'none', fontSize: 16 }}>
                {loading ? <CircularProgress size={20} style={{ marginLeft: '10px' }} /> : 'Submit'}
              </Button>

            </div>
          </>
        }
          handleClose={handleImportModal}
        />
      </div>
    </section>
  )
}

export default Advertisement
