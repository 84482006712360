import { Grid } from '@mui/material'
import moment from 'moment/moment'
import React from 'react'
import PropTypes from 'prop-types'
import { getDate } from 'shared/util/helper'

function PoHeader(props) {
  const purchaseOrderDetail = props.purchaseOrderDetail
  return (
    <>
      <div className='px-3 pb-4'>
        <Grid container direction='row' justifyContent='space-between' alignItems='center' className='mt-4 mb-3'>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <h5>Supplier</h5>
                  </Grid>
                  <Grid item xs={6}>
                    <p>{purchaseOrderDetail?.supplierName?.split(" ")[0]}</p>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <h5>Po Date</h5>
                  </Grid>
                  <Grid item xs={6}>
                    <p>{getDate(purchaseOrderDetail?.order_deadline || purchaseOrderDetail?.poDate)}</p>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <h5>Ship To</h5>
                  </Grid>
                  <Grid item xs={6}>
                    <p>{purchaseOrderDetail?.shipTo}</p>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <h5>Po Due Date</h5>
                  </Grid>
                  <Grid item xs={6}>
                    <p>
                      {purchaseOrderDetail?.order_deadline
                        ? moment(purchaseOrderDetail?.order_deadline).add(1, 'months').format('DD/MM/YYYY')
                        : moment(purchaseOrderDetail?.poDate).add(1, 'months').format('DD/MM/YYYY')}
                    </p>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container >
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <h5>Po Number</h5>
                  </Grid>
                  <Grid item xs={6}>
                  <p>{purchaseOrderDetail?.poNumber}</p>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <h5>Load Date</h5>
                  </Grid>
                  <Grid item xs={6}>
                    <p>Yet to be Confirmed</p>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <h5>PO Status</h5>
                  </Grid>
                  <Grid item xs={6}>
                  <p>{purchaseOrderDetail?.status}</p>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <h5>Ship Via</h5>
                  </Grid>
                  <Grid item xs={6}>
                    <p>{purchaseOrderDetail?.shipVia ? purchaseOrderDetail?.shipVia :"N/A"}</p>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default PoHeader
PoHeader.propTypes = {
  purchaseOrderDetail: PropTypes.object,
}
