import { Grid } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'
import SourceIcon from '@mui/icons-material/Source'
import { FaCalendar } from "react-icons/fa"
import { FaUserTie } from "react-icons/fa"
import { FaTruck } from "react-icons/fa"
import { FaAddressCard } from "react-icons/fa"
import { CContainer } from '@coreui/react'

function OrderHeader(props) {
    const purchaseOrderDetail = props?.purchaseOrderDetail
    const { mobile, ...shippingAddress } = purchaseOrderDetail?.shipping_address

    // function joinObjectKeyValuePairsToArray(obj) {
    //     return Object.entries(obj)
    //         .map(([key, value]) => `${key}: ${value}`)
    // }

    
    // const billingAddressArray = joinObjectKeyValuePairsToArray(purchaseOrderDetail.billing_address)
    // const shippingAddressArray = joinObjectKeyValuePairsToArray(purchaseOrderDetail.shipping_address)

    return (
        <>
        
            <div className='order_three'>
                <span className='order_four'>Order number: </span>
                <span className='order_five'>{purchaseOrderDetail?.order_id}</span>
            </div>
            <CContainer lg>
                <div className='px-3 pb-4 order_six'>
                    <Grid container direction='row' justifyContent='space-between' alignItems='center' className='mt-4 mb-3'>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Grid container spacing={2} className='order_seven'>
                                        <Grid item xs={2}>
                                            <i><SourceIcon style={{ fontSize: "40px" }} /></i>
                                        </Grid>
                                        <Grid item xs={10}>
                                            <h5 className='order_eight'>Source:</h5>
                                            <p>{purchaseOrderDetail?.source}</p>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid container spacing={2} className='order_seven'>
                                        <Grid item xs={2}>
                                            <i><FaCalendar size={"2rem"} /></i>
                                        </Grid>
                                        <Grid item xs={10}>
                                            <h5 className='order_eight'>Creation date:</h5>
                                            <p>{purchaseOrderDetail?.order_date}</p>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid container spacing={2} className='order_seven'>
                                        <Grid item xs={2}>
                                            <i><FaTruck size={"2rem"} /></i>
                                        </Grid>
                                        <Grid item xs={10}>
                                            <h5 className='order_eight'>State:</h5>
                                            <p style={{ textTransform: "capitalize" }}>{purchaseOrderDetail?.state}</p>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4} style={{ paddingTop: "100px" }}>
                                    <Grid container spacing={2} className='order_seven'>
                                        <Grid item xs={2}>
                                            <i><FaUserTie size={"2rem"} /></i>
                                        </Grid>
                                        <Grid item xs={10}>
                                            <h5 className='order_eight'>Customer:</h5>
                                            <p className='order_nine'>{purchaseOrderDetail?.shipping_address?.name}</p>
                                            {mobile}<br />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* <Grid item xs={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <h5>Billing Address</h5>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <p>{billingAddressArray.join(', ')}</p>
                                    </Grid>
                                </Grid>
                            </Grid> */}
                                <Grid item xs={4} style={{ paddingTop: "100px" }}>
                                    <Grid container spacing={2} className='order_seven'>
                                        <Grid item xs={2}>
                                            <i>< FaAddressCard size={"2rem"} /></i>
                                        </Grid>
                                        <Grid item xs={10}>
                                            <h5 className='order_eight'>Shipping address:</h5>
                                            {Object.values(shippingAddress).join(', ')}
                                        </Grid>
                                    </Grid>
                                </Grid>


                                {/* <Grid item xs={4}>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <h5 style={{ marginBottom: "0px", fontSize: "16px" }}>Tracking Details</h5>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <ul>
                                            {purchaseOrderDetail.tracking_details.map((tracking, index) => (
                                                <li key={index}>
                                                    <p>
                                                        <strong>Tracking Code:</strong> {tracking.tracking_code}<br />
                                                        <strong>Carrier:</strong> {tracking.carrier}<br />
                                                        <strong>Shipped Date:</strong> {tracking.shipped_date}<br />
                                                        <strong>Tracking URL:</strong> <a href={tracking.tracking_url} target="_blank" rel="noopener noreferrer">Track</a>
                                                    </p>
                                                </li>
                                            ))}
                                        </ul>
                                    </Grid>
                                </Grid>
                            </Grid> */}

                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </CContainer>

            <hr className="order_ten" />

            <div className='px-3 pb-4'>
                <h5 style={{ paddingBottom: "20px" }}>Products</h5>
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                        <tr className='order_eleven'>
                            <th className='order_twelve'>SKU</th>
                            <th className='order_twelve'>Quantity</th>
                            <th className='order_twelve'>Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {purchaseOrderDetail.order_lines.map((line, index) => (
                            <tr key={index} style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
                                <td className='order_thirteen'>{line?.sku}</td>
                                <td className='order_thirteen'>{line?.qty}</td>
                                <td className='order_thirteen'>{line?.price}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <hr className='order_ten' />


            <div className='px-3 pb-4'>
                {purchaseOrderDetail?.fulfillments && purchaseOrderDetail?.fulfillments?.length ? (
                    <>
                        <h5 style={{ paddingBottom: '20px' }}>Fulfillment #1</h5>
                        <p className='order_fifteen mb-3'>Fulfillment info:</p>
                        <p style={{ marginBottom: "45px", fontSize:'1rem' }}>State&nbsp;-&nbsp;
                            <span className='order_fourteen'>{purchaseOrderDetail?.state} </span>
                        </p>
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead>
                                <tr className='order_eleven'>
                                    <th className='order_twelve'>SKU</th>
                                    <th className='order_twelve'>Quantity</th>
                                    <th className='order_twelve'>Tracking Code</th>
                                    <th className='order_twelve'>Tracking Status</th>
                                    <th className='order_twelve'>Carrier Name</th>
                                    <th className='order_twelve'>Carrier Code</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.isArray(purchaseOrderDetail?.fulfillments) && purchaseOrderDetail?.fulfillments?.length ? purchaseOrderDetail?.fulfillments.map((fulfillment, index) => (
                                    <tr key={index} style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
                                        {/* Table data */}
                                        <td className='order_thirteen'>{fulfillment.sku}</td>
                                        <td className='order_thirteen'>{fulfillment.qty}</td>
                                        <td className='order_thirteen'>
                                            {/* Tracking Code with dynamic URL */}
                                            <button className='tracking_code'>
                                                <a href={`https://apc-overnight.com/TrackConsignment.php?parcel_no=${fulfillment.tracking_code}`} target="_blank" rel="noopener noreferrer" style={{ color: '#000', textDecoration: 'none' }}>
                                                    {fulfillment.tracking_code}
                                                </a>
                                            </button>
                                        </td>

                                        <td className='order_thirteen'>{fulfillment.tracking_status}</td>
                                        <td className='order_thirteen'>{fulfillment.carrier_name}</td>
                                        <td className='order_thirteen'>{fulfillment.carrier_code}</td>
                                    </tr>
                                )) : ""}
                            </tbody>
                        </table>
                    </>
                ):""}
            </div>
        </>
    )
}

OrderHeader.propTypes = {
    purchaseOrderDetail: PropTypes.object
}

export default OrderHeader
