import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import purchaseOrderService from 'shared/services/purchase-order-service'

// Thunks for asynchronous actions
export const fetchShipment = createAsyncThunk('advertisement/fetchShipment', async () => {
  const response = await purchaseOrderService.getShipmentData()
  return response.data
})


export const fetchShop = createAsyncThunk('advertisement/fetchShop', async () => {
  const response = await purchaseOrderService.getShopData()
  return response.data
})

export const fetchAdvertisementReport = createAsyncThunk('advertisement/fetchAdvertisementReport', async (params) => {
  const response = await purchaseOrderService.getAdvertisementReport(params)
  return response.data
})

export const fetchTotalRevenue = createAsyncThunk('advertisement/fetchTotalRevenue', async (params) => {
  const response = await purchaseOrderService.getTotalRevenue(params)
  return response.data
})

const advertisementSlice = createSlice({
  name: 'advertisement',
  initialState: {
    shipment: [],
    shop: [],
    selectedShop: '',
    selectedShipmentCountry: '',
    adGroups: [],
    selectedAdGroup: '',
    productSku: '',
    advertisementReport: null,
    totalRevenue: null,
    graphDetails: { acosData: 0, spendData: 0, xLabels: [], sales: [] },
    reportLoading: false,
    skuData: [],
    skuPage: 0,
    skuRowsPerPage: 10,
    importModalOpen: false,
    advertisementData: null,
    loading: false,
    error: null,
  },
  reducers: {
    setSelectedShop: (state, action) => { state.selectedShop = action.payload },
    setSelectedShipmentCountry: (state, action) => { state.selectedShipmentCountry = action.payload },
    setSelectedAdGroup: (state, action) => { state.selectedAdGroup = action.payload },
    setProductSku: (state, action) => { state.productSku = action.payload },
    setSkuPage: (state, action) => { state.skuPage = action.payload },
    setSkuRowsPerPage: (state, action) => { state.skuRowsPerPage = action.payload },
    setImportModalOpen: (state, action) => { state.importModalOpen = action.payload },
    setAdvertisementData: (state, action) => { state.advertisementData = action.payload },
    setLoading: (state, action) => { state.loading = action.payload },
    setError: (state, action) => { state.error = action.payload },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchShipment.fulfilled, (state, action) => { state.shipment = action.payload })
      .addCase(fetchShop.fulfilled, (state, action) => { state.shop = action.payload.map((item) => item.shop) })
      .addCase(fetchAdvertisementReport.pending, (state) => { state.reportLoading = true })
      .addCase(fetchAdvertisementReport.fulfilled, (state, action) => {
        // Processing response data and setting state
        const { data } = action.payload
        state.advertisementReport = data?.result[0]
        state.graphDetails = {
          acosData: data?.graphData?.map((data) => data?.totalSpend / data?.totalRevenue),
          spendData: data?.graphData?.map((data) => data?.totalSpend),
          xLabels: ['JAN 2024', 'FEB 2024', 'MAR 2024', 'APR 2024', 'MAY 2024', 'JUN 2024'].slice(0, data?.graphData?.length),
          sales: data?.salesGraphData?.map((data) => data?.untaxedTotal),
          impressions: data?.graphData?.map((data) => data?.totalImpressions),
          conversions: data?.graphData?.map((data) => data?.totalConversions),
          ctr: data?.graphData?.map((data) => data?.totalClicks / data?.totalImpressions),
          cvr: data?.graphData?.map((data) => data?.totalConversions / data?.totalClicks),
        }
        state.skuData = data?.skuData
        state.reportLoading = false
      })
      .addCase(fetchAdvertisementReport.rejected, (state, action) => { state.error = action.error.message })
      .addCase(fetchTotalRevenue.fulfilled, (state, action) => { state.totalRevenue = action.payload?.result[0] })
      .addCase(fetchTotalRevenue.rejected, (state, action) => { state.error = action.error.message })
  },
})

export const {
  setSelectedShop,
  setSelectedShipmentCountry,
  setSelectedAdGroup,
  setProductSku,
  setSkuPage,
  setSkuRowsPerPage,
  setImportModalOpen,
  setAdvertisementData,
  setLoading,
  setError,
} = advertisementSlice.actions

export default advertisementSlice.reducer
