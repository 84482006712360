import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle
} from '@coreui/react'
import { AiOutlineUser, AiOutlineLogout } from 'react-icons/ai'
// import { IoSettingsOutline } from 'react-icons/io5'
import PropTypes from 'prop-types'

import Avatar from 'components/uiElements/Avatar'
import { UserContext } from 'context/AuthContext/UserContext'

const AppHeaderDropdown = (props) => {
  const contextObj = React.useContext(UserContext)

  const navigate = useNavigate()
  const handleLogoutClick = (e) => {
    e.preventDefault()
    props.signOut()
    return navigate('/login')
  }
  return (
    <>
      <CDropdown variant='nav-item'>
        <CDropdownToggle placement='bottom-end' className='py-0' caret={false}>
          <Avatar
            name={contextObj?.userData?.user?.fName + ' ' + contextObj?.userData?.user?.lName}
            src={contextObj?.userData?.user?.image}
            width={40}
            height={40}
          />
        </CDropdownToggle>
        <CDropdownMenu className='pt-0' placement='bottom-end'>
          <CDropdownHeader className='bg-light fw-semibold py-2'>Settings</CDropdownHeader>
          <Link style={{textDecoration:'none'}} to={`/account/profile`}>
            <CDropdownItem >
              <AiOutlineUser className='me-2' />
              Profile
            </CDropdownItem>
          </Link>
          {/* <CDropdownItem href='/notifications'>
            <IoSettingsOutline className='me-2' />
            Notifications
          </CDropdownItem> */}
          <CDropdownDivider />
          <CDropdownItem href='/' onClick={(e) => handleLogoutClick(e)}>
            <AiOutlineLogout className='me-2' />
            Logout
          </CDropdownItem>
        </CDropdownMenu>
      </CDropdown>
    </>
  )
}

AppHeaderDropdown.propTypes = {
  signOut: PropTypes.func
}

export default AppHeaderDropdown
