import React, { useReducer, useEffect, useState } from 'react'
import { CFormInput, CFormLabel, CFormFeedback, CFormSelect, CInputGroup, CInputGroupText } from '@coreui/react'
import { Grid, IconButton, Tooltip } from '@mui/material'
import { AiFillSave, AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { FiEdit } from 'react-icons/fi'
import { BsXLg } from 'react-icons/bs'
import PropTypes from 'prop-types'

import { validate } from 'shared/util/validators'

import './Input.css'
import { isNotUndefinedAndNull } from 'shared/util/helper'

const inputReducer = (state, action) => {
  switch (action.type) {
    case 'CHANGE': {
      return {
        ...state,
        value: action.val,
        isValid: validate(action.val, action.validators)
      }
    }
    case 'TOUCH': {
      return {
        ...state,
        isTouched: true
      }
    }
    default:
      return state
  }
}

const Input = (props) => {
  const { id, onInput, validators, value: initialValue } = props

  const [inputState, dispatch] = useReducer(inputReducer, {
    value: initialValue,
    isTouched: false
  })

  
  const { value, isValid } = inputState

  useEffect(() => {
    dispatch({
      type: 'CHANGE',
      val: initialValue || '',
      validators: validators
    })
  }, [initialValue])

  useEffect(() => {
    onInput(id, value, isValid)
  }, [id, onInput, value, isValid])
  
  const changeHandler = (event) => {
    dispatch({
      type: 'CHANGE',
      val: event?.target?.value,
      validators: validators
    })
  }

  const touchHandler = () => {
    dispatch({
      type: 'TOUCH'
    })
  }

  const [values, setValues] = useState({
    password: false,
    newPassword: false,
    rePassword: false
  })
  const handleClickShowPassword = (type) => {
    setValues(prevValues => ({
      ...prevValues,
      [type]: !prevValues[type]
    }))
  }
  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const element =
    props.element === 'input' ? <>
      {!props.isPassowordEye ? <CFormInput
        id={props.id}
        type={props.type}
        data-testid={props.datatestid}
        placeholder={props.placeholder}
        onChange={changeHandler}
        onBlur={touchHandler}
        value={inputState.value}
        disabled={props.disabled}
        invalid={(!inputState.isValid && inputState.isTouched) || (!inputState.isValid && props.checkValidations)}
        readOnly={!props.isUpdate}
        plainText={!props.isUpdate}
      /> : ""}
      {props.isPassowordEye && props.element === 'input' ? (<CInputGroup className='mb-2 '>
        <CFormInput
          id={props.id}
          type={values[props?.id] ? 'text' : 'password'}
          data-testid={props.datatestid}
          placeholder={props.placeholder}
          onChange={changeHandler}
          onBlur={touchHandler}
          value={inputState.value}
          invalid={(!inputState.isValid && inputState.isTouched) || (!inputState.isValid && props.checkValidations)}
          readOnly={!props.isUpdate}
          plainText={!props.isUpdate}
        />
        <CInputGroupText
          className='password-toggle'
          data-testid='toggle-password'
          onClick={(e) => {
            e.preventDefault()
            handleClickShowPassword(props?.id)
          }}
          onMouseDown={handleMouseDownPassword}
        >
          {values.password || values.newPassword || values.rePassword ? <AiOutlineEyeInvisible size='1.2em' /> : <AiOutlineEye size='1.2em' />}
        </CInputGroupText>
        <CFormFeedback invalid>{props?.errorText}</CFormFeedback>
      </CInputGroup>) : ""}
    </>
      : props.element === 'select'
        ? (
          <CFormSelect
            aria-label={props.placeholder}
            onChange={changeHandler}
            placeholder={props.placeholder}
            invalid={(!inputState?.isValid && inputState?.isTouched) || (!inputState?.isValid && props?.checkValidations)}
          >
            {props.select && (
              <option value={props.select} disabled selected>
                {props.select}
              </option>
            )}
            {props.options.map((option, index) => (
              <option key={index} value={option.value} selected={props.value === option.value}>
                {option.label}
              </option>
            ))}
          </CFormSelect>
        )
        : (
          <textarea
            id={props.id}
            rows={props.rows || 3}
            onChange={changeHandler}
            onBlur={touchHandler}
            value={inputState.value}
          />
        )

  return (
    <Grid container direction='row' justifyContent='flex-start' alignItems='center' className='mb-2'>
      {props.label && (
        <Grid item lg={5} md={5} sm={6} xs={12}>
          <CFormLabel htmlFor={props.id}>
            {props.label}
            <sup>
              {props.helper && <>{props.helper}</>}
              {isNotUndefinedAndNull(props.setisUpdate) && (
                <>
                  {props.isUpdate
                    ? (
                      <Tooltip title='Save Changes'>
                        <IconButton
                          data-testid='save-button'
                          color='primary'
                          aria-label={props.updateLabel}
                          component='span'
                          onClick={props.save}
                        >
                          <AiFillSave size='0.8em' />
                        </IconButton>
                      </Tooltip>
                    )
                    : (
                      <Tooltip title={props.updateLabel}>
                        <IconButton
                          data-testid='update-name'
                          color='primary'
                          aria-label={props.updateLabel}
                          onClick={() => props.setisUpdate(true)}
                        >
                          <FiEdit size='0.6em' />
                        </IconButton>
                      </Tooltip>
                    )}
                </>
              )}
            </sup>
          </CFormLabel>
        </Grid>
      )}
      <Grid
        item
        lg={props.label ? (props.setisUpdate !== undefined ? 6 : 7) : 12}
        md={props.label ? (props.setisUpdate !== undefined ? 6 : 7) : 12}
        sm={props.label ? (props.setisUpdate !== undefined ? 5 : 6) : 12}
        xs={props.label ? (props.setisUpdate !== undefined ? 11 : 12) : 12}
      >
        {element}
        {props.isUpdate && <CFormFeedback invalid>{props.errorText}</CFormFeedback>}
      </Grid>
      {props.setisUpdate !== undefined && props.isUpdate && (
        <Grid item lg={1} md={1}>
          <IconButton
            color='error'
            aria-label={props.updateLabel}
            component='span'
            onClick={() => {
              props.setisUpdate(false)
            }}
          >
            <BsXLg size='0.6em' />
          </IconButton>
        </Grid>
      )}
    </Grid>
  )
}

Input.propTypes = {
  validators: PropTypes.array,
  element: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  errorText: PropTypes.string,
  label: PropTypes.string,
  onInput: PropTypes.func,
  value: PropTypes.any,
  isUpdate: PropTypes.bool,
  setisUpdate: PropTypes.func,
  showPreview: PropTypes.bool,
  isMultiple: PropTypes.bool,
  options: PropTypes.array,
  checkValidations: PropTypes.bool,
  select: PropTypes.string,
  directory: PropTypes.string,
  subdirectory: PropTypes.string,
  setImage: PropTypes.func,
  resetImages: PropTypes.func,
  allowMultiple: PropTypes.bool,
  maxFiles: PropTypes.number,
  disabled: PropTypes.bool,
  updateLabel: PropTypes.string,
  save: PropTypes.func,
  datatestid: PropTypes.string,
  helper: PropTypes.object,
  isPassowordEye: PropTypes.bool
}

export default Input
