import React from 'react'
import { Grid } from '@mui/material'
import PropTypes from 'prop-types'
import './HorizontalStatus.css'

const HorizontalStatus = (props) => {
  return (
    <Grid container direction='row' justifyContent='center' alignItems='center' mb={4} mt={5} className={props.isAbsolute ? 'status-centered-absolute px-5' : 'status-centered'}>
      <Grid item>
        <div className='small-status-div'>
          <img src={props.imagesrc} alt='small-status' height='100rem' />
        </div>
      </Grid>
      <Grid item>
        <Grid container direction='column' justifyContent='center' alignItems='center'>
          <Grid item>
            <span className='small-status-main-text'>{props.mainmsg}</span>
          </Grid>
          <Grid item>
            <small className='small-status-small-text'>{props.smallmsg}</small>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

HorizontalStatus.propTypes = {
  imagesrc: PropTypes.string,
  mainmsg: PropTypes.string,
  smallmsg: PropTypes.string,
  isAbsolute: PropTypes.string
}

export default HorizontalStatus
