import React from 'react'
import { CToast, CToastBody, CToastClose } from '@coreui/react'
import PropTypes from 'prop-types'
import { TiTick } from 'react-icons/ti'
// import { ImCross } from 'react-icons/im'

const ToastMessage = (props) => {
  return (
    <>
      <CToast
        autohide
        visible
        color={props.color || 'primary'}
        className='text-white align-items-center'
      >
        <div className='d-flex'>
          <CToastBody>
            {props.color === 'success'
              ? (
                <>
                  <TiTick size='1.8em' /> {props.message}
                </>
                )
              : props.color === 'warning'
                ? (
                  <>
                     {props.message}
                  </>
                  )
                : (
                    props.message
                  )}
          </CToastBody>
          <CToastClose className='me-2 m-auto' white />
        </div>
      </CToast>
    </>
  )
}

ToastMessage.propTypes = {
  sendToast: PropTypes.bool,
  message: PropTypes.string,
  color: PropTypes.string
}

export default ToastMessage
