import React, { useState } from 'react'
import PropTypes from 'prop-types'

const Magnifier = ({
  src,
  width,
  height,
  magnifierHeight = 150,
  magnifieWidth = 150,
  zoomLevel = 2.0
}) => {
  const [showMagnifier, setShowMagnifier] = useState(false)
  const [[x, y], setXY] = useState([0, 0])
  const [[imgWidth, imgHeight], setSize] = useState([0, 0])

  return (
    // the container
    <div
      style={{
        position: 'relative',
        height,
        width
      }}
    >
      {/* the image */}
      <img
        src={src}
        style={{
          height,
          width
        }}
        onMouseEnter={(e) => {
          // update image size and turn-on magnifier
          const elem = e.currentTarget
          const { width, height } = elem.getBoundingClientRect()
          setSize([width, height])
          setShowMagnifier(true)
        }}
        onMouseLeave={() => {
          setShowMagnifier(false)
        }}
        onMouseMove={(e) => {
          // update cursor position
          const elem = e.currentTarget
          const { top, left } = elem.getBoundingClientRect()

          // calculate cursor position on the image
          const x = e.pageX - left - window.pageXOffset
          const y = e.pageY - top - window.pageYOffset
          setXY([x, y])
        }}
        alt='img'
      />
      {/* a div for the magnifier */}
      <div
        style={{
          display: showMagnifier ? 'block' : 'none',
          position: 'absolute',
          zIndex: 900,
          // prevent magnifier blocks the mousemove event of img
          pointerEvents: 'none',
          overflow: 'visible',
          // set size of magnifier
          height: `${magnifierHeight}px`,
          width: `${magnifieWidth}px`,
          // move element center to cursor pos
          top: `${y - magnifierHeight / 2}px`,
          left: `${x - magnifieWidth / 2}px`,
          opacity: '1', // reduce opacity so you can verify position
          border: '1px solid lightgray',
          borderRadius: '20%',

          backgroundColor: 'white',
          backgroundImage: `url('${src}')`,
          backgroundRepeat: 'no-repeat',

          // calculate zoomed image size
          backgroundSize: `${imgWidth * zoomLevel}px ${imgHeight * zoomLevel}px`,

          // calculate position of zoomed image.
          backgroundPositionX: `${-x * zoomLevel + magnifieWidth / 2}px`,
          backgroundPositionY: `${-y * zoomLevel + magnifierHeight / 2}px`
        }}
      />
    </div>
  )
}

Magnifier.propTypes = {
  magnifieWidth: PropTypes.number,
  magnifierHeight: PropTypes.number,
  zoomLevel: PropTypes.number,
  src: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

export default Magnifier
