import { useMutation } from 'react-query'
import purchaseOrderService from 'shared/services/purchase-order-service'
import { useState } from 'react'

export const useRefundHooks = () => {
  const [loading, setLoading] = useState(false)
  const [refundDetails, setRefundDetails] = useState({
    marketplace_name: '',
    date_of_refund: '',
    order_date: '',
    order_id: '',
    return_fee: '',
    country_of_dispatch: '',
    shipped_country: '',
    transaction_type: '',
    status: ''
  })

  const handleRefundSubmitMutation = useMutation(
    async (payload) => {
      return await purchaseOrderService.addRefund(payload)
    },
    {
      onSuccess: () => {
        setLoading(false)
        setRefundDetails({
          marketplace_name: '',
          date_of_refund: '',
          order_date: '',
          order_id: '',
          return_fee: '',
          country_of_dispatch: '',
          shipped_country: '',
          transaction_type: '',
          status: ''
        })
      },
      onError: (error) => {
        console.error('Error adding project:', error)
        setLoading(false)
      }
    }
  )

  const handleChange = (name, value) => {

    setRefundDetails({ ...refundDetails, [name]: value })
  }
  const submitRefundData = async (event) => {

    event.preventDefault()
    if (validateForm()) {
      setLoading(true)
      await handleRefundSubmitMutation.mutateAsync(refundDetails)
    }
  }

  const [errors, setErrors] = useState({})

  const handleInputChange = (field, value) => {
    setRefundDetails({ ...refundDetails, [field]: value })
    if (value) {
      setErrors({ ...errors, [field]: '' })
    }
  }

  const validateForm = () => {
    let valid = true
    let errors = {}

    if (!refundDetails.marketplace_name) {
      errors.marketplace_name = 'Market Place Name is required'
      valid = false
    }
    if (!refundDetails.order_id) {
      errors.order_id = 'Order Number is required'
      valid = false
    }
    if (!refundDetails.shipped_country) {
      errors.shipped_country = 'Shipped Country is required'
      valid = false
    }
    if (!refundDetails.order_date) {
      errors.order_date = 'Order Date is required'
      valid = false
    }
    if (!refundDetails.date_of_refund) {
      errors.date_of_refund = 'Date Of Refund is required'
      valid = false
    }
    if (!refundDetails.return_fee) {
      errors.return_fee = 'Refund Amount is required'
      valid = false
    }
    if (!refundDetails.country_of_dispatch) {
      errors.country_of_dispatch = 'Dispatched Country is required'
      valid = false
    }
    if (!refundDetails.transaction_type) {
      errors.transaction_type = 'Transaction Type is required'
      valid = false
    }
    if (!refundDetails.status) {
      errors.status = 'Status is required'
      valid = false
    }

    setErrors(errors)
    return valid
  }

  return {
    refundDetails,
    submitRefundData,
    handleChange,
    loading,
    errors,
    handleInputChange
  }
}
