import React, { useEffect, useState } from 'react'
import Badge from '@mui/material/Badge'
import { styled } from '@mui/material/styles'
import { Link } from 'react-router-dom'
import { CContainer, CHeader, CHeaderNav, CNavItem } from '@coreui/react'
import { BsEnvelope } from 'react-icons/bs'
import PropTypes from 'prop-types'
import { AppHeaderDropdown } from './header/index'
import { pricingPlan, UserRoles } from 'data/Enums'
import { isAuthorized } from 'shared/util/helper'
import { UserContext } from 'context/AuthContext/UserContext'
import catalogueServices from 'shared/services/catalogues.service'
import { useLocation } from 'react-router-dom'
import { FaShoppingCart } from 'react-icons/fa'
import HomeIcon from '@mui/icons-material/Home'
import { BiSearch } from 'react-icons/bi'
import { VscTasklist } from 'react-icons/vsc'
import purchaseOrderService from 'shared/services/purchase-order-service'

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    left: 2,
    top: -12,
    border: `2px solid ${theme.palette.background.paper}`
  }
}))

const AppHeader = (props) => {
  const [messagesCount, setMessageCount] = useState(0)
  const [unreadCount, setUnreadCount] = useState(0)
  const [unreadChats, setUnreadChats] = useState()
  const contextObj = React.useContext(UserContext)
  const location = useLocation()
  const path = location.pathname
  const pathName = location.pathname.split("/")

  const getUserQueries = () => {
    catalogueServices.getUnreadUserQueresCount(contextObj.userData.user.id).then(
      (response) => {
        setMessageCount(response.data.messages)
      },
      () => { }
    )
  }
  // const getCompanyQueries = () => {
  //   catalogueServices.getUnreadCompanyQueresCount(contextObj.userData.user.companyId, contextObj.userData.user.id).then(
  //     (response) => {
  //       setMessageCount(response.data.messages)
  //     },
  //     () => { }
  //   )
  // }

  const getAllUnreadChats = () => {
    purchaseOrderService.getAllUnreadChats(contextObj.userData.user.id).then(
      (response) => {
        setUnreadChats(response.data)
        setUnreadCount(response.data.overallTotalUnreadCount)
      }
    )
  }

  useEffect(() => {
    if (isAuthorized(contextObj.userData.user.pricingPlan, pricingPlan.Standard)) {
      if (UserRoles.SuperAdmin === contextObj.userData.user.role) {
        // getCompanyQueries()
      } else {
        getUserQueries()
      }
    }
  }, [contextObj.userData])

  useEffect(() => {
    getAllUnreadChats()
  }, [contextObj.userData, location.pathname])


  return (
    <>
      <CHeader position='fixed' className={`${path == '/dashboard' ? 'dashboard-page' : path == '/' ? "dashboard-page" : path == '/products' ? "border-none" : pathName[3] == 'product-view' ? "border-none view-product-padding" : ""}`}>
        <CContainer fluid className={`${path == '/dashboard' ? 'justify-content-between' : path == '/' ? "justify-content-between" : path == '/products' ? "justify-content-between" : pathName[3] == 'product-view' ? "justify-content-between" : "justify-content-end"}`} >
          {path === '/dashboard' || path === '/' ? (
            <>
              <span className='d-flex align-items-center'>
                <HomeIcon id="dash-icon" />
                <span id="dashboard-text">Dashboard</span>
              </span>
              <span className='d-flex align-items-center' id="search-input">
                <BiSearch />
                <input type='text' placeholder='Search' className='form-control border-0' />
              </span>
            </>
          ) : ''}
          {path === '/products' ? (
            <>
              <span className='d-flex align-items-center'>
                <FaShoppingCart id='pro-icon' size={25} />
                <span id="product-text" >Products</span>
              </span>
            </>
          ) : ''}
          {pathName[3] === 'product-view' ? (
            <>
              <button className='product-view-btn d-flex align-items-center'><VscTasklist size="1.5rem" />&nbsp;&nbsp; View Product Details</button>
            </>
          ) : ''}
          <div className='d-flex align-items-center justify-content-end'>
            <CHeaderNav className='d-none d-md-flex'>
              <div className='d-flex align-items-center'>
                <CHeaderNav className='d-none d-md-flex'>
                  {isAuthorized(contextObj?.userData?.user?.pricingPlan, pricingPlan?.Standard) && (
                    contextObj?.userData?.user?.pomUserType === 3 || location?.pathname === "/orders" ? ('') : (
                      <CNavItem>
                        <StyledBadge
                          // badgeContent={messagesCount}
                          badgeContent={unreadCount}
                          color='success'
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                          }}
                          invisible={unreadChats && unreadChats.chats.some(chat => chat.lastMessage.userId === contextObj.userData?.user?.id)}
                        >
                          <Link to='/messenger'>
                            <Badge badgeContent={messagesCount} color="primary">
                              <BsEnvelope className='notifications-icon' />
                            </Badge>
                          </Link>
                        </StyledBadge>
                      </CNavItem>)
                  )}
                </CHeaderNav>
                <CHeaderNav className='ms-3'>
                  <AppHeaderDropdown signOut={props.logOut} />
                </CHeaderNav>
              </div>
            </CHeaderNav>
          </div>

        </CContainer>
      </CHeader>
    </>
  )
}

AppHeader.propTypes = {
  logOut: PropTypes.func,
  currentUser: PropTypes.object,
  show: PropTypes.bool,
  handleToggle: PropTypes.func
}

export default AppHeader
