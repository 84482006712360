import React from 'react'
import PropTypes from 'prop-types'

const CommonLoader = (props) => {
    const {title} = props
    return (
        <div className='loading-container'>
            <div>{title}</div>
            <div className='dot-bounce'></div>
            <div className='dot-bounce'></div>
            <div className='dot-bounce'></div>
        </div>
    )
}

CommonLoader.propTypes = {
    title: PropTypes.string.isRequired,
}
export default CommonLoader
