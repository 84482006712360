import React from 'react'
import PropTypes from 'prop-types'
import { FaPaperPlane } from 'react-icons/fa'

// class SendIcon extends Component {
const SendIcon = (props) => {
  // render () {
  return (
    <button
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      onClick={(e) => {
        e.preventDefault()
        props.onClick(e)
      }}
      className='sc-user-input--send-icon-wrapper'
    >
      <FaPaperPlane className='send-icon' size='1.5em' />
    </button>
  )
  // }
}

SendIcon.propTypes = {
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onClick: PropTypes.func
}

export default SendIcon
