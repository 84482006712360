import React from 'react'
import { IconButton } from '@mui/material'
import { AiOutlineLeft } from 'react-icons/ai'
import PropTypes from 'prop-types'
import Avatar from 'components/uiElements/Avatar'
import './Header.css'
import moment from 'moment'

const Header = (props) => {
  return (
    <div
      data-testid='bodyHeader'
      className='card-header d-flex  p-3 text-white border-bottom-0'
      style={{ backgroundColor: '#6495ED' }}
    >
      <IconButton onClick={props.clearChat}>
        <AiOutlineLeft color='#fff' />
      </IconButton>
      <div className='d-flex flex-row'>
        <div>
          <Avatar
            name={props.name}
            className='d-flex align-self-center me-3'
            width={60}
            height={60}
          />
        </div>
        <div className='pt-1'>
          <p className='fw-bold mb-0 header-user-name'>{props.name}</p>
          <p className='small header-time-ago'>
            {moment(props.lastActive).fromNow()}
          </p>
        </div>
      </div>
    </div>
  )
}

Header.propTypes = {
  clearChat: PropTypes.func,
  message: PropTypes.object,
  name: PropTypes.string,
  lastActive: PropTypes.string
}

export default Header
