import React from 'react'
import { Grid } from '@mui/material'
import PropTypes from 'prop-types'
import { LineChart } from '@mui/x-charts'
import { currencyFormatter, percentFormatter } from 'shared/util/helper'


const AdvertisementGraph = (props) => {
  const { graphData, label, xLabels } = props
  const graphDetails = () => {
    let graphArr = [
      { data: graphData[0], label: label[0], yAxisKey: 'leftAxisId' },
      { data: graphData[1], label: label[1], yAxisKey: graphData?.length > 2 ? 'leftAxisId' : 'rightAxisId' }
    ]
    if (graphData?.length > 2) {
      graphArr.push({ data: graphData[2], label: label[2], yAxisKey: 'rightAxisId' })
    }
    return graphArr
  }
  return (
    <Grid container className="advertisement-graph" >
      <LineChart
        width={600}
        height={300}
        series={graphDetails()}
        xAxis={[{ scaleType: 'point', data: xLabels }]}
        yAxis={[{ id: 'leftAxisId', valueFormatter: currencyFormatter }, { id: 'rightAxisId', valueFormatter: percentFormatter }]}
        rightAxis='rightAxisId'
      />
    </Grid>
  )
}
AdvertisementGraph.propTypes = {
  graphData: PropTypes.array.isRequired,
  label: PropTypes.array.isRequired,
  xLabels: PropTypes.object.isRequired
}

export default AdvertisementGraph
