import React, { useEffect, useRef, useState } from 'react'
import {
    Grid,
    TableRow,
    TableCell,
    Table,
    TableHead,
    TableBody,
    TableContainer,
    Tooltip,
    CircularProgress
} from '@mui/material'
import PropTypes from 'prop-types'
import purchaseOrderService from 'shared/services/purchase-order-service'
import { useMutation } from 'react-query'
import { FaArrowDownLong, FaArrowUpLong } from 'react-icons/fa6'

const SalesDashboardTable = (props) => {
    const {
        skurowsPerPage,
        skupage,
        handleSkuChangePage,
        viewType,
        dateRange,
        selectedComparison,
        shop,
        country,
        warehouse,
        shop_type,
        sku,
    } = props

    const [sortConfig, setSortConfig] = useState({ type: 'totalQty', order: 'asc' })
    const tableContainerRef = useRef(null)
    const [loadedData, setLoadedData] = useState([])
    const [allDataLoaded, setAllDataLoaded] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const { mutate: getSales } = useMutation(
        async (variables) => {
            const response = await purchaseOrderService.getSalesData(
                variables.startDate,
                variables.endDate,
                variables.page,
                variables.rowsPerPage,
                variables.selectedComparison,
                variables.sortType,
                variables.sortOrder,
                variables.shop,
                variables.country,
                variables.warehouse,
                variables.shop_type,
                variables.sku,
            )
            return response.data
        },
        {
            onSuccess: (data) => {
                const newData = viewType === 'design' ? data.designData : data.data
                setLoadedData((prevData) => [...prevData, ...newData])
                const totalToCompare = viewType === 'design' ? data.designTotal : data.total
                setAllDataLoaded(loadedData.length + newData.length >= totalToCompare)
                setIsLoading(false)
            },
            onError: (error) => {
                console.error(error.message || 'Some Error Occurred, Please Try Again')
                setIsLoading(false)
            },
        }
    )

    const loadMoreData = (sortType = sortConfig.type, sortOrder = sortConfig.order) => {
        if (!allDataLoaded && !isLoading) {
            setIsLoading(true)
            const variables = {
                startDate: dateRange.startDate,
                endDate: dateRange.endDate,
                page: skupage,
                rowsPerPage: skurowsPerPage,
                selectedComparison,
                sortType,
                sortOrder,
                shop,
                country,
                warehouse,
                shop_type,
                sku,
            }
            getSales(variables)
        }
    }

    const handleSort = async (type) => {
        const container = tableContainerRef.current
        if (container) {
            await container.scrollTo({ top: 0, behavior: 'smooth' })
        }
        const newOrder = sortConfig.type === type && sortConfig.order === 'asc' ? 'desc' : 'asc'
        setSortConfig({ type, order: newOrder })
        setLoadedData([])
        setAllDataLoaded(false)
        handleSkuChangePage('', 1) // Reset page number to 1 on sort
        loadMoreData(type, newOrder)
    }

    const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = tableContainerRef.current
        if (scrollTop + clientHeight >= scrollHeight - 10 && !allDataLoaded && !isLoading) {
            handleSkuChangePage('', skupage + 1) // Update page number
            loadMoreData() // Load more data
        }
    }

    const getIconStyle = (type, order) => ({
        color: sortConfig.type === type && sortConfig.order === order ? 'black' : 'grey',
        marginLeft: 5,
    })

    useEffect(() => {
        // Reset page number and loaded data when viewType changes
        setLoadedData([])
        setAllDataLoaded(false)
        handleSkuChangePage('', 1)
        loadMoreData()
    }, [viewType])

    useEffect(() => {
        const container = tableContainerRef.current
        if (container) {
            container.addEventListener('scroll', handleScroll)
            return () => {
                container.removeEventListener('scroll', handleScroll)
            }
        }
    }, [allDataLoaded, isLoading, skupage, viewType])

    // Determine the column headers based on selectedComparison
    const getColumnHeader = (yearLabel, monthLabel) => (
        selectedComparison === 'pp' ? monthLabel : yearLabel
    )

    return (
        <Grid item xs={12} lg={12} md={12} sm={12} id={'page-scroll'}>
            <TableContainer className="scroll-box" ref={tableContainerRef}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell rowSpan={2}>
                                <strong>{viewType === 'design' ? 'Design' : 'Sku'}</strong>
                            </TableCell>
                            <TableCell rowSpan={2}><strong>Image</strong></TableCell>
                            <TableCell colSpan={2} align="center"><strong>Units Sold</strong></TableCell>
                            <TableCell colSpan={2} align="center"><strong>Untaxed Amount</strong></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" onClick={() => handleSort('totalQty')}>
                                <strong>{getColumnHeader('2024', 'Current Month')}</strong>
                                <FaArrowUpLong style={getIconStyle('totalQty', 'asc')} />
                                <FaArrowDownLong style={getIconStyle('totalQty', 'desc')} />
                            </TableCell>
                            <TableCell align="center" onClick={() => handleSort('qty_2023')}>
                                <strong>{getColumnHeader('2023', 'Previous Month')}</strong>
                                <FaArrowUpLong style={getIconStyle('qty_2023', 'asc')} />
                                <FaArrowDownLong style={getIconStyle('qty_2023', 'desc')} />
                            </TableCell>
                            <TableCell align="center" onClick={() => handleSort('totalUntaxed')}>
                                <strong>{getColumnHeader('2024', 'Current Month')}</strong>
                                <FaArrowUpLong style={getIconStyle('totalUntaxed', 'asc')} />
                                <FaArrowDownLong style={getIconStyle('totalUntaxed', 'desc')} />
                            </TableCell>
                            <TableCell align="center" onClick={() => handleSort('untaxed_2023')}>
                                <strong>{getColumnHeader('2023', 'Previous Month')}</strong>
                                <FaArrowUpLong style={getIconStyle('untaxed_2023', 'asc')} />
                                <FaArrowDownLong style={getIconStyle('untaxed_2023', 'desc')} />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loadedData.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell>{row.sku}</TableCell>
                                <TableCell>
                                    <Tooltip
                                        title={<img src={row?.image ? row?.image : `https://innoage.blob.core.windows.net/images/648b053c91392cac4623e220/668.9231059410128/MicrosoftTeams-image%20%288%29.png`} alt="Product" width="200px" />}
                                        arrow
                                        placement="right"
                                        PopperProps={{
                                            style: { marginLeft: '10px' } // Adjust the left margin as needed
                                        }}
                                    >
                                        <img src={
                                            row?.image ? row?.image : `https://innoage.blob.core.windows.net/images/648b053c91392cac4623e220/668.9231059410128/MicrosoftTeams-image%20%288%29.png`
                                        } alt="Product" width="60px" height="60px" />
                                    </Tooltip>
                                </TableCell>
                                <TableCell align="center">{row.totalQty ?? '-'}</TableCell>
                                <TableCell align="center">{row.qty_2023 ?? '-'}</TableCell>
                                <TableCell align="center">{row.totalUntaxed?.toFixed(2) ?? '-'}</TableCell>
                                <TableCell align="center">{row.untaxed_2023?.toFixed(2) ?? '-'}</TableCell>
                            </TableRow>
                        ))}
                        {isLoading && (
                            <TableRow>
                                <TableCell colSpan={6} align="center">
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    )
}

SalesDashboardTable.propTypes = {
    skuData: PropTypes.array,
    skurowsPerPage: PropTypes.number.isRequired,
    skupage: PropTypes.number.isRequired,
    handleSkuChangePage: PropTypes.func.isRequired,
    viewType: PropTypes.string.isRequired,
    selectedComparison: PropTypes.string.isRequired,
    shop: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    warehouse: PropTypes.string.isRequired,
    shop_type: PropTypes.string.isRequired,
    sku: PropTypes.string.isRequired,
    dateRange: PropTypes.shape({
        startDate: PropTypes.string.isRequired,
        endDate: PropTypes.string.isRequired,
    }).isRequired,
}

export default SalesDashboardTable
