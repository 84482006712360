import { useState, useEffect, useContext } from 'react'
import productService from 'shared/services/products.service'
import authService from 'shared/services/auth.service'
import { UserContext } from 'context/AuthContext/UserContext'
// import * as XLSX from 'xlsx'
import { useNavigate } from 'react-router-dom'
import { isEmptyObject } from 'shared/util/helper'
import { useMutation, useQuery } from 'react-query'

const useOrdersHooks = () => {
  const { getOrdersData, createOrdersData, getOrderDetails } = productService
  const navigate = useNavigate()
  const contextObj = useContext(UserContext)
  const [orderDetail, setOrderDetail] = useState([])
  const [allOrder, setAllOrder] = useState([])
  const [retail, setRetail] = useState([])
  const [age, setAge] = useState([])
  const [tokenError, setTokenError] = useState('')
  const [importModalOpen, setImportModalOpen] = useState(false)
  const [processedData, setProcessedData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [formData, setFormData] = useState(null)
  const [selectedOption, setSelectedOption] = useState('export')
  const [showNoOrdersToast, setShowNoOrdersToast] = useState(false)
  const [limit, setLimit] = useState(10)
  const [offset, setOffset] = useState(0)
  const [detail, setDetail] = useState()
  const [currentPage, setCurrentPage] = useState(0)
  const [orderCount, setOrderCount] = useState(0)
  const [importResult, setImportResult] = useState({
    savedCount: 0,
    failedCount: 0,
    duplicateCount: 0
  })
  const [filters, setFilters] = useState({
    order_id: '',
    customer: '',
    state: '',
    creation_date: '',
    source: ''
  })


  const fetchData = async () => {
    try {
      const response = await refetch(limit, offset, filters)
      if (response && response.data && response.data.orders) {
        const page = Math.ceil(offset / limit)
        setCurrentPage(page)
        return response.data.orders
      }
    } catch (error) {
      console.error('Error fetching orders:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [limit, offset, filters])


  const { mutate: refetchOrderDetails } = useMutation(
    async (orderId) => {
      try {
        const response = await getOrderDetails(orderId)
        return response.data
      } catch (error) {
        throw error
      }
    },
    {
      onSuccess: (data) => {
        if (data) {
          setDetail(data.orders[0])
        }
      },
      onError: (error) => {
        console.error('Error fetching orders:', error)
      }
    }
  )


  const { refetch } = useQuery(
    ['orders', limit, offset, filters],
    async () => {
      setIsLoading(true)
      const queryParams = { limit, offset, ...filters }
      const filteredParams = Object.fromEntries(Object.entries(queryParams).filter(([, value]) => value !== ''))
      const response = await getOrdersData(filteredParams)
      if (response && response.data) {
        return response.data
      }
      return []
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (fetchedData) => {
        if (fetchedData?.orders && !isEmptyObject(fetchedData) && fetchedData?.orders.length > 0) {
          setOrderDetail(fetchedData.orders)
          setAllOrder(fetchedData.orders)
          setOrderCount(fetchedData.total_order_count)
          setIsLoading(false)
        } else {
          setOrderDetail([])
          setAllOrder([])
          setIsLoading(false)
        }
      },
      onError: (error) => {
        setTokenError(error.status)
        setIsLoading(false)
      }
    }
  )



  const handleFilterChange = (fieldName) => (event) => {
    if (fieldName === 'null') {
      setFilters({
        order_id: '',
        customer: '',
        state: '',
        creation_date: '',
        source: ''
      })
      setAge('All')
      setOffset(0) // Reset offset to 0 when no filter is selected
      setCurrentPage(0)
      refetch()
    } else {
      setFilters({
        ...filters,
        [fieldName]: event.target.value.trim()
      })
      setOffset(0)
      setCurrentPage(0)
    }
  }

  const handleChange = (event) => {
    const selectedShopCode = event.target.value
    setAge(selectedShopCode)
    if (selectedShopCode !== 'All') {
      const filteredOrders = orderDetail.filter((order) => order.shop_code.includes(selectedShopCode))
      setAllOrder(filteredOrders)
    } else {
      setAllOrder(orderDetail)
    }
    setAge(event.target.value)
  }

  const handleFilterOptionChange = async (event) => {
    const option = event.target.value
    setSelectedOption(option)
    await exportOrdersToExcel(event.target.value)
  }

  const handlePreviousPage = () => {
    if (offset - limit >= 0) {
      setOffset(offset - limit)
      setCurrentPage(currentPage - 1)
    }
  }

  const handleNextPage = () => {
    setOffset(offset + limit)
    setCurrentPage(currentPage + 1)
  }

  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10)
    setLimit(newRowsPerPage)
    setOffset(0)
  }

  const navigateToOrderDetails = (orderId) => {
    navigate(`/order/${orderId}`)
  }

  const openImportModal = () => {
    setImportModalOpen(true)
  }

  const closeImportModal = () => {
    setImportModalOpen(false)
  }

  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsArrayBuffer(file)
      fileReader.onload = (e) => {
        const bufferArray = e.target.result
        const wb = XLSX.read(bufferArray, { type: 'buffer' })
        const wsname = wb.SheetNames[0]
        const ws = wb.Sheets[wsname]
        const data = XLSX.utils.sheet_to_json(ws, { defval: '' })
        const groupedOrders = {}
        data.forEach((item) => {
          const orderNumber = item['Order Nr']
          const pattern = /(\d{9}-[A-Z])-\d+/
          const getAddress = () => ({
            name: item['Name'],
            address1: item['Street'],
            address2: item['Street 2'],
            address3: item['Street 2'],
            zip_code: item['Postal code'],
            city: item['Town'],
            state: '',
            country_code: item['Country'],
            phone: item['Phone'],
            mobile: item['Phone'],
            email: item['Mailadress']
          })
          if (orderNumber && pattern.test(orderNumber)) {
            const matchedPart = pattern.exec(orderNumber)[1]
            if (Array.isArray(groupedOrders) && !groupedOrders[matchedPart]) {
              groupedOrders[matchedPart] = {
                order_id: matchedPart,
                billing_address: getAddress(),
                shipping_address: getAddress(),
                order_lines: []
              }
            }
            const orderLine = {
              sku: item['Item Nr'],
              quantity: item['Quantity'],
              unit_price: item['Unit Price']
            }
            if (
              Array.isArray(groupedOrders) &&
              groupedOrders[matchedPart] &&
              Array.isArray(groupedOrders[matchedPart].order_lines)
            ) {
              groupedOrders[matchedPart]?.order_lines?.push(orderLine)
            }
          } else {
            const nonNumberOrder = {
              order_id: orderNumber,
              billing_address: getAddress(),
              shipping_address: getAddress(),
              order_lines: [
                {
                  sku: item['Item Nr'],
                  quantity: item['Quantity'],
                  unit_price: item['Unit Price']
                }
              ]
            }
            groupedOrders[orderNumber] = nonNumberOrder
          }
        })
        const groupedOrderArray = Object.values(groupedOrders)
        resolve(groupedOrderArray)
      }
      fileReader.onerror = (error) => {
        reject(error)
      }
    })
    promise.then((groupedOrderArray) => {
      setProcessedData(groupedOrderArray)
    })
  }

  const handleSubmitBTN = async () => {
    if (processedData) {
      try {
        let savedCount = 0
        let failedCount = 0
        let duplicateCount = 0
        setIsLoading(true)
        for (const item of processedData) {
          const formDataObject = {
            order_id: item.order_id,
            billing_address: item.billing_address,
            shipping_address: item.shipping_address,
            order_lines: item.order_lines,
            source: 'bulk'
          }
          setFormData(formDataObject)
          const response = await handleSubmit(formDataObject)
          if (response && response === 'Order Created') {
            savedCount++
          } else if (response && response === 'Order already created in system') {
            duplicateCount++
          } else if (response && response === 'Order Failed') {
            failedCount++
          }
        }
        setImportResult({
          savedCount,
          failedCount,
          duplicateCount
        })

        setIsLoading(false)
        closeImportModal()
      } catch (error) {
        console.error('Error handling submit:', error)
      }
    } else {
      console.error('Processed data is not available.')
    }
    closeImportModal()
  }

  const downloadSampleCSV = () => {
    import(/* webpackChunkName: "sampleCSV" */ 'assets/images/Order_Bulk_Import.xlsx')
      .then((module) => {
        const excelURL = module.default

        if (typeof excelURL === 'string' && excelURL) {
          const a = document.createElement('a')
          a.style.display = 'none'
          a.href = excelURL
          a.download = 'Order_Bulk_Import.xlsx'
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        } else {
          alert('Invalid URL for Excel file')
        }
      })
      .catch((error) => {
        console.error('Error importing Excel file:', error)
      })
  }

  const exportOrdersToExcel = async (file) => {
    /* eslint-disable */
    try {
      let filteredOrders = []
      const orders = await refetch()
      if (Array.isArray(orders) && orders.length > 0) {
        if (file === 'Today') {
          const today = new Date()
          filteredOrders = orders.filter((order) => {
            const orderDate = new Date(order.order_date)
            return orderDate.toDateString() === today.toDateString()
          })
        } else if (file === 'Yesterday') {
          const yesterday = new Date()
          yesterday.setDate(yesterday.getDate() - 1)
          filteredOrders = orders.filter((order) => {
            const orderDate = new Date(order.order_date)
            return orderDate.toDateString() === yesterday.toDateString()
          })
        } else if (file === 'All') {
          // No date filtering for "all" option
          filteredOrders = orders
        } else {
          alert('Invalid date selection.')
          return
        }

        if (filteredOrders.length > 0) {
          const allOrdersWithTracking = filteredOrders.map((order) => {
            const {
              tracking_details,
              shipping_address,
              billing_address,
              order_lines,
              fulfillments,
              ...orderDataWithoutTracking
            } = order

            if (tracking_details) {
              return {
                ...orderDataWithoutTracking,
                ...tracking_details[0]
              }
            } else {
              return {
                ...orderDataWithoutTracking,
                tracking_code: '',
                carrier: '',
                shipped_date: '',
                tracking_url: ''
              }
            }
          })

          const wb = XLSX.utils.book_new()
          const ws = XLSX.utils.json_to_sheet(allOrdersWithTracking)

          if (ws && ws['!ref']) {
            XLSX.utils.book_append_sheet(wb, ws, 'Orders')

            const excelArrayBuffer = XLSX.write(wb, {
              type: 'array',
              mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            })

            if (excelArrayBuffer) {
              const blob = new Blob([excelArrayBuffer], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              })

              const url = window.URL.createObjectURL(blob)
              const a = document.createElement('a')
              a.href = url
              a.download = 'orders.xlsx'
              a.click()
              window.URL.revokeObjectURL(url)
            } else {
              alert('Failed to generate Excel array buffer.')
            }
          } else {
            console.error('Worksheet is empty.')
          }
        } else {
          console.warn('No orders for today to export.')
          setShowNoOrdersToast(true)
        }
      } else {
        alert('No orders to export.')
      }
    } catch (error) {
      console.error('Error exporting orders:', error)
    }
  }

  const handleNoOrdersToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setShowNoOrdersToast(false)
  }

  const refreshRetailToken = async () => {
    try {
      const existingValue = localStorage.getItem('iauser')
      const userObject = JSON.parse(existingValue || '{}')
      const response = await authService.refreshToken(
        contextObj.userData.user.companyId,
        contextObj.userData.user.shopName,
        contextObj.userData.user.email
      )
      userObject.user.retailToken = response.data
      const updatedValue = JSON.stringify(userObject)
      localStorage.setItem('iauser', updatedValue)
      window.location.reload()
    } catch (error) {
      throw error
    }
  }

  const mutation = useMutation(async (temp) => {
    const response = await createOrdersData(temp, contextObj.userData.user.retailToken)
    return response.data
  })

  const handleSubmit = async (temp) => {
    try {
      const response = await mutation.mutateAsync(temp)
      if (response === 'Order already created in system') {
        return 'Order already created in system'
      } else if (response === 'Order created successfully') {
        return 'Order Created'
      } else if (
        response === 'SKU is required field in Order Lines' ||
        response === 'Please enter valid Country Name IN Billing Address'
      ) {
        return 'Order Failed'
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getRetailShop = async () => {
    try {
      const response = await authService.getRetailshop()
      setRetail(response.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getRetailShop()
  }, [])

  return {
    orderDetail,
    age,
    setAge,
    handleSubmit,
    allOrder,
    setOrderDetail,
    setAllOrder,
    refetch,
    tokenError,
    refreshRetailToken,
    getRetailShop,
    retail,
    setRetail,
    importModalOpen,
    isLoading,
    formData,
    selectedOption,
    showNoOrdersToast,
    importResult,
    filters,
    currentPage,
    limit,
    setImportResult,
    closeImportModal,
    handleNoOrdersToastClose,
    downloadSampleCSV,
    handleSubmitBTN,
    readExcel,
    handleFilterChange,
    handleChange,
    handleFilterOptionChange,
    handlePreviousPage,
    handleNextPage,
    navigateToOrderDetails,
    openImportModal,
    setProcessedData,
    contextObj,
    detail,
    refetchOrderDetails,
    orderCount,
    handleRowsPerPageChange,
    limit
  }
}

export default useOrdersHooks
