// import axios from 'axios'
import { isNotNullUndefinedOrEmpty } from 'shared/util/helper'



const logout = () => {
    // ARUN - API CALL to expire token
    localStorage.removeItem('iauser')
}

const getCurrentUser = () => {
    const user = JSON.parse(localStorage.getItem('iauser'))
    if (isNotNullUndefinedOrEmpty(user)) {
        const currentDate = new Date().toISOString()
        if (Date.parse(user.tokenExpiration) > Date.parse(currentDate)) {
            return user
        } else {
            logout()
            return ''
        }
    }
}


const isLoggedIn = () => {
    return isNotNullUndefinedOrEmpty(getCurrentUser())
}


const userService = {
    logout,
    getCurrentUser,
    isLoggedIn,
}

export default userService
