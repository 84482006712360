export default function authHeader(contentType = 'application/json', authToken = "", odooToken="") {
  const user = JSON.parse(localStorage.getItem('iauser'))
  if (authToken !== null && authToken !== undefined && authToken !== "") {
    return { Authorization: 'Bearer ' + authToken, 'Content-Type': contentType }
   } 
  else if (odooToken !== null && odooToken !== undefined && odooToken !== "") {
    return { Authorization: odooToken, 'Content-Type': 'text/html; charset=utf-8' }
  } 
  else if (user && user.token) {
    return { Authorization: 'Bearer ' + user.token, 'Content-Type': contentType }
  }
  else {
    return { 'Content-Type': contentType }
  }
}
