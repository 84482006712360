import React from 'react'
import { Grid } from '@mui/material'
import SimpleBar from 'simplebar-react'
import PropTypes from 'prop-types'
import ImageMetadata from 'components/uploader/tiles/ImageMetadata'
import { Document, Page, pdfjs } from 'react-pdf'
import Loader from 'components/common/Loader'

const ImageFiles = ({ image, isLoading }) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`
  return (
    <SimpleBar
      data-testid='new-files'
      style={{
        height: '17rem',
        maxHeight: '18rem',
        border: '1px dotted grey',
        borderRadius: '15px',
        margin: 10
      }}
    >
      <div
        style={{
          padding: '15px',
          paddingLeft: '45px'
        }}
      >
        <Grid
          container
          spacing={{
            xs: 2,
            md: 3
          }}
        >
          {isLoading ? (
            <Loader />
          ) : (
            <Grid item xs={12} sm={10} md={10}>
              {image.type === 'application/pdf' ? (
                <Document file={image}>
                  <Page pageNumber={1} />
                </Document>
              ) : (
                <ImageMetadata data={image} />
              )}
            </Grid>
          )}
        </Grid>
      </div>
    </SimpleBar>
  )
}

ImageFiles.propTypes = {
  handleUpload: PropTypes.func,
  image: PropTypes.object,
  isLoading: PropTypes.bool,
  uploadFailure: PropTypes.bool,
  uploadSuccess: PropTypes.bool,
  isLoading: PropTypes.string
}

export default ImageFiles
