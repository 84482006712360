import { CCol, CForm, CFormInput, CFormLabel, CRow } from '@coreui/react'
import React, { useState } from 'react'
import { Button as ProductButton } from '@mui/material'
import { HiViewGridAdd } from 'react-icons/hi'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import useOrdersHooks from './useOrdersHooks'
import CircularProgress from '@mui/material/CircularProgress'
import { useNavigate } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import IconButton from '@mui/material/IconButton'
import { AppBreadcrumb } from 'sections/layout/dashboard'

const Create = (props) => {
    const navigate = useNavigate()
    let breadCrumbPath = [
        { name: 'Orders List', url: `/orders`, active: 'not-allowed' },
        { name: 'Order-Create', url: `/order/create`, active: 'not-allowed' },
    ]

    const [isLoading, setIsLoading] = useState(false)
    const [isChecked, setIsChecked] = useState(false)
    const [serialNumber, setSerialNumber] = useState(1)
    const [orderLines, setOrderLines] = useState([
        {
            sku: '',
            quantity: 0,
            unit_price: 0,
        },
    ])

    const addOrderLineFields = () => {
        const newSerialNumber = serialNumber + 1
        setOrderLines((prevOrderLines) => [
            ...prevOrderLines,
            {
                sku: '',
                quantity: 0,
                unit_price: 0,
            },
        ])
        setFormData((prevFormData) => ({
            ...prevFormData,
            order_lines: [
                ...prevFormData.order_lines,
                {
                    sku: '',
                    quantity: 0,
                    unit_price: 0,
                },
            ],
        }))
        setSerialNumber(newSerialNumber)
    }

    const handleSave = async () => {
        setIsLoading(true)
        try {
            await new Promise((resolve) => setTimeout(resolve, 2000))
            navigate('/orders')
        } catch (error) {
           
            console.error('Save error:', error)
        } finally {
            setIsLoading(false) 
        }
    }

    const [shipping_address, setShipping_address] = useState({
        name: '',
        address1: '',
        address2: '',
        address3: '',
        zip_code: '',
        city: '',
        state: '',
        country_code: '',
        phone: '',
        mobile: '',
        email: '',
    })

    const [billing_address, setBilling_address] = useState({
        name: '',
        address1: '',
        address2: '',
        address3: '',
        zip_code: '',
        city: '',
        state: '',
        country_code: '',
        phone: '',
        mobile: '',
        email: '',
    })

    const [formData, setFormData] = useState({
        order_id: '',
        shipping_address: {
            name: '',
            address1: '',
            address2: '',
            address3: '',
            zip_code: '',
            city: '',
            state: '',
            country_code: '',
            phone: '',
            mobile: '',
            email: '',
        },
        billing_address: {
            name: '',
            address1: '',
            address2: '',
            address3: '',
            zip_code: '',
            city: '',
            state: '',
            country_code: '',
            phone: '',
            mobile: '',
            email: '',
        },
        source: 'Manual', // Set the source field here
        order_lines: orderLines,
    })

    const { handleSubmit } = useOrdersHooks(props, formData)

    const handleCheckboxChange = (event) => {
        const isChecked = event.target.checked

        setIsChecked(isChecked)
        setFormData((prevFormData) => ({
            ...prevFormData,

            billing_address: isChecked ? { ...prevFormData.shipping_address } : { ...billing_address },
        }))

        if (isChecked) {
            
            setBilling_address({ ...shipping_address })
        } else {
            
            setBilling_address({
                name: '',
                address1: '',
                address2: '',
                address3: '',
                zip_code: '',
                city: '',
                state: '',
                country_code: '',
                phone: '',
                mobile: '',
                email: '',
            })
        }
    }

    const handleShippingAddressChange = (event, field) => {
        setShipping_address({
            ...shipping_address,
            [field]: event.target.value,
        })

     
        setFormData((prevFormData) => ({
            ...prevFormData,
            shipping_address: {
                ...prevFormData.shipping_address,
                [field]: event.target.value,
            },
        }))
    }

    const handleBillingAddressChange = (event, field) => {
        
        setBilling_address({
            ...billing_address,
            [field]: event.target.value,
        })

        setFormData((prevFormData) => ({
            ...prevFormData,
            billing_address: {
                ...prevFormData.billing_address,
                [field]: event.target.value,
            }
        }))
    }

    function formatFieldName(fieldName) {
        
        return fieldName
            .split(/(?=[A-Z])/)
            .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
            .join(' ')
    }

    const handleOrderLineChange = (event, field, index) => {
        const newOrderLines = [...formData.order_lines]

        if (field === 'quantity' || field === 'unit_price') {
            const newValue = parseFloat(event.target.value) 

            if (!isNaN(newValue)) {
                newOrderLines[index] = {
                    ...newOrderLines[index],
                    [field]: newValue,
                }
            }
        } else {
            newOrderLines[index] = {
                ...newOrderLines[index],
                [field]: event.target.value,
            }
        }


        setFormData((prevFormData) => ({
            ...prevFormData,
            order_lines: newOrderLines,
        }))
    }

    const handleSaveAndSubmit = async () => {
        const formDataWithSource = {
            ...formData,
            source: 'manual',
        }
        handleSave()

        handleSubmit(formDataWithSource)
    }

    return (
        <>
            <CRow className='mt-5 mb-2'>
                <p className='heading-large display-6 mb-1 ms-2 text-start' style={{ paddingLeft: '60px' }} ><AppBreadcrumb pathNameDeclare={breadCrumbPath} /></p>
                <p className='heading-large display-6 mb-1 ms-2 text-start' style={{ paddingLeft: '60px' }}> Create Order</p>
            </CRow>
            <CForm className='row g-3 needs-validation' style={{ paddingLeft: '40px', paddingRight: '40px' }}>
                <CRow className='ms-2 mt-5'>
                    <CCol sm={12}>
                        <CRow>
                            <CCol sm={6}>
                                <CRow>
                                    <CCol sm={12}>
                                        <CFormLabel htmlFor='orderId' className='col-sm-12 col-form-label' style={{ fontWeight: 'bold' }}>
                                            Order ID<span style={{ color: 'red' }}>*</span>
                                        </CFormLabel>
                                        <CFormInput type='text' id='order_id' name="order_id" value={formData.order_id} placeholder='Enter Order Id'
                                            onChange={(event) => {
                                                const { name, value } = event.target
                                                setFormData((prevFormData) => ({
                                                    ...prevFormData,
                                                    [name]: value,
                                                }))
                                            }}
                                        />
                                    </CCol>
                                </CRow>
                            </CCol>
                            <CCol sm={12}>
                                <CRow>
                                    <CCol sm={4}>
                                        <CFormLabel htmlFor='shipping_address' className='col-sm-4 col-form-label' style={{ fontWeight: 'bold', paddingTop: '30px' }}>
                                            Shipping Address<span style={{ color: 'red' }}>*</span>
                                        </CFormLabel>
                                    </CCol>
                                </CRow>
                                {/* Shipping Address Fields */}
                                <CRow>
                                    {Object.keys(shipping_address).map((field) => (

                                        <CCol key={field} sm={6}>
                                            <CFormLabel htmlFor={`shipping${field}`} className='col-sm-4 col-form-label'>
                                                {formatFieldName(field)}
                                            </CFormLabel>
                                            <CFormInput
                                                type='text'
                                                id={`shipping${field}`}
                                                placeholder={`Enter ${field}`}
                                                required
                                                value={shipping_address[field]}
                                                onChange={(event) => handleShippingAddressChange(event, field)}
                                            />
                                        </CCol>
                                    ))}
                                </CRow>
                            </CCol>
                            {!isChecked ? (
                                <CCol sm={12}>
                                    <CRow>
                                        <CCol sm={4}>
                                            <CFormLabel htmlFor='billing_address' className='col-sm-4 col-form-label' style={{ fontWeight: 'bold', paddingTop: '30px' }}>
                                                Billing Address
                                            </CFormLabel>
                                        </CCol>
                                        <CCol sm={12}>
                                            <FormControlLabel
                                                control={<Checkbox checked={isChecked} onChange={handleCheckboxChange} />}
                                                label='Same as Shipping Address'
                                            />
                                        </CCol>
                                    </CRow>
                                    {/* Billing Address Fields */}
                                    <CRow>
                                        {Object.keys(billing_address).map((field) => (
                                            <CCol sm={6} key={field}>
                                                <CFormLabel htmlFor={`billing${field}`} className='col-sm-4 col-form-label'>
                                                    {formatFieldName(field)}
                                                </CFormLabel>
                                                <CFormInput
                                                    type='text'
                                                    id={`billing${field}`}
                                                    placeholder={`Enter ${field}`}
                                                    required
                                                    value={billing_address[field]}
                                                    onChange={(event) => handleBillingAddressChange(event, field)}
                                                />
                                            </CCol>
                                        ))}
                                    </CRow>
                                </CCol>
                            ) : (
                                <>
                                    <CCol sm={12}>
                                        <CRow>
                                            <CCol sm={12}>
                                                <CFormLabel htmlFor='billing_address' className='col-sm-4 col-form-label' style={{ fontWeight: 'bold', paddingTop: '30px' }}>
                                                    Billing Address
                                                </CFormLabel>
                                            </CCol>
                                            <CCol sm={12}>
                                                <FormControlLabel
                                                    control={<Checkbox checked={isChecked} onChange={handleCheckboxChange} />}
                                                    label='Same as Shipping Address'
                                                />
                                            </CCol>
                                        </CRow>
                                    </CCol>
                                </>
                            )}
                            <CCol sm={12}>
                                <CCol sm={4}>
                                    <CFormLabel htmlFor='order_lines' className='col-sm-4 col-form-label' style={{ fontWeight: 'bold', paddingTop: '30px' }}>
                                        Order Lines<span style={{ color: 'red' }}>*</span>
                                    </CFormLabel>
                                </CCol>

                                {formData.order_lines.map((orderLine, index) => (
                                    <div key={index}>
                                        {index > 0 && <hr style={{ marginTop: '30px', width: '672px'}}/>} 
                                        <CFormLabel className='col-sm-4 col-form-label'>
                                            {index + 1}. 
                                        </CFormLabel>
                                        <CCol sm={6}>
                                            <CFormLabel htmlFor={`sku${index}`} className='col-sm-4 col-form-label'>
                                                SKU<span style={{ color: 'red' }}>*</span>
                                            </CFormLabel>
                                            <CFormInput
                                                type='text'
                                                id={`sku${index}`}
                                                placeholder='Enter SKU of product'
                                                value={orderLine.sku}
                                                onChange={(event) => handleOrderLineChange(event, 'sku', index)}
                                            />
                                        </CCol>

                                        <CFormLabel htmlFor={`quantity${index}`} className='col-sm-4 col-form-label'>
                                            Quantity<span style={{ color: 'red' }}>*</span>
                                        </CFormLabel>
                                        <CCol sm={6}>
                                            <CFormInput
                                                type='number'
                                                id={`quantity${index}`}
                                                placeholder='Enter quantity of product'
                                                required
                                                value={orderLine.quantity}
                                                onChange={(event) => handleOrderLineChange(event, 'quantity', index)}
                                            />
                                        </CCol>

                                        <CFormLabel htmlFor={`unit_price${index}`} className='col-sm-4 col-form-label'>
                                            Price<span style={{ color: 'red' }}>*</span>
                                        </CFormLabel>
                                        <CCol sm={6}>
                                            <CFormInput
                                                type='number'
                                                id={`unit_price${index}`}
                                                placeholder='Enter price of product'
                                                required
                                                value={orderLine.unit_price}
                                                onChange={(event) => handleOrderLineChange(event, 'unit_price', index)}
                                            />
                                        </CCol>
                                    </div>
                                ))}
                                <div className="text-left" style={{ paddingTop: '20px' }}>
                                    <IconButton color="secondary" onClick={addOrderLineFields}>
                                        <AddIcon />
                                    </IconButton>

                                </div>
                            </CCol>
                        </CRow>
                    </CCol>
                </CRow>
            </CForm>
            <CRow className='mt-5 mb-2'>
                <CCol className='mb-3 mr-4 me-4 d-flex justify-content-end'>
                    {isLoading ? (
                        <div className='d-flex align-items-center'>
                            <ProductButton variant='outlined'>Saving&nbsp;
                                <CircularProgress size={24} />
                            </ProductButton>
                        </div>
                    ) : (
                        <ProductButton variant='outlined' onClick={handleSaveAndSubmit}>
                            Save
                            <HiViewGridAdd size='1.5rem' />
                        </ProductButton>
                    )}
                </CCol>
            </CRow>
        </>
    )
}

export default Create
