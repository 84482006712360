import React, { useState } from 'react'

import ChatList from 'components/chat/fullPage/chatList'
import ChatBody from 'components/chat/fullPage/chatBody'
import Status from 'components/common/status/VerticalStatus'
import { isNotNullUndefinedOrEmpty } from 'shared/util/helper'
import Seo from 'components/common/seo'
import Loader from 'components/common/Loader'
import PropTypes from 'prop-types'
import Images from 'data/Images'

import './index.css'
import { UserContext } from 'context/AuthContext/UserContext'
import purchaseOrderService from 'shared/services/purchase-order-service'
import { useQuery } from 'react-query'

const Messages = (props) => {
  const [chat, setChat] = useState(null)
  const [chats, setChats] = useState([])
  const [chatId, setChatId] = useState()
  const [chatName, setChatName] = useState()
  const [value, setValue] = useState('Purchase Order')

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const getPoChats = async(poId, page, limit, name) => {
    try {
      setChatId(poId)
      setChatName(name)
      purchaseOrderService.getAllPoChats(poId, page, limit).then(
        (response) => {
          setChat(response.data[0])
        },
      )
    } 
    catch (error) {
      
    }
  }
  let error = ''
  const contextObj = React.useContext(UserContext)

  const { isLoading:isLoading } = useQuery(
    ['getUserQueries', contextObj?.userData?.user?.companyId],
    async () => {
      const response = await purchaseOrderService.getAllChats(contextObj.userData.user.pomUserType, contextObj.userData.user.companyId, contextObj.userData.user.id)
      return response
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        setChats(response.data)
      },
      onError: (error) => {
        console.log(error , 'onError')
      },
    }
  )
  
  const handleReply = (id, messages) => {
    const tempQueries = chats
    tempQueries.queries.find((x) => x.id === id).messages = messages
    setChat({ ...chat, messages: [...messages] })
  }

  const clearChat = () => {
    setChat(null)
  }

  return (
    <>
      <Seo title='Messenger' />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {Array.isArray(chats) && chats?.length > 0 ? (
            <div className='row full-page-chat'>
              {
                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 mb-4 mb-md-0 chat-users-list'>
                  <ChatList
                    chatListData={chats}
                    logo={chats?.logo}
                    message={chat}
                    users={chat?.users}
                    setMessage={getPoChats}
                    user={contextObj.userData}
                    value={value}
                    handleChange={handleChange}
                    socket={props.socket}
                  />
                </div>
              }
              {chat !== null && (
                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-7 col-xl-8'>
                  <ChatBody
                    catalogueName={chat?.catalogueName || ''}
                    companyLogo={chats?.logo || Images.InnoAgeLogoWithBG}
                    buyerLogo={chat?.buyerLogo}
                    chat={chat}
                    setChat={setChat}
                    clearChat={clearChat}
                    handleReply={handleReply}
                    socket={props.socket}
                    poId={chatId}
                    userId={contextObj.userData.user.id}
                    userName={contextObj.userData.user.fName}
                    value={value}
                    chatName={chatName}
                  />
                </div>
              )}
            </div>
          ) : (
            <Status
              image={Images.Messaging}
              message={isNotNullUndefinedOrEmpty(error) ? 'Something Went Wrong!!!' : 'No Conversations found'}
              error={error}
              height='35rem'
              imgheight='15rem'
              extraClasses='pt-5'
            />
          )}
        </>
      )}
    </>
  )
}
Messages.propTypes = {
  socket: PropTypes.object
}

export default Messages
