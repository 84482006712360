import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

const InnoAgeLogo = 'https://innoageimages.blob.core.windows.net/images/assets/pom.svg'

function Seo({ description, lang, meta, title, image }) {
  const metaTitle = 'POM -Create, Negotiate, Finalize, and Track with Ease.'
  const metaDesc =
    'Empower Your Business with our All-in-One Solution: Create, Negotiate, Finalize, and Track with Ease.'
  const metaAuthor = 'InnoAge™-Technologies PVT. LTD'

  const metaDescription = description || metaDesc
  const defaultTitle = metaTitle
  const metaImage = image || InnoAgeLogo

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: 'description',
          content: metaDescription
        },
        {
          property: 'og:title',
          content: title
        },
        {
          property: 'og:description',
          content: metaDescription
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          property: 'og:image',
          content: metaImage
        },
        {
          property: 'og:image:type',
          content: 'image/png'
        },
        {
          property: 'og:image:width',
          content: '320'
        },
        {
          property: 'og:image:height',
          content: '349'
        },
        {
          name: 'twitter:card',
          content: 'summary'
        },
        {
          name: 'twitter:creator',
          content: metaAuthor || ''
        },
        {
          name: 'twitter:image',
          content: metaImage
        },
        {
          name: 'twitter:title',
          content: title
        },
        {
          name: 'twitter:description',
          content: metaDescription
        }
      ].concat(meta)}
    />
  )
}

Seo.defaultProps = {
  lang: 'en',
  meta: [],
  description: ''
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.string
}

export default Seo
