import React from 'react'
import { Button, Checkbox, FormControlLabel, Grid } from '@mui/material'
import SimpleBar from 'simplebar-react'
import PropTypes from 'prop-types'
import Loader from 'components/common/Loader'

const Query = (props) => {
  const { queryNessage, setQueryMessage, isLoading, showQuery, setShowQuery, scrollToBottom } = props
  const handleSelect = (value) => {

    setQueryMessage(value)
    scrollToBottom()
  }
  const handleSubmit = () => {
    setShowQuery(!showQuery)
    props.onSubmit(queryNessage, 'query')
    setQueryMessage('')

  }

  return (
    <SimpleBar
      data-testid='new-files'
      style={{
        // height: '17rem',
        maxHeight: '18rem',
        border: '1px dotted grey',
        borderRadius: '15px',
        margin: 5
      }}
    >
      <div
        style={{
          padding: '15px',
          paddingLeft: '15px'
        }}
      >
        <Grid
          container
          spacing={{
            xs: 2,
            md: 3
          }}
        >
          {isLoading ? (
            <Loader />
          ) : (
            <Grid item xs={12} sm={10} md={10}>
              <Grid
                style={{
                  textAlign: 'center',
                  paddingLeft: '35px',
                  paddingBottom: '20px'
                }}
              >
                <h5>Raise a query</h5>
              </Grid>

              <Grid
                item
                style={{
                  paddingBottom: '5px'
                }}
              >
                <FormControlLabel
                  className='checkBoxItem'
                  control={
                    <Checkbox
                      data-testid='checkBox'
                      size='small'
                      id={'1234'}
                      color='primary'
                      checked={queryNessage === 'Order Cancellation'}
                      onChange={() => handleSelect('Order Cancellation')}
                      sx={{ '& .MuiSvgIcon-root': { fontSize: '0.9em' } }}
                    />
                  }
                  label={<span className='sub-item-label'>Order Cancellation</span>}
                />
              </Grid>
              <Grid
                item
                style={{
                  paddingBottom: '5px'
                }}
              >
                <FormControlLabel
                  className='checkBoxItem'
                  control={
                    <Checkbox
                      data-testid='checkBox'
                      size='small'
                      id={'1234'}
                      color='primary'
                      checked={queryNessage === 'Missing Shippment'}
                      onChange={() => handleSelect('Missing Shippment')}
                      sx={{ '& .MuiSvgIcon-root': { fontSize: '0.9em' } }}
                    />
                  }
                  label={<span className='sub-item-label'>Missing Shippment</span>}
                />
              </Grid>
              <Grid
                item
                style={{
                  paddingBottom: '5px'
                }}
              >
                <FormControlLabel
                  className='checkBoxItem'
                  control={
                    <Checkbox
                      data-testid='checkBox'
                      size='small'
                      id={'1234'}
                      color='primary'
                      checked={queryNessage === 'Order Status'}
                      onChange={() => handleSelect('Order Status')}
                      sx={{ '& .MuiSvgIcon-root': { fontSize: '0.9em' } }}
                    />
                  }
                  label={<span className='sub-item-label'>Order Status</span>}
                />
              </Grid>
              <Grid
                item
                style={{
                  paddingLeft: '30px',
                  paddingBottom: '5px',
                  textAlign: 'center',
                  paddingTop: '20px'
                }}
              >
                <Button variant='outlined' color='success' onClick={() => handleSubmit()}>
                  Submit
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </div>
    </SimpleBar>
  )
}

Query.propTypes = {
  handleUpload: PropTypes.func,
  image: PropTypes.object,
  isLoading: PropTypes.bool,
  uploadFailure: PropTypes.bool,
  uploadSuccess: PropTypes.bool,
  isLoading: PropTypes.string,
  queryNessage: PropTypes.string,
  setQueryMessage: PropTypes.func,
  showQuery: PropTypes.bool,
  setShowQuery: PropTypes.func,
  scrollToBottom: PropTypes.func,
  onSubmit: PropTypes.func,

}

export default Query
