import axios from 'axios'
import authHeader from './auth-header'


const addWarehouse = (input) => {
  return axios.post(
    `${process.env.REACT_APP_API_WAREHOUSE}/api/warehouse`,
    input,
    {
      headers: authHeader()
    },
    { timeout: process.env.REACT_APP_API_TIMEOUT }
  )
}



const getWarehouse = (companyId) => {
  return axios.get(
    `${process.env.REACT_APP_API_WAREHOUSE}/api/warehouse/allWarehouse/${companyId}`,
    {
      headers: authHeader()
    },
    { timeout: process.env.REACT_APP_API_TIMEOUT }
  )
}
const getWarehouseonId = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API_WAREHOUSE}/api/warehouse/getwarehouse/${id}`,
    {
      headers: authHeader()
    },
    { timeout: process.env.REACT_APP_API_TIMEOUT }
  )
}
const deleteWarehouse = (id) => {
  return axios
    .delete(
      `${process.env.REACT_APP_API_WAREHOUSE}/api/warehouse/Warehouse/${id}`,
      {
        headers: authHeader()
      },
      { timeout: process.env.REACT_APP_API_TIMEOUT }
    )
    .then(
      (response) => {
        return response
      },
      (error) => {
        throw error.response
      }
    )
}
const updateWarehouse = (id, input) => {
  return axios
    .patch(
      `${process.env.REACT_APP_API_WAREHOUSE}/api/warehouse/warehouseId/${id}`,
      input,
      {
        headers: authHeader()
      },
      { timeout: process.env.REACT_APP_API_TIMEOUT }
    )
    .then(
      (response) => {
        return response
      },
      (error) => {
        throw error.response
      }
    )
}


const WarehouseServices = {
  addWarehouse,
  getWarehouse,
  getWarehouseonId,
  deleteWarehouse,
  updateWarehouse
}

export default WarehouseServices