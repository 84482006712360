import React, { useContext, useEffect } from 'react'

import PropTypes from 'prop-types'
import { DataTable, ToastMessage } from 'components/uiElements'
import { getDate, isNotNullUndefinedOrEmpty } from 'shared/util/helper'
import { Grid } from '@mui/material'
import CatalogueDisplay from 'pages/catalogues/components/PoNumber'
import Supplier from 'pages/catalogues/components/Supplier'
import CatalogueStatus from 'pages/catalogues/components/CatalogueStatus'

import { projectlistHeadCells } from 'data/HeadCells'
import Constants from 'data/Constants'
import ShipTo from 'pages/catalogues/components/ShipTo'
import PoDate from 'pages/catalogues/components/PoDate'
import PoDueDate from 'pages/catalogues/components/PoDueDate'
import ShipVia from 'pages/catalogues/components/ShipVia'
import { useProjectHooks } from '../create/useProjectHooks'
import ProjectHeader from '../create/ProjectHeader'
import { HiViewGridAdd } from 'react-icons/hi'
import ResultPo from '../create/ResultPo'
import Loader from 'components/common/Loader'
import { useParams } from 'react-router-dom'
import { AppBreadcrumb } from 'sections/layout/dashboard'
import { LoadingButton } from '@mui/lab'
import { UserContext } from 'context/AuthContext/UserContext'


const CatalogList = () => {
  const { projectId } = useParams()
  const { loading } = useContext(UserContext)
  let breadCrumbPath = [
    { name: 'Projects', url: `/projects`, active: 'not-allowed' },
    {
      name: `${projectId ? 'Project Update' : 'Project Create'} `,
      url: `${projectId ? `/project/edit/${projectId}` : `/projects/list`}`,
      active: 'not-allowed'
    }
  ]
  const {
    values,
    setPurchaseOrders,
    purchaseOrders,
    rows,
    setRows,
    searchKey,
    setSearchKey,
    selected,
    setSelected,
    error,
    sortOn,
    setSortOn,
    isEmpty,
    showReviewList,
    dynamicSort,
    sortOrder,
    setSortOrder,
    draftBtn,
    setDraftBtn,
    createData,
    EditProject,
    handleChange,
    isLoading,
    handlePoSubmit,
    validated,
    isload,
    errorMessage,
    vendors,
    success
  } = useProjectHooks()

  const handleProject = (e, i) => {
    const activeData = document.getElementById(i).checked
    if (activeData == true) {
      setSelected((oldData) => [...oldData, e.target.value])
    } else {
      setSelected((oldData) => oldData.filter((po) => po !== e.target.value))
    }
  }

  const SetTableRows = (responseData) => {
    if (Array.isArray(responseData)) {
      const arrData = responseData.map((catalogue, i) =>
        createData(
          <CatalogueDisplay
            name={catalogue.poNumber}
            isQuery={catalogue.isQuery}
            isQueryPending={catalogue.isQueryPending}
            catalogueId={catalogue.code}
          />,

          <Supplier supplier={catalogue?.supplierName} />,
          <ShipTo shipTo={catalogue?.shipTo} />,
          <PoDate poDate={getDate(catalogue?.poDate)} />,
          <PoDueDate poDueDate={getDate(catalogue?.poDueDate)} />,
          <ShipVia shipVia={catalogue?.shipVia} />,
          <CatalogueStatus status={2} />,
          <input
            id={i}
            type='checkbox'
            value={catalogue?._id}
            checked={selected.includes(catalogue?._id)}
            onChange={(e) => handleProject(e, i)}
          ></input>
        )
      )
      setRows(arrData)
    }
  }

  useEffect(() => {
    if (searchKey !== '') {
      SetTableRows(purchaseOrders.filter((x) => x.poNumber.toLowerCase().includes(searchKey)))
    } else {
      let arr = selected.reduce((acc, selectedId) => {
        const matches = purchaseOrders.filter((x) => x._id.toLowerCase().includes(selectedId))
        return matches.length > 0 ? acc.concat(matches) : acc
      }, [])
      SetTableRows([...arr])
    }
  }, [searchKey])

  useEffect(() => {
    if (isNotNullUndefinedOrEmpty(sortOn) && purchaseOrders.length > 0 && searchKey !== '') {
      SetTableRows(purchaseOrders.sort(dynamicSort(sortOn)))
    }
  }, [sortOn, sortOrder])

  useEffect(() => {

    if (searchKey !== '') {
      SetTableRows(purchaseOrders)
    } else {
      let arr = selected.reduce((acc, selectedId) => {
        const matches = purchaseOrders.filter((x) => x._id.toLowerCase().includes(selectedId))
        return matches.length > 0 ? acc.concat(matches) : acc
      }, [])
      SetTableRows([...arr])
    }
  }, [purchaseOrders, draftBtn, setPurchaseOrders, selected])

  return (
    <div className='p-5'>
      {success ? (
        <ResultPo error={error} />
      ) : (<div>
        <Grid container>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <AppBreadcrumb pathNameDeclare={breadCrumbPath} />
            <h3>Projects</h3>
            <p>View & Manage your Projects</p>
            {errorMessage && <ToastMessage color='danger' message='select purchase order' />}
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Grid textAlign='end' alignItems='end'>
              <LoadingButton
                variant='outlined'
                loading={loading}
                onClick={projectId ? EditProject : handlePoSubmit
                }>
                {projectId ? "Update" : "Create"} &nbsp;&nbsp;&nbsp;
                <HiViewGridAdd size='1.5rem' />
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
        <ProjectHeader
          values={values}
          handleChange={handleChange}
          handleSubmit={handlePoSubmit}
          validated={validated}
          vendors={vendors}
        />
        {isLoading ? (<>{isload ?
          (<Loader />) : ('')}</>
        ) : (
          <div className='row mt10 mb-5'>
            <>
              <DataTable
                setIsLoading={isLoading}
                headCells={projectlistHeadCells}
                defaultMessage='Search  Purchase Order '
                defaulSubMessage='Try to search Purchase Order'
                rows={rows}
                isEmpty={isEmpty}
                searchKey={searchKey}
                sortOn={sortOn}
                setSearchKey={setSearchKey}
                setSortOn={setSortOn}
                setSortOrder={setSortOrder}
                setDraftBtn={setDraftBtn}
                sortOrder={sortOrder}
                stickyHeader

                items={Constants.sortDropdown}
                showReviewList={showReviewList}
                draftBtn={draftBtn}
                cataloguesCount={purchaseOrders.length}
              />
            </>
          </div>
        )}
      </div>)}
    </div>
  )
}

CatalogList.propTypes = {
  id: PropTypes.string,
  code: PropTypes.string
}

export default CatalogList
