import React from 'react'
// import { CButton } from '@coreui/react'
import PropTypes from 'prop-types'
import { ToastMessage } from 'components/uiElements'
import { isNotNullUndefinedOrEmpty } from 'shared/util/helper'
import Status from 'components/common/status/VerticalStatus'
import { useParams } from 'react-router-dom'

const ResultPo = (props) => {
  const param = useParams()
  return (
    <>
      {!props.error && (
        <ToastMessage
          color={'success'}
          message={
            param.poNumber ? 'Purchase Order Updated Successfully' : 'Purchase Order Created Successfully'
          }
        />
      )}

      <Status
        message={
          isNotNullUndefinedOrEmpty(props.error)
            ? 'Something Went Wrong!!!'
            : param.poNumber
            ? 'Purchase Order Updated Successfully'
            : 'Purchase Order Created Successfully'
        }
        error={props.error}
      />
    </>
  )
}

ResultPo.propTypes = {
  error: PropTypes.string,
  pCode: PropTypes.string,
  message: PropTypes.string,
  setActiveStep: PropTypes.func,
  setOpenModal: PropTypes.func,
  isAdmin: PropTypes.bool,

}

export default ResultPo
