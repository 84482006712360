import React from 'react'
import PropTypes from 'prop-types'
import { isNotNullUndefinedOrEmpty, isNotUndefinedAndNull } from 'shared/util/helper'

const PoDate = (props) => {
  return (
    <>
      {isNotUndefinedAndNull(props.poDate) ? (
        <>
          {isNotNullUndefinedOrEmpty(props.poDate) && (
            <>
              <span>{props.poDate}</span>
              <br />
            </>
          )}
        </>
      ) : (
        <div className='cat-sub-title text-center'>---</div>
      )}
    </>
  )
}

PoDate.propTypes = {
  poDate: PropTypes.string,
}

export default PoDate
