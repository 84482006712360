import axios from 'axios'
import authHeader from './auth-header'

const postImage = (input) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/image`,
      input,
      {
        headers: authHeader('multipart/form-data;')
      },
      { timeout: process.env.REACT_APP_API_TIMEOUT }
    )
    .then(
      (response) => {
        return response.data
      },
      (error) => {
        throw error.response
      }
    )
}

const removeImage = (input) => {
  return axios
    .delete(
      `${process.env.REACT_APP_BASE_URL}/image`,
      {
        data: {
          files: input
        },
        headers: authHeader()
      },
      { timeout: process.env.REACT_APP_API_TIMEOUT }
    )
    .then(
      (response) => {
        return response.data
      },
      (error) => {
        throw error.response
      }
    )
}

const uploadServices = {
  postImage,
  removeImage
}

export default uploadServices
