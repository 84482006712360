import React from 'react'
import PropTypes from 'prop-types'
import { isNotNullUndefinedOrEmpty, isNotUndefinedAndNull } from 'shared/util/helper'

const PoDueDate = (props) => {
  return (
    <>
      {isNotUndefinedAndNull(props.poDueDate) ? (
        <>
          {isNotNullUndefinedOrEmpty(props.poDueDate) && (
            <>
              <span>{props.poDueDate}</span>
              <br />
            </>
          )}
        </>
      ) : (
        <div className='cat-sub-title text-center'>---</div>
      )}
    </>
  )
}

PoDueDate.propTypes = {
  poDueDate: PropTypes.string
}

export default PoDueDate
