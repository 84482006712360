import React, { useContext } from 'react'
import { CContainer } from '@coreui/react'
import { Grid, Skeleton } from '@mui/material'
import { usePurchaseOrderDetailsHooks } from './usePurchaseOrderDetailsHook'
import BasicTable from 'components/uiElements/table/BasicTable'
import ChatBox from 'components/chat/chatBox'
import ModalDialog from 'components/uiElements/modal/Dialog'
import ProposalForm from './ProposalForm'
import ProposalHistory from './ProposalHistory'
import ProposalValidation from './ProposalValidation'
import { AiOutlineAppstoreAdd, AiOutlineEdit } from 'react-icons/ai'
import { Button as ProductButton } from '@mui/material'
import { Link, useParams } from 'react-router-dom'
import PoHeader from 'pages/purchaseOrder/PoHeader'
import { AppBreadcrumb } from 'sections/layout/dashboard'
import { UserContext } from 'context/AuthContext/UserContext'

const PurchaseOrderDetails = (props) => {
  const { userData } = useContext(UserContext)
  const { updatedColumns, rows, page, rowsPerPage, isPoLoading,
    isLoading, messages, isOpen, modal, pId, isSubmitting, products, sku, newMessage, content, validating, handleChangePage, handleChangeRowsPerPage, sendMessage, setIsOpen, setModal, submitHandler,
    setNewMessage, validateSubmitHandler, data, purchaseOrderDetail, contextObj, updateMessage
  } = usePurchaseOrderDetailsHooks(props)

  const handleClose = () => {
    setModal(false)
  }
  const param = useParams()
  let breadCrumbPath = [
    { name: 'Purchase Order', url: `/purchaseorders`, active: 'not-allowed' },
    { name: `Purchase Order Details`, url: `/purchaseOrder/${param?.poNumber}`, active: 'not-allowed' },
  ]
  return (
    <>
      <>
        <div className='p-5'>
          <Grid container>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <AppBreadcrumb pathNameDeclare={breadCrumbPath} />
              <h3>Purchase Order Detail</h3>
              <p>Detail of your Purchase Order</p>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Grid textAlign='end' alignItems='end'>
                {userData?.user?.pomUserType === 1 && userData?.user?.role >= 4 ? <ProductButton className='me-2' variant='outlined' component={Link} to={`/purchaseOrder/edit/${param?.poNumber}`}>
                  Edit Purchase Order&nbsp;&nbsp;
                  <AiOutlineEdit
                    size='1.5rem'
                  />
                </ProductButton> : ("")}
                {(purchaseOrderDetail.status == "Quotation" || purchaseOrderDetail.status == "Proposal") ?
                  (<ProductButton variant='outlined' disabled component={Link} to={`/purchaseOrder/delivery/${param.poNumber}`}>
                    Delivery&nbsp;&nbsp;
                    <AiOutlineAppstoreAdd size='1.5rem' />
                  </ProductButton>) : (<ProductButton variant='outlined' component={Link} to={`/purchaseOrder/delivery/${param.poNumber}`}>
                    Delivery&nbsp;&nbsp;
                    <AiOutlineAppstoreAdd size='1.5rem' />
                  </ProductButton>)}

              </Grid>
            </Grid>
          </Grid>
          <>
            <>
              {isPoLoading ? (
                <Skeleton animation='wave' variant='rectangle' width='100%' height='350px' />
              ) : (
                <CContainer lg>
                  <Grid>
                    <PoHeader purchaseOrderDetail={purchaseOrderDetail} />
                    <Grid>
                      {rows.length > 0 ? (
                        <BasicTable
                          columns={updatedColumns}
                          rows={rows}
                          page={page}
                          rowsPerPage={rowsPerPage}
                          handleChangePage={handleChangePage}
                          handleChangeRowsPerPage={handleChangeRowsPerPage}
                          purchaseOrderDetail={purchaseOrderDetail}
                          isLoading={isLoading}
                          Nopagination={true}
                        />
                      ) : ('')}
                    </Grid>
                    <ChatBox
                      isOpen={isOpen}
                      poId={purchaseOrderDetail._id}
                      poNumber={purchaseOrderDetail.poNumber}
                      userId={contextObj.userData.user.id}
                      sendMessage={sendMessage}
                      messages={messages}
                      setIsOpen={setIsOpen}
                      setNewMessage={setNewMessage}
                      newMessage={newMessage}
                      data={data}
                      updateMessage={updateMessage}
                      purchaseOrderDetail={purchaseOrderDetail}
                      contextObj={contextObj}
                    />
                  </Grid>

                </CContainer>
              )}
              <ModalDialog
                maxWidth={content.history ? 'md' : ''}
                open={modal}
                content={
                  <div>
                    {content.history && (
                      <ProposalHistory products={products} pId={pId} sku={sku} poDetails={purchaseOrderDetail} />
                    )}
                    {content.proposal && (
                      <ProposalForm
                        PoNumber={purchaseOrderDetail?._id}
                        ProductId={pId}
                        cancelAction={handleClose}
                        isSubmitting={isSubmitting}
                        submitHandler={submitHandler}
                        poDetails={purchaseOrderDetail}
                      />
                    )}
                    {content.validation && (
                      <ProposalValidation
                        PoId={purchaseOrderDetail._id}
                        pId={pId}
                        rows={rows}
                        ValidationSubmit={validateSubmitHandler}
                        validating={validating}
                        cancelAction={handleClose}
                        sku={sku}
                      />
                    )}
                  </div>
                }
                handleClose={handleClose}
                title={
                  (content.history && 'HISTORY') ||
                  (content.proposal && 'SKU Proposal') ||
                  (content.validation && 'Sku Validation')
                }
              />
            </>
          </>
        </div>
      </>
    </>
  )
}

export default PurchaseOrderDetails
